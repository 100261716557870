import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import { LY_EmailAccountSetup_EmailProvider } from '../LY_EmailAccountSetup_EmailProvider/LY_EmailAccountSetup_EmailProvider';
import { LY_EmailAccountSetup_EmailProvider_Instructions } from '../LY_EmailAccountSetup_EmailProvider_Instructions/LY_EmailAccountSetup_EmailProvider_Instructions';

import "./LY_EmailAccountSetupModal.css";
interface LY_EmailAccountSetupModalProps extends LY.LY_EmailAccountSetupModuleProps {

}

export const LY_EmailAccountSetupModal: React.FC<LY_EmailAccountSetupModalProps> = (props) => {

    const context = LY.useEmailAccountSetupModuleContext()
    const state = context.state
    const manager = context.manager;
    const services = context.services;


    function onPrevButtonClick() {
        if (state.currentStep === 0) {
           // context.setAddAccountModalIsOpen(false)
            manager.onCancelOrCloseReset();
        } else {
            context.prevStep()
        }
      //  context.onCancelOrCloseReset()
        state.newAccountSm = new Api.EmailAccount();
        manager.resetErrors();
    }

    function validateImapAccountForm(): boolean {

        const item = state.newAccountSm;

        state.errors.clear();

        if (LIB.Common.isNullOrEmpty(item.first_name))
            state.errors.set('first_name', `First Name is required!`);

        if (LIB.Common.isNullOrEmpty(item.last_name))
            state.errors.set('last_name', `Last Name is required!`);

        if (!LIB.ValidationHelper.isValidEmail(item.email))
            state.errors.set('email', `Valid email address is required!`);

        if (LIB.Common.isNullOrEmpty(item.imap_host))
            state.errors.set('imap_host', `IMAP host is required!`);

        if (LIB.Common.isNullOrEmpty(item.imap_port))
            state.errors.set('imap_port', `IMAP port is required!`);

        if (LIB.Common.isNullOrEmpty(item.smtp_port))
            state.errors.set('smtp_port', `SMTP port is required!`);

        if (LIB.Common.isNullOrEmpty(item.smtp_server))
            state.errors.set('smtp_host', `SMTP host is required!`);

        if (LIB.Common.isNullOrEmpty(item.password))
            state.errors.set('password', `Password is required!`);

        if (LIB.Common.isNullOrEmpty(item.username))
            state.errors.set('username', `User name is required!`);

        context.forceUpdate()

        const isValid = (state.errors.size < 1);

        return isValid;

    }

    function validateOauthPasswordForm(): boolean {

        const item = state.newAccountSm;

        state.errors.clear();

        if (LIB.Common.isNullOrEmpty(item.first_name))
            state.errors.set('first_name', `First Name is required!`);

        if (LIB.Common.isNullOrEmpty(item.last_name))
            state.errors.set('last_name', `Last Name is required!`);

        if (!LIB.ValidationHelper.isValidEmail(item.email))
            state.errors.set('email', `Valid email address is required!`);

        if (LIB.Common.isNullOrEmpty(item.password))
            state.errors.set('password', `Password is required!`);

        context.forceUpdate()

        const isValid = (state.errors.size < 1);

        return isValid;

    }

    function openOauthWindow(window: Window) {

        if (window && window.document) {

            console.log(window)

        }
    }

    async function onNextButtonClick() {

        if (state.currentStep === 1 && 
            state.selectedAccountType?.emailProviderTypeId === state.EmailProviderTypeEnum.CustomOther) {

            const isValid = validateImapAccountForm()

            if (isValid) {
                console.log("IMAP FORM IS VALID")
                //Here i need to add logic for imap connect
            }

        } else if (state.currentStep === 1 && 
                 state.selectedEmailProviderAuthtype?.emailProviderAuthTypeId === state.EmailProviderAuthTypeEnum.Password) {

            const isValid = validateOauthPasswordForm()

            if (isValid) {
                services.connect(state.newAccountSm);
            }

        } else if (state.currentStep === 1 && 
                    state.selectedEmailProviderAuthtype?.emailProviderAuthTypeId === state.EmailProviderAuthTypeEnum.OAuth) {

            await context.getSelectedAuthTypeRedirectUrl()

            const redirectUrl = state.accountOauthUrl

            const width = 600;
            const height = 400;

            const oauthWindow = window.open(redirectUrl, 'OAuthWindow', `width=${width},height=${height},left=-6000,top=-6000,resizable=yes,scrollbars=yes`);

            const checkInterval = setInterval(() => {
                try {

                    if (oauthWindow && oauthWindow.closed) {
                        clearInterval(checkInterval);
                        console.log('OAuth window closed');
                    } else if (oauthWindow) {
                        openOauthWindow(oauthWindow);
                    }
                } catch (err) {
                    console.error('Error during OAuth window check:', err);
                }
            }, 500);

            //it's  a test function let me know if it's work i will change it

        }
        else {
            context.nextStep()
        }
    }

 
    function getPrevBtnText() {

        if (state.currentStep === 0) {
            return "Cancel"
        }

        return "Back"
    }

    function getNextBtnText() {

        if (state.currentStep === 1) {
            return "Connect"
        }

        return "Continue"
    }
 
    function stepOneRenderer() {

        return <LY.LY_Stepper.LY_Step label="Choose Type" currentStep={state.currentStep} >

            <div className="LY_EmailAccountSetup_Step_AccountTypes_Container">
                {state.emailAccountTypes.map((t) => {

                    return <LY_EmailAccountSetup_EmailProvider
                        {...props}
                        key={t.emailProviderTypeId} emailProviderType={t} />
                }
                ) 
                }
            </div>

        </LY.LY_Stepper.LY_Step>

    }

    function stepTwoRenderer() {

        return <LY.LY_Stepper.LY_Step label="Connect" currentStep={state.currentStep} >

            <LY_EmailAccountSetup_EmailProvider_Instructions  {...props} />

        </LY.LY_Stepper.LY_Step>

    }

    function modalBodyRenderer() {

        console.log('LY_EmailAccountSetupModal modalBodyRenderer:', state.isEmailTypesLoadingActive)
        if (state.isEmailTypesLoadingActive) {

            return loaderRenderer()

        }

        return <div className="LY_EmailAccountSetup_Modal_Content_Body">

            <LY.LY_Stepper active={state.currentStep}
                onStepClick={context.nextStep}
                allowNextStepsSelect={false}
                stepperRootClassName='LY_EmailAccountSetup_Step_Root'
                stepperContentClassName='LY_EmailAccountSetup_Step_Content'
                stepsContainerClassName='LY_EmailAccountSetup_Steps_Container'
            >
                {stepOneRenderer()}
                {stepTwoRenderer()}
            </LY.LY_Stepper>

        </div>

    }

    function getButtons() {

        return < >

            <LY.LY_ButtonCancel onClick={onPrevButtonClick}>{getPrevBtnText()}</LY.LY_ButtonCancel>

            <LY.LY_Button loading={state.isConnectPending} 
            loaderProps={{ type: 'dots' }} 

            
            disabled={!(!state.isEmailTypesLoadingActive && !!state.selectedAccountType)} 
            onClick={onNextButtonClick}>{getNextBtnText()}
            </LY.LY_Button>
        </>

    }

    function loaderRenderer() {

        return <div className="LY_EmailAccountSetup_Loader">
            <LIB.Loading show={true} />
        </div>

    }



    function render() {

        return <LY.LY_Modal
             title="Connect New Email Account"
              classNames={{
                body: "LY_EmailAccountSetup_Modal_Body",
                content: "LY_EmailAccountSetup_Modal_Content",
            }}  
            centered
            opened={state.isAddAccountModalOpened}
            //withCloseButton={false}
            onClose={() => manager.onCancelOrCloseReset()}
           // onClose={() => context.setAddAccountModalIsOpen(false)}

            footer={getButtons()}
            size={'xl'}
            >

       {/*      {modalHeaderRenderer()} */}
            {modalBodyRenderer()}
          {/*   {modalFooterRenderer()}
 */}
        </LY.LY_Modal>

    }




    return render();
};


