import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { Button, Menu } from "@mantine/core";
import "./LY_MenuButton.css";

interface LY_MenuButton_Item {
  id: string;
  name: string;
}

interface LY_MenuButtonProps {
  menuItems: LY_MenuButton_Item[];
  buttonClassName?: string;
  onItemClick?: (val: LY_MenuButton_Item) => void;
  opened?: boolean;
}

export const LY_MenuButton: React.FC<LY_MenuButtonProps> = ({ menuItems, onItemClick, buttonClassName, ...props }) => {

  const [open, onOpenChange] = React.useState(props.opened || false);



  return (
    <Menu
     
      offset={2}
      withinPortal={false}
      withArrow
      width={150}

      opened={open}
      position="bottom"
     // classNames={{dropdown: "LY_ButtonPinkHover_Dropdown"}}
     closeOnClickOutside={true}
     onClose={() => onOpenChange(false)}
    >
      <Menu.Target>
 
       <LY.LY_ButtonPinkHover
               
           isActive={open}
          title={'Colors'}
          onClick={() => onOpenChange(!open)}
    
        >
              <Icons.IconDots width={22} height={22} />
        </LY.LY_ButtonPinkHover>      
{/*          <Button
          variant="transparent"
          classNames={{
            root: "LY_MenuButton " + buttonClassName,
          }}
          onClick={() => onOpenChange(!open)}
        >
          <Icons.IconDots width={22} height={22} />
        </Button>    
  */}
 

      </Menu.Target>
      <Menu.Dropdown>
        {menuItems.map((item) => (
          <Menu.Item
            key={item.id}
            classNames={{ item: "LY_MenuButton_Item" }}
            onClick={() => onItemClick && onItemClick(item)}
          >
            {item.name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
