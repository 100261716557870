import './LY_KanbanBoard_ItemList.css';

import * as Api from "app-api";
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from '@mantine/core'

import { Droppable } from 'react-beautiful-dnd';
import { LY_KanbanBoard_InnerList } from '../LY_KanbanBoard_InnerList/LY_KanbanBoard_InnerList';
import LY_KanbanBoard_Item from '../LY_KanbanBoard_Item/LY_KanbanBoard_Item';
import { LY_KanbanProps } from '../../Interfaces/LY_KanbanModuleProps';
import React from 'react';

interface LY_KanbanBoard_ItemListProps extends LY_KanbanProps {
    ignoreContainerClipping?: boolean;
    internalScroll?: boolean;
    scrollContainerStyle?: React.CSSProperties;
    isDropDisabled?: boolean;
    isCombineEnabled?: boolean;
    listKeyId?: string;
    listType?: string;
    style?: React.CSSProperties;
    records: { [key: string]: any }[];
    title?: string;
    useClone?: boolean;
}

export const LY_KanbanBoard_ItemList: React.FC<LY_KanbanBoard_ItemListProps> = ({
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listKeyId = 'LIST',
    listType,
    style,
    records,
    title,
    useClone,
    ...props
}) => {
    function render() {

        return (
            <Droppable
                droppableId={listKeyId}
                type={listType}
                ignoreContainerClipping={ignoreContainerClipping}
                isDropDisabled={isDropDisabled}
                isCombineEnabled={isCombineEnabled}
                renderClone={
                    useClone
                        ? (provided, snapshot, descriptor) => (
                            <LY_KanbanBoard_Item
                                {...props}
                                item={records[descriptor.source.index]}
                                provided={provided}
                                isDragging={snapshot.isDragging}
                                
                            />
                        )
                        : undefined
                }
            >
                {(dropProvided, dropSnapshot) => (
                    <div
                        className={`LY_KanbanBoard_ItemList-wrapper ${dropSnapshot.isDraggingOver ? 'dragging-over' : ''
                            } ${isDropDisabled ? 'disabled' : ''}`}
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                    >
                        {internalScroll ? (
                            <div className="LY_KanbanBoard_ItemList-container LY_Scrollbar" style={scrollContainerStyle}>
                                <LY_KanbanBoard_InnerList {...props} records={records} title={title} dropProvided={dropProvided} />
                            </div>
                        ) : (
                            <LY_KanbanBoard_InnerList {...props} records={records} title={title} dropProvided={dropProvided} />
                        )}
                    </div>
                )}
            </Droppable>
        )

    }

    return render();
};


