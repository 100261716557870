import './LY_Kanban.css';

import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import React, { FC } from 'react';

import LY_KanbanBoard from './Components/LY_KanbanBoard/LY_KanbanBoard';
import { LY_KanbanContextProvider } from './Context/LY_KanbanModuleContext';

export const LY_Kanban: FC<LY.LY_KanbanProps> = (props) => {

    return (
        <LY_KanbanContextProvider {...props}>
            <LY_KanbanBoard {...props} />
        </LY_KanbanContextProvider>
    );
};
