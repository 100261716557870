import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_EmailAccountSetupModuleState } from "../Context/LY_EmailAccountSetupModuleState";
import { LY_EmailAccountSetupModuleManager } from './LY_EmailAccountSetupModuleManager';

export class LY_EmailAccountSetupModuleServiceManager {


  public forceUpdate: () => void;
  public state: LY_EmailAccountSetupModuleState;
  public props: LY.LY_EmailAccountSetupModuleProps;
  public context: LY.LY_EmailAccountSetupModuleContextProps;

  public listViewService: App.ListViewService;
  public sm: Api.ListViewGetDataSm;

  private emailMarketingService: App.EmailMarketingService;
  public manager: LY_EmailAccountSetupModuleManager; 


  constructor(props: LY.LY_EmailAccountSetupModuleProps, state: LY_EmailAccountSetupModuleState, context: LY.LY_EmailAccountSetupModuleContextProps) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;

    this.sm = new Api.ListViewGetDataSm()

    this.listViewService = new App.ListViewService(props.workspaceId)
    this.listViewService.linkedWorkspaceId = this.props.linkedWorkspaceId;

    this.emailMarketingService = new App.EmailMarketingService(props.workspaceId);

  }

  public onServiceError(error: any) {

    if (this.state == undefined)
      return;

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    App.AppBase.showError(errorMessage)
    this.state.isActionInProgress = false;
    this.state.isEmailTypesLoadingActive = false;
    this.state.isConnectPending = false;
    this.state.apiErrorMessage = errorMessage;
   // this.state.passwordAuthErrorMessage = errorMessage;

    this.forceUpdate();

  }


  
  public getEmailAccountConnectState() {

    console.log('getEmailAccountConnectState')
 
     this.emailMarketingService.getEmailAccountConnectState(

      //onSuccess
      (result: Api.EmailAccountConnectState) => {
        console.log('LY_EmailAccountSetupModuleServiceManager getEmailAccountConnectState onSuccess:', result);

        this.state.emailAccountTypes = result.emailProviderTypes || [];
        this.state.isEmailTypesLoadingActive = false;
        
        this.forceUpdate();
      },
      //onError
      this.onServiceError.bind(this)
    );

    this.state.isEmailTypesLoadingActive = true;
    this.forceUpdate();
 
  }



 
  public connect(sm: Api.EmailAccount) {

    console.log('connect',sm);

    if(!sm)
       return;

    var state = this.state;
    sm.name = `${state.newAccountSm.first_name} ${state.newAccountSm.last_name}`
    sm.provider_id = state.selectedAccountType!.emailProviderTypeId
    sm.provider_auth_type_id = state.selectedEmailProviderAuthtype!.emailProviderAuthTypeId

     this.emailMarketingService.connect(
      sm,
      //onSuccess
      (result: Api.EmailAccountConnectState) => {
        console.log('LY_EmailAccountSetupModuleServiceManager connect onSuccess:', result);


        App.AppBase.showMessage("Successfully connected!")
        this.manager.onCancelOrCloseReset();
 
      },
      //onError
      this.onServiceError.bind(this)
    );

    this.state.isConnectPending = true;
    this.forceUpdate();
 
  }


 
  public async getOauthConnectUrl() {

    this.state.isConnectPending = true;

    this.forceUpdate()

    const id = LIB.StringHelper.generateUUID()

    //its test data, will change it

 
  }

}
