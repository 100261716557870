import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

export class LY_ListPageModuleState extends LIB.BaseVmModel {

  public view?: Api.ListView = new Api.ListView();
  public newViewSm: Api.ListViewSm = new Api.ListViewSm();

  public workspaceId?: number | undefined;
  public linkedWorkspaceId?: number | undefined;

  public listStates: App.ListState[] = [];
  public listState: App.ListState | undefined = new App.ListState();
  public list: Api.List = new Api.List();

  public isPageLoadDone: boolean = false;

  public sm: Api.ListGetDataSm = new Api.ListGetDataSm();
 
  public selectedTabIndex: number;
  public selectedTab: string;

  public isItemAddOpen: boolean = false;
  public isItemDeleteOpen: boolean = false;
  public isItemEditOpen: boolean = false;
 
  public selectedItem: LY.DataItemModel;
  public selectedItemOriginal: LY.DataItemModel;
  public selectedId: string = '';

  public viewId: any = -1;

  public isAddColumnScreenOpen: boolean = false;
  public isColumnSettingsScreenOpen: boolean = false;
  public isColumnDeleteOpen: boolean = false;
  public isColumnDeleteInProgress: boolean = false;

  public isDetailRightPanelOpen: boolean = false;
  public isDetailPopupModalOpen: boolean = false;
  public selectedDetailListId?: string;
  public selectedDetailViewId?: number;

  public isRelActionInProgress: boolean = false;
  public isSearchInProgress: boolean = false;

  public isNewRecordOpen: boolean = false;

  public lookupTypes:Api.LookupType[] = [];
  public selectedWorkspaceItem?: Api.WorkspaceItem;

  constructor() {
    super();
  }
}
