import * as Api from "app-api";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from "@mantine/core";
import * as React from "react";

import UserAvatar from "_LY_Components/Users/UserAvatar/UserAvatar";

import "./LY_UserDropDown.css";

export interface LY_UserDropDownProps extends LY.LY_DropDownProps {
  data: Api.LooceyUser[];
  selectedUsers?: Api.LooceyUser[];
  currentUserData?: Api.LooceyUser;
  isSingleSelect?: boolean;
  isCheckBoxStyle?: boolean;
  onInviteUserClick?: () => void;
  onViewProfileClick?: (user: Api.LooceyUser) => void;
  onSelectedUserChange?: (val: Api.LooceyUser | null, isSelected: boolean, optionProps?: MT.ComboboxOptionProps | undefined,
    combobox?: MT.ComboboxStore | undefined) => void;


}

export const LY_UserDropDown = React.forwardRef<HTMLInputElement, LY_UserDropDownProps>((
  {
    name,
    value,
    displayProperty = "name",
    valueProperty = "id",
    style,
    isCheckBoxStyle = false,
    currentUserData,
    ...props
  },
  ref
) => {
  const [ users, setUsers ] = React.useState<Api.LooceyUser[]>(props.data);

  const [ selectedUsers, setSelectedUsers ] = React.useState<Api.LooceyUser[]>(props.selectedUsers || []);

  const [ selectedUser, setSelectedUser ] = React.useState<Api.LooceyUser | undefined>(undefined);


  React.useEffect(() => {


    // console.log(`LY_UserDropDown useEffect props.selectedUsers: `, props.selectedUsers );



    setSelectedUsers(props.selectedUsers || []);

    if (selectedUsers.length > 0)
      setSelectedUser(selectedUsers[ 0 ]);
    else
      setSelectedUser(undefined);

    // console.log(`LY_UserDropDown useEffect setSelectedUsers: `, setSelectedUsers );

    /*     if (currentUserData) {
    
    
          setUsers((prev) => [ currentUserData, ...prev ]);
          setSelectedUsers((prev) => [ currentUserData, ...prev ]);
          setSelectedUser(currentUserData);
    
    
        } else {
          setSelectedUser(props.data[ 0 ]);
        } */
  }, [ props.data, props.selectedUsers ]);



  function userOptionItemRenderer(usersData: any[], selectedUsers: any[], selectedItem: any,
    combobox?: MT.ComboboxStore | undefined): React.ReactElement[] | undefined {

    if (props.readOnly)
      return [];

    let options = usersData?.map((item, index) => {

      let isActive;

      let activeClassName;

      let defaultClassName = isCheckBoxStyle
        ? "LY_UserDropDown_Option_Line"
        : "LY_UserDropDown_Option";

      const currentUser =
        item[ valueProperty ] ===
        currentUserData?.[ valueProperty as keyof Api.LooceyUser ];

      if (selectedItem) {
        isActive = selectedItem[ valueProperty ] === item[ valueProperty ];
      } else {
        isActive = !!selectedUsers?.filter(
          (i) => i?.[ valueProperty ] === item[ valueProperty ]
        ).length;
      }

      activeClassName = isActive ? "LY_UserDropDown_OptionActive" : "";

      const key = `ComboboxOptionKey_${valueProperty}_${item[ valueProperty! ]
        }_${index}`;

      return (
        <React.Fragment key={key}>
          <MT.Combobox.Option
            className={`${defaultClassName} ${activeClassName}`}
            value={item[ valueProperty ]}
            selected={isActive}>
            <div className="LY_UserDropDown_Option_Left">
              {isCheckBoxStyle ? (
                <LY.LY_CheckBox
                  checked={isActive}
                  name="LY_UserDropDown_CheckBox"
                />
              ) : null}
              <UserAvatar name={item.name} />
            </div>
            {/*            {userOptionMenuRenderer(item)} */}
          </MT.Combobox.Option>
          {currentUser && <div className="LY_UserDropDown_Border"></div>}
        </React.Fragment>

      );
    });

    return options;
  }

  function userOptionMenuRenderer(user: Api.LooceyUser) {
    return (
      <MT.Menu shadow="md" width={200} >
        <MT.Menu.Target >
          <MT.Button
            style={{ ariaHidden: "inert" }}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation()
              if (props.onViewProfileClick) {
                props.onViewProfileClick(user)
              }
            }
            }
            className="LY_UserDropDown_Menu_Button">
            <Icons.IconDots />
          </MT.Button>
        </MT.Menu.Target>

        <MT.Menu.Dropdown>
          <MT.Menu.Item
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();

            }}
            leftSection={
              <Icons.IconLink
                style={{ width: MT.rem(14), height: MT.rem(14) }}
              />
            }>
            User Profile
          </MT.Menu.Item>
        </MT.Menu.Dropdown>
      </MT.Menu>
    );
  }

  function multipleSelectedUserIconRenderer(selectedDisplayItems: any[], selectedUsers: any[]): any[] {

    let items: any[] = [];

    if (!selectedDisplayItems)
      return items;

    if (selectedUsers.length === 1) {
      let fullName = selectedUsers[ 0 ][ displayProperty as keyof Api.LooceyUser ];
      items.push(<UserAvatar key={`${displayProperty}_`} name={fullName} />);
      return items;
    }

    selectedDisplayItems.forEach((x, i) => {
      const key = `ComboboxOptionKey_${valueProperty}_${x[ valueProperty! ]}_${i}`;
      let name = x[ displayProperty as keyof Api.LooceyUser ];

      if (selectedUsers.length === 1) {
        items.push(<UserAvatar key={`${displayProperty}_`} name={name} />);
        return items;
      }
      items.push(
        <UserAvatar
          name={name}
          key={key}
          showName={false}
          showTooltip={true}
        />
      );

    });


    return items;

  }

  function customSingleSelectedItemRenderer(combobox?: MT.ComboboxStore | undefined) {

    // console.log("LY_UserDropDown customSingleSelectedItemRenderer");

    return (
      <div
        className="LY_UserDropDown_Selected_Single_Container"
        onClick={() => {
          combobox?.dropdownOpened
            ? combobox?.closeDropdown()
            : combobox?.openDropdown();
        }}
        onBlur={() => closeDropdown(combobox)}>
        <div className="LY_UserDropDown_Selected_Single_Info">
          {selectedUser && (
            <UserAvatar name={selectedUser?.name!} />
          )}
        </div>

        {!props.readOnly &&
          <div className="LY_MultiDropDown_SelectedItem_arrow">
            {combobox?.dropdownOpened ? (
              <Icons.IconCaretUpFilled width={16} />
            ) : (
              <Icons.IconCaretDownFilled size={16} />
            )}
          </div>
        }
      </div>
    );
  }

  function footerRightSectionRenderer(
    combobox?: MT.ComboboxStore | undefined
  ) {
    return (
      <div
        className="_LY_DropDownBaseViewEditFooterButton"
        onClick={(e) => {
          if (props.onInviteUserClick) {
            props.onInviteUserClick()
          }
        }}
        style={{ cursor: "pointer" }}>
        Invite Users
      </div>
    );
  }

  function closeDropdown(combobox?: MT.ComboboxStore | undefined) {
    if (combobox?.dropdownOpened) combobox?.closeDropdown();
  }

  function render() {
    //console.log("LY_UserDropDown render");

    return (
      <LY.LY_MultiDropDown
        ref={ref}
        name={name}
        selectedItems={selectedUsers}
        selectedItem={props.isSingleSelect ? selectedUser : undefined}
        isSingleSelect={props.isSingleSelect}
        isAutoCompleteStyle={props.isAutoCompleteStyle}
        displayProperty={displayProperty}
        valueProperty={valueProperty}
        //single
        customSingleSelectedItemRenderer={customSingleSelectedItemRenderer}
        //onCustomSingleItemChange={
        onChange={(val: any | null, optionProps?: MT.ComboboxOptionProps | undefined,
          combobox?: MT.ComboboxStore | undefined) => {

          if (props.isSingleSelect)
            setSelectedUser(val);

          /* 
                    props.isSingleSelect
                      ? (item, optionProps) => {
                        setSelectedUser(item);
                      }
                      : undefined */
        }}
        //multiple
        customMultipleSelectedItemsRenderer={multipleSelectedUserIconRenderer}
        customMultipleItemOptionRenderer={userOptionItemRenderer}
        footerRightSectionRenderer={footerRightSectionRenderer}
        customSelectedItemsInfoContainerClassName="LY_UserDropDown_SelectedItem_Container"
        model={props.model}
        //  optionsContainerStyle={{ minWidth: "210px" }}
        {...props}
        data={users}
      />
    );
  }
  return render();
}
);

