import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { createContext, useContext, useEffect } from "react";

import { LY_EmailAccountSetupModuleServiceManager } from '../Managers/LY_EmailAccountSetupModuleServiceManager';
import { LY_EmailAccountSetupModuleManager } from '../Managers/LY_EmailAccountSetupModuleManager';
import { LY_EmailAccountSetupModuleState } from './LY_EmailAccountSetupModuleState';

import { useDisclosure } from '@mantine/hooks';


export interface LY_EmailAccountSetupModuleContextProps {
  state: LY_EmailAccountSetupModuleState;
  forceUpdate: () => void;
  //onCancelOrCloseReset: () => void;
  resetState: () => void;
  services: LY_EmailAccountSetupModuleServiceManager;
  manager: LY_EmailAccountSetupModuleManager; 
  getUserEmailInfoData: () => void;
 // connect: () => void;
  getSelectedAuthTypeRedirectUrl: () => void;

  setActiveAccountType: (a: Api.EmailProviderType) => void

  setEditAccountModalIsOpen: (value: boolean) => void;

  setSelectedImapEncryptionType: (name: string) => void;
  setSelectedSmtpEncryptionType: (name: string) => void;

  getAuthTypeName: (id: number) => string;

  setSelectedAuthType: (id: number) => void;

  nextStep: () => void;
  prevStep: () => void;

  //resetOauthPasswordForm: () => void;
 // resetPasswordAuthError: () => void;
}

const useEmailAccountSetupModuleState = (props: LY.LY_EmailAccountSetupModuleProps): LY_EmailAccountSetupModuleContextProps => {


  const initialState = new LY_EmailAccountSetupModuleState();

  const [state, setState] = React.useState<LY_EmailAccountSetupModuleState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_EmailAccountSetupModuleState>(new LY_EmailAccountSetupModuleState());


  const contextObjBase: LY.LY_EmailAccountSetupModuleContextProps = {
    state,
    forceUpdate,
    //onCancelOrCloseReset,
    resetState,
    services: {} as LY_EmailAccountSetupModuleServiceManager,
    setActiveAccountType,
    setEditAccountModalIsOpen,
    getUserEmailInfoData,
    getAuthTypeName,
    setSelectedAuthType,
   // connect,
   // resetOauthPasswordForm,
    getSelectedAuthTypeRedirectUrl,
   // resetPasswordAuthError,
    nextStep,
    prevStep,
    setSelectedImapEncryptionType,
    setSelectedSmtpEncryptionType,
    manager: {} as LY_EmailAccountSetupModuleManager
  };

 // const services = new LY_EmailAccountSetupModuleServiceManager(props, state, contextObjBase);

  //const contextObj: LY.LY_EmailAccountSetupModuleContextProps = { ...contextObjBase, services: services };
 

  const services = new LY_EmailAccountSetupModuleServiceManager(props, state, contextObjBase);
  const manager = new LY_EmailAccountSetupModuleManager(props, state, contextObjBase,services);
  services.manager = manager;
  
  const contextObj: LY.LY_EmailAccountSetupModuleContextProps = {...contextObjBase, services: services, manager: manager};



  React.useEffect(() => {

    console.log('LY_EmailAccountSetupContext useEffect props', props);

    state.workspaceId = props.workspaceId;
    state.view = props.view;
    state.linkedWorkspaceId = props.linkedWorkspaceId;
    state.isAddAccountModalOpened = props.isOpen;

    if(props.isOpen && state.emailAccountTypes.length < 1)
          services.getEmailAccountConnectState();

    forceUpdate();

    var stateCopy = clone(state);
    setPropsState(stateCopy);


  }, [props.view, props.workspaceId, props.isOpen]);

  React.useEffect(() => {

    if (state.selectedAccountType && state.selectedAccountType.authTypes && state.selectedAccountType.authTypes.length >= 1) {

      state.selectedEmailProviderAuthtype = state.selectedAccountType.authTypes[0]

    }

    state.newAccountSm.id = LIB.StringHelper.generateUUID()

    if (state.selectedAccountType && (state.selectedAccountType.emailProviderTypeId === state.EmailProviderTypeEnum.CustomOther)) {

      state.newAccountSm.imap_host = state.selectedAccountType.imap_host
      state.newAccountSm.imap_port = state.selectedAccountType.imap_port
      state.newAccountSm.smtp_port = state.selectedAccountType.smtp_port
      state.newAccountSm.smtp_server = state.selectedAccountType.smtp_server
      state.newAccountSm.imap_encryption = state.selectedAccountType.imap_encryption
      state.newAccountSm.smtp_encryption = state.selectedAccountType.smtp_encryption

      setSelectedImapEncryptionType(state.selectedAccountType.imap_encryption!)
      setSelectedSmtpEncryptionType(state.selectedAccountType.smtp_encryption!)

    }

    forceUpdate()

  }, [state.selectedAccountType])

  function clone(stateIn: LY_EmailAccountSetupModuleState): LY_EmailAccountSetupModuleState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_EmailAccountSetupModuleState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }


  function forceUpdate() {
    setState({ ...state })
  }

  function resetState() {
    var stateCopy = clone(propsState);
    setState({ ...stateCopy })
  }


 

  function nextStep() {

    state.currentStep = state.currentStep < 1 ? state.currentStep + 1 : state.currentStep;

    forceUpdate();
  }

  function prevStep() {

    state.currentStep = state.currentStep > 0 ? state.currentStep - 1 : state.currentStep;

    state.errors = initialState.errors;

    forceUpdate();

  }

 

  async function getUserEmailInfoData() {

    // await services.getUserEmailInfo()

  }
/* 
  async function connect() {

    if(!state.selectedAccountType || !state.selectedEmailProviderAuthtype)
      return;

    state.newAccountSm.name = `${state.newAccountSm.first_name} ${state.newAccountSm.last_name}`
    state.newAccountSm.provider_id = state.selectedAccountType.emailProviderTypeId
    state.newAccountSm.provider_auth_type_id = state.selectedEmailProviderAuthtype.emailProviderAuthTypeId

    await services.connect()

  } */

  async function getSelectedAuthTypeRedirectUrl() {

    await services.getOauthConnectUrl()

  }


 

  function setEditAccountModalIsOpen(value: boolean) {
    state.isEditAccountModalOpened = value
    forceUpdate()
  }

  function setActiveAccountType(a: Api.EmailProviderType) {
    let selected;

    selected = state.emailAccountTypes.find((acc) => acc.emailProviderTypeId === a.emailProviderTypeId)

    if (selected) {

      state.selectedAccountType = selected

      forceUpdate()

    }
  }

  function getAuthTypeName(id: number) {

    if (id === state.EmailProviderAuthTypeEnum.Password)
      return "Password"

    if (id === state.EmailProviderAuthTypeEnum.Api_Key)
      return "Api Key"

    return "oAuth"

  }



  function setSelectedAuthType(id: number) {

    if (!state.selectedAccountType || !state.selectedAccountType.authTypes)
      return

    const selectedEmailProviderAuthtype = state.selectedAccountType
                                               .authTypes.find((t) => t.emailProviderAuthTypeId === id)

    if (selectedEmailProviderAuthtype) {
      state.selectedEmailProviderAuthtype = selectedEmailProviderAuthtype

      forceUpdate()

    }

  }

  function setSelectedImapEncryptionType(name: string) {

    const selected = state.encryptionTypes.find(e => e.name === name)

    if (selected) {
      state.selectedImapEncryptionType = selected

      forceUpdate()
    }

  }

  function setSelectedSmtpEncryptionType(name: string) {

    const selected = state.encryptionTypes.find(e => e.name === name)

    if (selected) {
      state.selectedSmtpEncryptionType = selected

      forceUpdate()
    }

  }

 

/*   function resetPasswordAuthError() {

    state.isPasswordAuthErrorMessageVisible = false;
    state.passwordAuthErrorMessage = "";

    forceUpdate()

  } */

  return contextObj;
};

const LY_EmailAccountSetupModuleContext = createContext<LY.LY_EmailAccountSetupModuleContextProps | undefined>(undefined);

export const useEmailAccountSetupModuleContext = () => {


  const context = useContext(LY_EmailAccountSetupModuleContext)


  if (!context) {
    throw new Error(
      "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_EmailAccountSetupModuleContextProvider: React.FC<LY.LY_EmailAccountSetupModuleProps & { children: React.ReactNode }> = (props) => {


  const contextValue = useEmailAccountSetupModuleState(props);

  function render() {


    return (
      <LY_EmailAccountSetupModuleContext.Provider value={contextValue} >
        {props.children}
      </LY_EmailAccountSetupModuleContext.Provider>
    );
  }

  return render();
};
export { LY_EmailAccountSetupModuleContextProvider, LY_EmailAccountSetupModuleContext };
