import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

// LY_KanbanModuleContext.tsx
import React, { createContext, useContext, useState } from 'react';

import { DropResult } from 'react-beautiful-dnd';
import { LY_KanbanBoardModuleServices } from '../Managers/LY_KanbanBoardModuleServices';
import { LY_KanbanModuleState } from './LY_KanbanModuleState';
import { LY_KanbanProps } from "../Interfaces/LY_KanbanModuleProps";

export type LY_KanbanSection = Api.SystemLookupType & { records: { [key: string]: any }[] };

interface LY_KanbanContextProps {
    state: LY_KanbanModuleState;
    onDropHandler: (result: DropResult) => void;
    onDragColumnEnd: (result: DropResult) => void;
    onSaveNewColumn: () => void;
    forceUpdate: () => void;
    deleteColumn: () => void;
}




const useKanbanState = (props: LY_KanbanProps): LY_KanbanContextProps => {




    const initialState = new LY_KanbanModuleState();
    const [state, setState] = useState<LY_KanbanModuleState>(initialState);
    const services = new LY_KanbanBoardModuleServices(props, state, forceUpdate);
 
    React.useEffect(() => {

        console.log('useKanbanState useEffect props',props);

        var list = props.listState?.list;
        var dataCol = list?.columns?.find(x=>x.listColumnId === props.statusColumnId);
    

        console.log('useKanbanState useEffect dataCol', dataCol); 

        state.statusColumnName = dataCol?.name || 'status_id';
/*         state.columns =  list?.columns?.filter((x: Api.ListColumn) => (x.isSystemHidden == false || x.name=='id') && 
                                              !x.isAlwaysSystemHidden); */

        state.columns = App.ListManager.getColumnsToShow(list?.columns);

        if (props?.sections?.length && props?.listState?.records?.length) {

            state.sections  = getColumnsAndRecords(props.sections, props.listState?.records);
           // console.log('useKanbanState useEffect columnsWithData', state.columns );
            forceUpdate(); 
        }


       // console.log('useKanbanState useEffect statusColumnName', state.statusColumnName);
       

    }, [props.sections, props.listState?.records]);




    function forceUpdate() {
        setState({ ...state });
    }

    function onDragHandlerSameColumn(startIndex: number, endIndex: number, columnIndex: string): { [key: string]: any; }[] {


        const column = state.sections.find((x) => x.name === columnIndex);


        column?.records?.splice(endIndex!, 0, column?.records.splice(startIndex!, 1)[0]);


        return column!.records.map((item, index) => ({
            ...item, _ly_row_number: index + 1
        }))

    }

    function onDropHandlerDiffernetColumn(result: DropResult, updatedColumns: LY_KanbanSection[]): { [key: string]: any; }[] {
        const { destination, source, draggableId } = result;

        const sourceColumnIndex = state.sections.findIndex((x) => x.name === source.droppableId);

        const destinationColumnIndex = state.sections.findIndex((x) => x.name === destination?.droppableId);

        const sourceColumnrecords = state.sections[sourceColumnIndex]?.records ?? [];
        const destinationColumnrecords = state.sections[destinationColumnIndex]?.records ?? [];

        const task = sourceColumnrecords[source.index];
        task[state.statusColumnName] = state.sections[destinationColumnIndex]?.id


        const updatedSourcerecords = sourceColumnrecords.filter((_, index) => index !== source.index);
        const updatedDestinationrecords = [...destinationColumnrecords];
        updatedDestinationrecords.splice(destination!.index, 0, task);


        updatedColumns[sourceColumnIndex] = {
            ...state.sections[sourceColumnIndex],
            records: updatedSourcerecords,

        };
        updatedColumns[destinationColumnIndex] = {
            ...state.sections[destinationColumnIndex],
            records: updatedDestinationrecords
        };
        const changedSourceRecords = updatedColumns[sourceColumnIndex].records.map((task, index) => ({
            ...task, _ly_row_number: index + 1, [state.statusColumnName]: updatedColumns[sourceColumnIndex].id
        }))

        const changedDestinationRecords = updatedColumns[destinationColumnIndex].records.map((task, index) => ({
            ...task, _ly_row_number: index + 1, [state.statusColumnName]: !(destinationColumnIndex === 0) ? updatedColumns[destinationColumnIndex].id : null
        }))
        return [...changedSourceRecords, ...changedDestinationRecords];


    }

    function onDropHandler(result: DropResult) {


        let updatedRecords: { [key: string]: any }[] = [];

        const updatedColumns = [...state.sections];


        const { destination, source, draggableId } = result;


        if (destination?.droppableId === source?.droppableId) {
            updatedRecords = onDragHandlerSameColumn(source?.index, destination.index, destination.droppableId);
        }
        else {
            updatedRecords = onDropHandlerDiffernetColumn(result, updatedColumns)
        }
        const sendData = updatedRecords.map(i => {
            return {
                listId: props.listId!,
                rowId: i.id!,
               // data: { ...i, [state.statusColumnName]: i?.[state.statusColumnName] }
                data: {[state.statusColumnName]: i?.[state.statusColumnName], _ly_row_number: i._ly_row_number}
            }
        })
        services.updateValues({ updates: sendData } as Api.ListUpdateDataSm);
        setState({ ...state, sections: updatedColumns });


    }

    const onDragColumnEnd = (result: DropResult) => {
        if (result.type === 'COLUMN') {
            const col = state.sections.find((i) => i.name === result.draggableId);
            if (!col) return;

            const updatedColumns = [...state.sections];
            updatedColumns.splice(result.source.index, 1);
            updatedColumns.splice(result.destination?.index ?? result.source.index, 0, col);


            updatedColumns.forEach((status, index) => {
                status.sort_order = index + 1;
                status.isChanged = true;
            })

            services.updateSections(updatedColumns);
            state.sections = updatedColumns;
            forceUpdate();
        }
    }


    function onSaveNewColumn() {

        if (!state.selectedItem.id) {
            state.selectedItem.id = LIB.StringHelper.generateUUID();
            state.selectedItem.sort_order = state.sections?.length + 1;
            state.selectedItem.column_id = props.statusColumnId;
            state.selectedItem.list_id = props.listId;
            state.selectedItem.isNew = true;
            state.selectedItem.workspace_id = props.workspaceId;
        }
        services.updateSections([state.selectedItem])
        forceUpdate();
    }

    function deleteColumn() {
        services.delete()
    }


    function getColumnsAndRecords(sections: Api.SystemLookupType[], records: { [key: string]: any }[]) {
        const emptyColumn = new Api.SystemLookupType();
        emptyColumn.name = 'Unassigned';
        emptyColumn.id = '';
    
        var allColumns = [emptyColumn, ...sections];
    
      console.log('getColumnsAndRecords allColumns', allColumns);
        //console.log('getColumnsAndRecords records', records);
     
    
        const columnsAndRecords: LY_KanbanSection[] = allColumns.map(x => {
     
            //console.log('getColumnsAndRecords filteredRecords x', x[statusColumnName]);
    
            var filteredRecords = records.filter(r => r[state.statusColumnName] === x.id);
          //  console.log('getColumnsAndRecords filteredRecords', filteredRecords);
    
            return {
                ...x,
                records: filteredRecords
            }
        });
        columnsAndRecords[0].records = records.filter(x => !x[state.statusColumnName]);
        return columnsAndRecords;
    }
    



    return { state, onDropHandler, deleteColumn, forceUpdate, onDragColumnEnd, onSaveNewColumn };



};

// Create Kanban context
const LY_KanbanContext = createContext<LY_KanbanContextProps | undefined>(undefined);

// Hook to use Kanban context
export const useKanbanContext = () => {
    const context = useContext(LY_KanbanContext);
    if (!context) throw new Error("useKanbanContext must be used within LY_KanbanContextProvider");
    return context;
};

// Provider component for the Kanban context
export const LY_KanbanContextProvider: React.FC<LY_KanbanProps & { children: React.ReactNode }> = (props) => {
    const contextValue = useKanbanState(props);

    return (
        <LY_KanbanContext.Provider value={contextValue}>
            {props.children}
        </LY_KanbanContext.Provider>
    );
};
