import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';
import * as Api from 'app-api';
import * as Icons from '@tabler/icons-react';
 

import './DlaBidStats.css';
 

interface DlaBidStatsProps {
  contractId:any;
  data:any;
}

export const DlaBidStats: React.FC<DlaBidStatsProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const currentUser = App.AppBase.currentUser; 
  var workspaceVm = App.AppBase.getCurrentWorkspaceVm();
  var currentWorkspace = workspaceVm.selectedWorkspace;
 
  React.useEffect(() => {

    console.log('DlaBidStats useEffect contractId', props.contractId);
    console.log('DlaBidStats useEffect data', props.data);
 

   // getData(sm);

  }, [props.contractId]);



  function getStatsData(){
 
   if(!props.data)
      return [];

    var data = props.data;

    var result = [];
    result.push({ title: 'Bid Price', icon: 'coin', value: data.bidPrice, isMoney:true });
    result.push({
        title: 'Bid Unit Price', icon: 'coin',
        value: <div className='DlaBidStats2Values'
        ><div title='Sum of the quantity in Product Details/Quotes'
        >${data.bidUnitPrice}</div> 
        <div title='Last Awarded Unit Price (Verify)' style={{fontSize:'16px'}}
        >LAST: ${data.lastUnitPrice}</div></div>, 
        //value: data.bidUnitPrice, 
       // isMoney:true ,
        iconTooltip:' Quantity is the sum quantity of product line items.' 
       });


    result.push({ title: 'Quantity', 
    icon: 'info',
    value: <div className='DlaBidStats2Values'
    ><div title='Sum of the quantity in Product Details/Quotes'
    >{data.quantity}</div> 
    <div title='Quantity Requested in RFQ'
    >ASKED: {data.rfqQuantity}</div></div>, 
    iconTooltip:' Quantity is the sum quantity of product line items.' });
    result.push({ title: 'Cost Total', icon: 'coin', value: data.costTotalPrice, isMoney:true, color:'red' });
    result.push({ title: 'Margin', icon: 'coin', value: data.pctProfitMargin, showNumColor:true, isPct:true });
    result.push({ title: 'Profit', icon: 'coin', value: data.profit , isMoney:true, showNumColor:true });
 
    return result;
  }
 
 
  function render(){
 

    var statsData =  getStatsData();



    return <LY.LY_StatsGrid 
      data = {statsData}
    />;

  }



  return render();
};
