//@ts-nocheck
import './LY_KanbanBoard.css';

import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { Button, Group, Modal } from '@mantine/core';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';

import { LY_AddOrChangeItem } from '../LY_AddOrChangeItem/LY_AddOrChangeItem';
import { LY_KanbanBoard_Column } from '../LY_KanbanBoard_Column/LY_KanbanBoard_Column';
import { PlusIcon } from '@radix-ui/react-icons';
import { useKanbanContext } from '../../Context/LY_KanbanModuleContext';

const LY_KanbanBoard: React.FC<LY.LY_KanbanProps> = ({ ...props }) => {


    const context = useKanbanContext();
    const state = context.state;
    const sections = state.sections;


    useEffect(() => {
        if (sections?.length) {
            setOrdered(sections.map((x) => x.id ?? ''));
        }
    }, [sections]);

    const [ordered, setOrdered] = useState<string[]>([]);


    function onDragEnd(result: DropResult) {


        const { destination, source } = result

        console.log('LY_KanbanBoard onDragEnd' , result);

        //return;

        if (source.droppableId === destination?.droppableId && source.index === destination.index) {
            return
        }


        if (!result.destination) 
            return;
 
        if (result.type !== 'COLUMN') {
            console.log('LY_KanbanBoard onDragEnd onDropHandler LIST' , result);

            context.onDropHandler(result);
        } else {
            console.log('LY_KanbanBoard onDragEnd onDropHandler COLUMN' , result);

            context.onDragColumnEnd(result);
        }
    };



    function newColumnRenderer() {
        return (
            <div
                className={"LY_KanbanBoard_New_Column"}
                onClick={() => {
                    state.isColumnModalOpened = true;
                    state.selectedItem = new Api.SystemLookupType();
                    state.selectedItem.name = "";
                    context.forceUpdate();
                }}
                draggable={false}>
                <PlusIcon width={18} height={18} />
                <div>
                    Add/Edit Section
                </div>
            </div>

        )
    };



    function getDnDArea() {



        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="board"
                    type="COLUMN"
                    direction="horizontal"
                    ignoreContainerClipping={true}
                    isCombineEnabled={false}
                >
                    {(provided) => (
                        <div className="LY_KanbanBoard_Column LY_Scrollbar" ref={provided.innerRef} {...provided.droppableProps}>
                            {ordered?.map((key, index) => {
  
                                let section = state.sections?.find((i) => i.id === key);

                               // console.log('LY_KanbanBoard getDnDArea column', column, index);
 
                                return section ? (
                                    <LY_KanbanBoard_Column
                                        {...props}
                                        key={key}
                                        index={index}
                                        title={section?.name ?? `Column ${index}`}
                                        section={section}
                                        isScrollable={true}
                                        isCombineEnabled={false}
                                        useClone={true}
                                    />
                                ) : null;
                            })}
                            {provided.placeholder}
                            {newColumnRenderer()}
                        </div >
                    )}
                </Droppable>
            </DragDropContext >

        );
    };




    function render() {
       // if (!sections.length) return <LIB.Loading />
        return (
            <>
                <LY_AddOrChangeItem />
                {getDnDArea()}
            </>
        );
    };



    return render();
};

export default LY_KanbanBoard;
