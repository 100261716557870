import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';

import * as Api from 'app-api';

import '../ListTableView.css';
import { Badge, Tooltip } from '@mantine/core';

interface ListTableViewTableCellRendererProps {
  state: App.ListState;

  model: LY.DataItemModel;
  forceReadOnly: boolean;
  disableNameClick?: boolean;
  onChange?: (model: LY.DataItemModel | undefined, event?: React.ChangeEventHandler | undefined) => void;
  forceUpdateState?: (state?: App.ListState | undefined) => void;
  getRelationshipItemClick?: (model: LY.DataItemModel | undefined, event: React.MouseEvent<HTMLElement> | undefined) => void;
  onNameClick?: (model: LY.DataItemModel | undefined, event: React.MouseEvent<any> | undefined) => void;

}

export const ListTableViewTableCellRenderer: React.FC<ListTableViewTableCellRendererProps> = ((
  {
    state,
    /*  row,
     col,
     value,
     rowId,
     viewId, */
    model,
    onChange,
    forceReadOnly,
    ...props

  }) => {

  const forceUpdate = LIB.useForceUpdate();
  const newItemInput = useRef<HTMLInputElement>(null);
  const newItem = useRef<LY.LY_TextInputHandle>(null);
  const context = React.useContext(LY.ListTableViewContext);

  const workspaceVm = App.AppBase.getCurrentWorkspaceVm();

  const workspace = workspaceVm?.selectedWorkspace;

  /* 
    function onBtnSaveClick() {
      console.log('ListTableViewTableCellRenderer onNewItemSave');
  
      newItem.current?.sendUpdates();
  
     
  
  
    }
   */



  function onLocalChange(m: LY.DataItemModel | undefined, e: any) {

    console.log('ListTableViewTableCellRenderer onLocalChange m: ', m);
    //console.log('ListTableViewTableCellRenderer onLocalChange model: ',model);
    // console.log('LY_NameColumnInput getViewMode model.value3: ',model?.value);

    if (!m)
      return

    model.value = m.value;

    console.log('ListTableViewTableCellRenderer onLocalChange getViewMode model.value4: ', model?.value);
    //console.log('LY_NameColumnInput getViewMode model.value5: ',m?.value);


    if (onChange)
      onChange(model, e);

    //forceUpdate();
  }




  function render() {

    var col = model?.column;
    //console.log('ListTableViewTableCellRenderer model',model);
    //console.log('ListTableViewTableCellRenderer model.originalValue',model?.originalValue);

    if (model?.isEditMode) {
      console.log('ListTableViewTableCellRenderer col', col);
      console.log('ListTableViewTableCellRenderer model', model);

    }

    //console.log('ListTableViewTableCellRenderer linkedWorkspaceId', state?.listFilterSm?.linkedWorkspaceId);
    //console.log('ListTableViewTableCellRenderer state.linkedWorkspaceId', state?.linkedWorkspaceId);



    var readOnly = false;


    var linkedWorkspaceId = state?.linkedWorkspaceId;

    if (col?.fkIsLinkedWorkspace && col?.fkWorkspaceId)
      linkedWorkspaceId = col.fkWorkspaceId;


    // var lookupTypes = [ ...workspace?.lookupTypes || [], ...state?.workspaceLookupTypes || []];
    // var lookupTypes = workspace?.lookupTypes || state?.workspaceLookupTypes || [];
    
    //remove duplicates
    var lookupTypes = Array.from(new Set([
      ...workspace?.lookupTypes || [],
      ...state?.workspaceLookupTypes || []
    ]));

    var result = <LY.LA_InputSelector
      key={`formItem_${col.name}_${model?.rowId}`}
      model={model}
      workspaceId={state.workspaceId}
      linkedWorkspaceId={linkedWorkspaceId}
      column={col}

      lookupTypes={lookupTypes}
      users={workspaceVm?.users}
      dropDownData={state.dropDownData}


      forceReadOnly={forceReadOnly}
      name={col.name}
      style={{ width: '100%', height: '100%' }}
      onClick={props.getRelationshipItemClick}
      onChange={onLocalChange}
      onNameClick={props.onNameClick}
      forceUpdateState={props.forceUpdateState}
      isForList={true}
    />


    return result;

  }




  return render();
});
