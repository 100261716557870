import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_EmailAccountSetup_EmailProvider } from '../LY_EmailAccountSetup_EmailProvider/LY_EmailAccountSetup_EmailProvider';
import { LY_EmailAccountSetup_CustomForm } from '../LY_EmailAccountSetup_CustomForm/LY_EmailAccountSetup_CustomForm';

import "./LY_EmailAccountSetup_EmailProvider_Instructions.css";

interface LY_EmailAccountSetup_EmailProvider_InstructionsProps extends LY.LY_EmailAccountSetupModuleProps {
}

export const LY_EmailAccountSetup_EmailProvider_Instructions: React.FC<LY_EmailAccountSetup_EmailProvider_InstructionsProps> = (props) => {

    const context = LY.useEmailAccountSetupModuleContext()
    const state = context.state

    let passwordLabel: string = "Password"

    if (state.selectedAccountType.emailProviderTypeId === state.EmailProviderTypeEnum.GoogleGSuite || state.selectedAccountType.emailProviderTypeId === state.EmailProviderTypeEnum.GoogleGmailFree) {
        passwordLabel = "App password"
    }

    function getAuthTypeIcon(id: number) {

        if (id === state.EmailProviderAuthTypeEnum.Password)
            return <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5002 19.5423C9.85922 19.5423 9.24453 19.2877 8.79132 18.8345C8.33811 18.3813 8.0835 17.7666 8.0835 17.1257C8.0835 15.7844 9.15891 14.709 10.5002 14.709C11.1411 14.709 11.7558 14.9636 12.209 15.4168C12.6622 15.87 12.9168 16.4847 12.9168 17.1257C12.9168 17.7666 12.6622 18.3813 12.209 18.8345C11.7558 19.2877 11.1411 19.5423 10.5002 19.5423ZM17.7502 23.1673V11.084H3.25016V23.1673H17.7502ZM17.7502 8.66732C18.3911 8.66732 19.0058 8.92193 19.459 9.37514C19.9122 9.82836 20.1668 10.443 20.1668 11.084V23.1673C20.1668 23.8083 19.9122 24.4229 19.459 24.8762C19.0058 25.3294 18.3911 25.584 17.7502 25.584H3.25016C2.60922 25.584 1.99453 25.3294 1.54132 24.8762C1.08811 24.4229 0.833496 23.8083 0.833496 23.1673V11.084C0.833496 9.74273 1.90891 8.66732 3.25016 8.66732H4.4585V6.25065C4.4585 4.6483 5.09503 3.11158 6.22806 1.97855C7.36109 0.845515 8.89781 0.208984 10.5002 0.208984C11.2936 0.208984 12.0792 0.365257 12.8122 0.668879C13.5452 0.972501 14.2112 1.41753 14.7723 1.97855C15.3333 2.53957 15.7783 3.2056 16.0819 3.93861C16.3856 4.67161 16.5418 5.45725 16.5418 6.25065V8.66732H17.7502ZM10.5002 2.62565C9.53875 2.62565 8.61672 3.00757 7.9369 3.68739C7.25708 4.36721 6.87516 5.28924 6.87516 6.25065V8.66732H14.1252V6.25065C14.1252 5.28924 13.7432 4.36721 13.0634 3.68739C12.3836 3.00757 11.4616 2.62565 10.5002 2.62565Z" fill="#757B87" />
            </svg>


        if (id === state.EmailProviderAuthTypeEnum.Api_Key)
            return <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 14.8679L8.87062 11.9993L11.5 9.1307L14.1294 11.9993L11.5 14.8679ZM8.70629 6.93767L6.48183 4.51228L10.0052 0.666945C10.2182 0.435546 10.4548 0.2663 10.7151 0.159206C10.9772 0.0530685 11.2388 0 11.5 0C11.7612 0 12.0224 0.0530685 12.2836 0.159206C12.5447 0.265344 12.7814 0.43459 12.9935 0.666945L16.5182 4.51085L14.2937 6.9391L11.5 3.8898L8.70629 6.93767ZM4.63601 17.474L1.11133 13.6301C0.899227 13.3977 0.744094 13.1395 0.645931 12.8556C0.548644 12.5697 0.5 12.2842 0.5 11.9993C0.5 11.7143 0.548644 11.4294 0.645931 11.1444C0.743217 10.8595 0.898351 10.6013 1.11133 10.3699L4.6347 6.52459L6.86046 8.95141L4.06675 11.9993L6.86046 15.0472L4.63601 17.474ZM18.364 17.474L16.1395 15.0472L18.9332 11.9993L16.1395 8.95141L18.364 6.52459L21.8887 10.3685C22.1008 10.6008 22.2559 10.859 22.3541 11.143C22.4514 11.4289 22.5 11.7143 22.5 11.9993C22.5 12.2842 22.4514 12.5692 22.3541 12.8541C22.2568 13.1391 22.1017 13.3972 21.8887 13.6286L18.364 17.474ZM10.0065 23.3331L6.48183 19.4892L8.70629 17.0609L11.5 20.1088L14.2937 17.0609L16.5182 19.4877L12.9948 23.3331C12.7818 23.5645 12.5452 23.7337 12.2849 23.8408C12.0228 23.9469 11.7612 24 11.5 24C11.2388 24 10.9776 23.9469 10.7164 23.8408C10.4553 23.7347 10.2186 23.5654 10.0065 23.3331Z" fill="#757B87" />
            </svg>


        return <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.7006 10.3748L16.0941 5.06923C15.6066 4.76893 15.058 4.61133 14.5002 4.61133C13.9423 4.61133 13.3937 4.76893 12.9062 5.06923L4.29912 10.3741C3.51162 10.8598 3.51162 12.114 4.29912 12.5996L12.9056 17.9058C13.3931 18.2061 13.9417 18.3637 14.4996 18.3637C15.0574 18.3637 15.6061 18.2061 16.0935 17.9058L24.7006 12.5996C25.4881 12.114 25.4881 10.8598 24.7006 10.3741" stroke="#757B87" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.8462 13.7441L24.7006 14.8866C25.4881 15.3729 25.4881 16.6271 24.7006 17.1135L22.8456 18.2553L16.0947 22.4196C15.6068 22.7197 15.058 22.8771 14.4999 22.8771C13.9418 22.8771 13.3929 22.7197 12.905 22.4196L6.15354 18.2553L4.29912 17.1135C3.51162 16.6271 3.51162 15.3729 4.29912 14.8866L6.15354 13.7441" stroke="#757B87" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.8462 18.2559L24.7006 19.399C25.4881 19.8847 25.4881 21.1389 24.7006 21.6252L16.0947 26.9313C15.6068 27.2314 15.058 27.3888 14.4999 27.3888C13.9418 27.3888 13.3929 27.2314 12.905 26.9313L4.29912 21.6252C3.51162 21.1389 3.51162 19.8847 4.29912 19.3983L6.15354 18.2559" stroke="#757B87" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    }

    function onInputChange(value: string, key: keyof Api.EmailAccount) {

        state.errors.delete(key)

        const item = state.newAccountSm

        //@ts-ignore
        item[key] = value

        context.forceUpdate();
    }

    function emailProviderAuthTypesRenderer() {

        if (!state.selectedAccountType || !state.selectedAccountType.authTypes || state.selectedAccountType.authTypes.length <= 1) {
            return null;
        }

        return (
            <div className="LY_EmailAccountSetup_EmailProvider_Instructions_AuthTypes_Container">

                {state.selectedAccountType.authTypes.map(a => {

                    const isActive = a.emailProviderAuthTypeId === state.selectedEmailProviderAuthtype.emailProviderAuthTypeId;

                    const containerClassName = isActive
                        ? "LY_EmailAccountSetup_EmailProvider_Instructions_AuthType_Container LY_EmailAccountSetup_EmailProvider_Instructions_AuthType_Container_Active"
                        : "LY_EmailAccountSetup_EmailProvider_Instructions_AuthType_Container";

                    return (
                        <div key={a.emailProviderAuthTypeId} onClick={() => context.setSelectedAuthType(a.emailProviderAuthTypeId)} className={containerClassName}>
                            <div>
                                {getAuthTypeIcon(a.emailProviderAuthTypeId)}
                            </div>
                            <span>
                                {context.getAuthTypeName(a.emailProviderAuthTypeId)}
                            </span>
                        </div>
                    );
                })}

            </div>
        );

    }

    function emailProviderPasswordAuthFormErrorRenderer() {

        if (state.isPasswordAuthErrorMessageVisible) {
            return <div className="LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Error">{state.passwordAuthErrorMessage}</div>
        }

        return null

    }

    function emailProviderPasswordAuthFormRenderer() {

        if (!state.selectedEmailProviderAuthtype)
            return null

        if (state.selectedEmailProviderAuthtype.emailProviderAuthTypeId === state.EmailProviderAuthTypeEnum.Password) {

            return <div className="LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Container">

                {emailProviderPasswordAuthFormErrorRenderer()}

                <div className="LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Wrapper">
{/*                     <div className="LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Line">
 */}                        <LY.LY_Input
                            inputContainerClassName='LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Line_Input_Container'
                            error={state.errors.get("first_name")}
                            value={state.newAccountSm.first_name}
                            onChange={(m, e) => {
                                onInputChange(e?.target.value, "first_name");
                            }}
                            label="First Name"
                            labelPlacement='top'
                            isRequired={true}
                            name="LY_EmailAccountSetup_NameInput" />
                        <LY.LY_Input
                            inputContainerClassName='LY_EmailAccountSetup_EmailProvider_Instructions_PasswordForm_Line_Input_Container'
                            error={state.errors.get("last_name")}
                            value={state.newAccountSm.last_name}
                            onChange={(m, e) => {
                                onInputChange(e?.target.value, "last_name");
                            }}
                            label="Last Name"
                            labelPlacement='top'
                            isRequired={true}
                            name="LY_EmailAccountSetup_LastNameInput" />
{/*                     </div>
 */}
               
                        <LY.LY_Input
                            error={state.errors.get("email")}
                            value={state.newAccountSm.email}
                            onChange={(m, e) => {
                                onInputChange(e?.target.value, "email");
                            }}
                            label="Email"
                            labelPlacement='top'
                            isRequired={true}
                            name="LY_EmailAccountSetup_EmailInput"
                        />
                        <LY.LY_Input
                            error={state.errors.get("password")}
                            value={state.newAccountSm.password}
                            onChange={(m, e) => {
                                onInputChange(e?.target.value, "password");
                            }}
                            label={passwordLabel}
                            type='password'
                            labelPlacement='top'
                            isRequired={true}
                            name="LY_EmailAccountSetup_LastNameInput" />
                     
                </div>

            </div>

        }

        return null

    }

    function emailProviderSelectedAuthTypeInstructionRenderer() {

        if (!state.selectedAccountType)
            return null;

        if (state.selectedAccountType.emailProviderTypeId === state.EmailProviderTypeEnum.CustomOther) {

            return <LY_EmailAccountSetup_CustomForm {...props}/>

        }

        if (state.selectedAccountType.authTypes && state.selectedAccountType.authTypes.length > 0) {

            return <div className="LY_EmailAccountSetup_EmailProvider_Instructions_BottomPart_Container">

                <div
                    className="LY_EmailAccountSetup_EmailProvider_AuthType_Instructions LY_Scrollbar"
                    dangerouslySetInnerHTML={{
                        __html: state.selectedEmailProviderAuthtype.setup_instructions!
                    }}
                />

                {emailProviderPasswordAuthFormRenderer()}

            </div>
        }

    }

    function render() {

        return <div className="LY_EmailAccountSetup_EmailProvider_Instructions_Container">

            <div className="LY_EmailAccountSetup_EmailProvider_Instructions_SelectedProvider_Container">
                <LY_EmailAccountSetup_EmailProvider {...props} emailProviderType={state.selectedAccountType} />
            </div>

            {emailProviderAuthTypesRenderer()}

            {emailProviderSelectedAuthTypeInstructionRenderer()}

        </div>
    }

    return render();
};
