import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';

import { LY_ListViewTabsState } from "../State/LY_ListViewTabsModuleState";

export class LY_ListViewTabsManager {


    public service: App.ListViewService;
    public sm: Api.ListViewGetDataSm;
    public forceUpdate: () => void;
    public state: LY_ListViewTabsState;
    public props: LY.LY_ListViewTabsModuleProps;
    public context: LY.LY_ListViewTabsContextProps;

    constructor(props: LY.LY_ListViewTabsModuleProps, state: LY_ListViewTabsState, context: LY.LY_ListViewTabsContextProps) {


        this.sm = new Api.ListViewGetDataSm()
        this.sm.listId = props.listId;
        this.state = state;
        this.forceUpdate = context.forceUpdate;

        this.props = props;

        this.service = new App.ListViewService(props.workspaceId)
        this.service.linkedWorkspaceId = this.props.linkedWorkspaceId;

        this.context = context;

    }



    public getViews() {
        this.state.isActionInProgress = true;
        this.forceUpdate();


        return this.service.getListViews(this.sm, (result: Api.ListViewSm[]) => {
            this.state.tabs = result;
            this.state.isActionInProgress = false;
            this.forceUpdate();

        }, this.onServiceError.bind(this));



    }

    public deleteView(sm: Api.ListViewSm) {

        this.state.isActionInProgress = true;
        this.forceUpdate();

        var id = sm.listViewId;

        return this.service.deleteView(
            id,
            () => {

                console.log('LY_ListViewTabsManager deleteView id', id);

                this.state.tabs = this.state.tabs.filter(t => t.listViewId !== id) || [];
                //   this.state.pinnedTabs = this.state.tabs.filter(t => t.isPinned) || [];

                //  console.log('LY_ListViewTabsManager deleteView pinnedTabs', this.state.pinnedTabs );

                this.context.reset();

                App.AppBase.showSaveSuccess()


                if (this.props.onDeleteViewSuccess)
                    this.props.onDeleteViewSuccess(sm);
                /* 
                                if(this.props.onTabChange && this.state.tabs.length > 0)
                                    this.props.onTabChange(this.state.tabs[0]); */
            },
            this.onServiceError.bind(this));
    }

    public addNewView(sm: Api.ListViewSm) {

        if (!sm)
            return;

        sm.isSettingsOnlyUpdate = true;

        this.state.isActionInProgress = true;
        this.forceUpdate();

        sm.sortOrder = this.state.tabs.length + 1;



        return this.service.createView(
            sm,
            //OnSuccess
            (result: Api.ListViewSm) => {


                this.state.selectedSettingsItem = result;
                this.state.selectedTab = result;
                this.state.tabs.push(result);
                // this.state.pinnedTabs = this.state.tabs.filter(t => t.isPinned) || [];
                this.state.isActionInProgress = false;
                this.state.isNewTabModalOpen = false;
                this.forceUpdate();
                App.AppBase.showSaveSuccess()

                if (this.props.onNewViewSuccess)
                    this.props.onNewViewSuccess(result);
            },
            //OnError
            this.onServiceError.bind(this));



    }
    public updateView(sm: Api.ListViewSm) {

        if (!sm)
            return;

        sm.isSettingsOnlyUpdate = true;

        this.state.isActionInProgress = true;
        this.forceUpdate();


        return this.service.updateView(
            sm,
            //OnSuccess
            (result: Api.ListViewSm) => {

                console.log('LY_ListViewTabsManager updateView result', result);

                this.state.tabs = this.state.tabs.map(x => x.listViewId === result.listViewId ? result : x);

                //  this.state.pinnedTabs = this.state.tabs.filter(t => t.isPinned) || [];

                //   console.log('LY_ListViewTabsManager updateView pinnedTabs', this.state.pinnedTabs);

                this.context.reset();

                App.AppBase.showSaveSuccess()
            },
            //OnError
            this.onServiceError.bind(this));


    }
    public updateViews(tabs: Api.ListViewSm[]) {


        this.state.isActionInProgress = true;


        return this.service.updateViews(
            tabs,
            () => {
                this.context.reset();
                App.AppBase.showSaveSuccess()
            },
            this.onServiceError.bind(this));


    }

    public updateViewSortOrders(newTabs: Api.ListViewSm[]) {


        return this.service.updateViewSortOrders(newTabs, () => {
            App.AppBase.showSaveSuccess()
        }, this.onServiceError.bind(this));


    }
    public onServiceError(error: any) {

        if (this.state == undefined)
            return;
        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
        App.AppBase.showError(errorMessage)


        this.state.isActionInProgress = false;
        this.forceUpdate();

    }
}
