import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';
import * as MT from '@mantine/core';
import * as React from "react";

import { IconDots, IconSettings, IconTable } from '@tabler/icons-react';

import { rem } from "@mantine/core";
 
export const LY_ListViewTabsNewItemContextMenu = () => {


    const context = LY.useListViewTabsContext();
    const { state, forceUpdate  } = context;


    return (
        <MT.Menu shadow="md" width={230} 
        withArrow
        arrowSize={10}
        //arrowPosition='side'
        position='bottom-start'
        >
            <MT.Menu.Target>
                <IconDots size={24} className="LY_DotsIcon" style={{ width: rem(20), height: rem(20) }} />
            </MT.Menu.Target>
            <MT.Menu.Dropdown>
                <MT.Menu.Item 
                    onClick={(e:React.MouseEvent) => {
                        e.stopPropagation();

                        state.isNewTabModalOpen = true;
                        context.setupNewViewItem(App.ListViewTypeEnum.Table);
                        forceUpdate();
  
                    }} 
                    leftSection={<IconTable style={{ width: rem(16), height: rem(16) }} />}
                >
                    Add Table View
                </MT.Menu.Item>

                <MT.Menu.Item 
                    onClick={(e:React.MouseEvent) => {
                        e.stopPropagation();

                        state.isNewTabModalOpen = true;
                        context.setupNewViewItem(App.ListViewTypeEnum.Kanban);
                        forceUpdate();
  
                    }} 
                    leftSection={<Icons.IconLayoutKanban style={{ width: rem(16), height: rem(16) }} />}
                >
                    Add Kanban/Pipeline View
                </MT.Menu.Item>



                <MT.Menu.Item
                    onClick={(e:React.MouseEvent) => {
                        e.stopPropagation();
                        state.isAllTabsSettingsOpen = true;
                        forceUpdate();
                    }}
                    leftSection={<IconSettings style={{ width: rem(16), height: rem(16) }} />}
                >
                    Manage Views
                </MT.Menu.Item>
            </MT.Menu.Dropdown>
        </MT.Menu>
    );
}