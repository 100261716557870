import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_AddColumnModule_ExistingRelColumn.css";


interface LY_AddColumnModule_ExistingRelColumnProps extends LY.LY_AddColumnModuleProps {
  onValueChange: (key: string, value: any) => void;
}

export const LY_AddColumnModule_ExistingRelColumn: React.FC<LY_AddColumnModule_ExistingRelColumnProps> = (props) => {



  const context = LY.useAddColumnModuleContext();
  const state = context.state;
  const services = context.services;
  const manager = context.manager;
  const forceUpdate = context.forceUpdate;

  // var item = state.newViewSm;
  
  const { view, list,  columnTypes } = props.state;

  var errors = state.errors;
  var item = state.newColumn;


 

  function onRelColumnChange(value:any) {

 
    props.onValueChange('fkExistingRelationshipColumnId', value);

    services.getRelationshipInfo(value);
  }


  function getForm() {

    var columns = props.state?.view?.columns;
    var relColumns = columns?.filter(x => x.isRelationship);
 

    return <div className='LY_AddColumnModule_ExistingRelColumn_Container'>

      <LY.LY_DropDown
        name="fkExistingRelationshipColumnId"
        label="Existing Relationship"
        withStar={true}
        placeholder='Select Column'
        value={item.fkExistingRelationshipColumnId}
        displayProperty="displayName"
        valueProperty="listColumnId"
        data={relColumns}

        // selectedItem={dropdownValueTypes?.find(x => x.listColumnTypeId === item.dropdownValueColumnTypeId)}
        isAutoCompleteStyle={true}
        error={errors.get('fkExistingRelationshipColumnId')}

        onChange={(value, option: any) => {
          /* 
                          console.log('LY_AddColumnModule_ExistingRelColumn LY_DropDown dropdownValueColumnTypeId value:', value);
                          console.log('LY_AddColumnModule_ExistingRelColumn LY_DropDown dropdownValueColumnTypeId option:', option);
           */
         // props.onValueChange('fkExistingRelationshipColumnId', option?.value)

          onRelColumnChange(option?.value);
        }}

        inputContainerClassName='LY_AddColumnModule_ExistingRelColumn_Container_Item'

      />


      <LY.LY_Input
        name="Related Workspace/List"
        label="Related Workspace/List"
        readOnly={true}
        value={state?.existingRelColumnInfo?.workspaceAndListName}
        isLoading={state.isLoadingGetExistingRelColumnInfo}
        inputContainerClassName='LY_AddColumnModule_ExistingRelColumn_Container_ItemReadOnly'

      />


<LY.LY_DropDown
        name="fkExistingRelationshipJoinColumnId"
        label="Add/Join Relationship Column"
        withStar={true}
        disabled={state.isLoadingGetExistingRelColumnInfo || !item.fkExistingRelationshipColumnId}
        placeholder='Select Column'
       //  value={item.fkExistingRelationshipJoinColumnId}
        displayProperty="displayName"
        valueProperty="listColumnId"
        data={state?.existingRelColumnInfo?.fkColumns}

        selectedItem={state?.existingRelColumnInfo?.fkColumns?.find(x => x.listColumnId === item.fkExistingRelationshipJoinColumnId)}
        isAutoCompleteStyle={true}
        error={errors.get('fkExistingRelationshipJoinColumnId')}

        onChange={(value, option: any) => {
          /* 
                          console.log('LY_AddColumnModule_ExistingRelColumn LY_DropDown dropdownValueColumnTypeId value:', value);
                          console.log('LY_AddColumnModule_ExistingRelColumn LY_DropDown dropdownValueColumnTypeId option:', option);
           */
         props.onValueChange('fkExistingRelationshipJoinColumnId', option?.value)
        }}

        inputContainerClassName='LY_AddColumnModule_ExistingRelColumn_Container_Item'

      />

    </div>

  }

  function render() {


    return getForm();


  }

  return render();

};
