import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as MT from '@mantine/core';

import { useEffect, useState, useImperativeHandle, useRef } from 'react';


import './AccountSetup.css';

//import styles from './Pricing.module.css';
//styles.LY_Pricing_Limited


import { AccountProfile } from '../Account/Profile/AccountProfile';
import { CompanyProfile } from '../Account/Company/CompanyProfile';
import { CSSProperties } from "react";



//class AccountSetup extends React.Component<App.AccountState, {}> {
const AccountSetup: React.FC<App.AccountState> = (props) => {


  const validationManager: App.ValidationManager = new App.ValidationManager();
  const accountProfileRef = useRef<any>();
  const companyProfileRef = useRef<any>();
  const forceUpdate = LIB.useForceUpdate();


   const [active, setActive] = useState(0);
 // const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
   const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
 


  React.useEffect(() => {
    console.log('AccountSetup useEffect');

    if (!props.hasDataLoaded) {
      App.AppStore.dispatch(App.ActionTypes.ACCOUNT_GET_PAGE_DATA_REQUEST);
    }

  }, []);

 
  React.useEffect(() => {
    console.log('AccountSetup useEffect: activeStep',props.activeStep);
 
    // var newActive =  getActiveTab();

    // console.log('AccountSetup useEffect2 newActive:',newActive);


     setActive(props.activeStep)
 

  }, [props.activeStep, props.forceUpdateCount]);
 
  /* 
  React.useEffect(() => {
    console.log('AccountSetup useEffect 2');
 
    if(props.activeStep!=active)
    setActive(props.activeStep)
  } ); */

  

  function onTabChange(e: React.ChangeEvent<{}>, newValue: number) {
    console.log('onTabChange');

    //var project = props.project;

    // if (LIB.Common.isNullOrEmpty(project) || LIB.Common.isNullOrEmpty(project.aliasId))
    //   return;

    var id: string = (e.currentTarget as any).id;
    if (!LIB.Common.isNullOrEmpty(id))
      id = id.replace('Tab', '');

    var vm = props.vm;
    vm.selectedTabIndex = newValue;

    vm.selectedTab = id;
    //  App.AppBase.history.push(`AccountSetup?id=${project.aliasId}&tab=${vm.selectedTab}`);

    forceUpdate();
  }


  function getActiveTab() {
    var profile = props.profile;
    var company = props.company;

    if(!profile.isProfileDetailsComplete)
      return 0;
    else if(!company?.isCompanyDetailsComplete)
      return 1;
    else
      return 2;

  }

  function nextStep() {
    console.log('nextStep');

    var vm = props.vm;
    var profile = props.profile;
    var company = props.company;

    if (active==0) {
      accountProfileRef?.current?.onSaveClick(null);

    }
    else if (active==1) {

      companyProfileRef?.current?.onSaveClick(null);

    }



  }



  function getSteps() {

     var state = props;

    return <div className='LY_AccountSetupContainer'>

      <MT.Stepper active={active}
         onStepClick={setActive} 
        allowNextStepsSelect={false}
        iconSize={50}
        className='LY_AccountSetupStepper'
        classNames={{ content: 'LY_AccountSetup_Stepper_content' }}
      >


        <MT.Stepper.Step

          className='LY_AccountSetup_Step'
          label="Profile Info" description="Profile Information">
          <AccountProfile
            ref={accountProfileRef}
            state={state}
            hideButtons={true}
          />

        </MT.Stepper.Step>

        <MT.Stepper.Step
          className='LY_AccountSetup_Step'
          label="Company Info" description="Company Information">

          <CompanyProfile
            ref={companyProfileRef}
            state={state}
            hideButtons={true}
          />


        </MT.Stepper.Step>

        <MT.Stepper.Step

          //ts-ignore
          // classNames={{ contnet: 'LY_AccountSetup_Step' }}


          label="Billing" description="Select Billing Plan">

          <h2>Select Your Plan</h2>

          <App.BillingPricingTable

            billingPlans={state.billingPlans}
            pricingGridClassName="LY_Pricing_PricingGrid"
            offer={state.offer}
          />

        </MT.Stepper.Step>



        <MT.Stepper.Completed>


          Steps done

        </MT.Stepper.Completed>
      </MT.Stepper>


      <div style={{ display: 'flex', justifyContent: "space-between", }}>
        <MT.Button variant="default"
         onClick={prevStep}
        >Back</MT.Button>

        {active < 2 &&
          <MT.Button
            onClick={() => nextStep()}
          >Save And Continue</MT.Button>
        }
      </div>


    </div>;

  }

  function render() {
    console.log('AccountSetup render');

    var state = props;
    var vm = props.vm;

     console.log("AccountSetup activeStep", state.activeStep);



    var state = props;
    var vm = state.vm;
    //var offer = vm.offer;

    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      //loading={true}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      //hasNoPageDesign={true}
      isNarrowPage={true}
    /*   mainPanelsClassName="LY_AccountSetup_MainPanels"
      mainPageContainerClassName='LY_AccountSetup_MainPageContainer'
      mainPanelsContentClassName='LY_AccountSetup_MainPanelsContent'
      mainContainerClassName='LY_MainContainerAutoHeight'
      */ 
      mainPanelsContentClassName='LY_AccountSetup_MainPageContainer'
 
    >

      <h1 className="LY_Pricing_Title">

        Account Setup

      </h1>

      {getSteps()}


    </App.MainSplitPage>
      ;


  }

  return render();
};
export default connect(
  (state: App.AppState) => ({ ...state.account })
)(AccountSetup as any);
