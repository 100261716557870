import './LY_ListSettingsModal.css'


import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { Tabs, Modal } from '@mantine/core';


export const LY_ListSettingsModal = () => {

  const context = LY.useListSettingsModuleContext();
  const state = context.state;
  const service = context.services
  const listSettingsState = context.state.listSettingsState;
  const isActionInProgress = state.isActionInProgress;
  var errors = state.errors;


  function validateAll(): boolean {

    var item = state.listSettingsForm;

    state.errors.clear();

      if (LIB.Common.isNullOrEmpty(item?.name))
        state.errors.set('name', `Name is required!`);
      
      if (LIB.Common.isNullOrEmpty(item?.internalName))
        state.errors.set('internalName', `Internal Name is required!`);

    var isValid = (state.errors.size < 1);
    return isValid;

  }

  function onInputChange(value: string, key: string) {
    console.log(key, value);
    
    const item = state.listSettingsForm

    if(!item) return;

    if (key == 'name'  ) {
      item['internalName'] = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }
    else  if (key == 'internalName' ) {
      value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }
 
      //@ts-ignore
     item[key] = value
 
   
/*     if(key === 'internalName') {
      item[key] = value.toLowerCase()
    } 
 */
    context.forceUpdate();
    validateAll()
  }

  function onSaveClick(e: React.MouseEvent) {

    state.isActionInProgress = false;

    var isValid = validateAll();
    if (!isValid) {


      console.log('LY_ListSettingsModalForm onSaveClick isValid:', isValid, errors);


      context.forceUpdate();
      return false;
    }

    service.updateListSettings()
  }

  React.useEffect(() => {
    context.services.getListSettings()
  }, [])
  

  function render() {



    return <LY.LY_Modal
      centered
      opened={true}
      onClose={() => context.onCancelOrCloseReset()}
      title={`List ${service.props.listName} Settings`}
      size={'lg'}
    
    >
      {isActionInProgress ? <div className='LY_ListSettingsModal_LoaderContainer'>
        <MT.Loader />
      </div> : <div className='LY_ListSettingsModal_Content'>
        <Tabs defaultValue="general">
          <Tabs.List>
            <Tabs.Tab value="general">General</Tabs.Tab>
            <Tabs.Tab value="forms">Forms</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="general">
            <div className='LY_ListSettingsModal_Inputs'>
              <LY.LY_Input
                name="name"
                inputClassName='LY_ListSettingsModal_InputsName'
                value={state.listSettingsForm?.name}
                type='text'
                label="Display Name"
                placeholder="Display Name"
                labelPlacement='top'
                withStar={true}
                error={state.errors.get('name')}
                onChange={(m, e) => {
                  onInputChange(e?.target.value, e?.target.name);
                }}
              />
              <LY.LY_Input
                name="internalName"
                inputClassName='LY_ListSettingsModal_InputsName'
                value={state.listSettingsForm?.internalName}
                type='text'
                label="Internal Name"
                placeholder="Internal Name"
                labelPlacement='top'
                withStar={true}
                error={state.errors.get('internalName')}
                onChange={(m, e) => {
                  onInputChange(e?.target.value, e?.target.name);
                }}
              />
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="forms">
            <div className='LY_ListSettingsModal_Inputs'>
              <LY.LY_DropDown
                name='quickCreateFormViewId'
                style={{ width: '250px' }}
                label="Quick Create Form:"
                placeholder="Default Auto Form"
                isAutoCompleteStyle={true}
                displayProperty='name'
                data={listSettingsState?.formViews}
                valueProperty={"formViewId"}
                selectedItem={listSettingsState?.formViews?.find(item => item.formViewId === state.listSettingsForm?.quickCreateFormViewId)}
                onChange={(item) => onInputChange(item.formViewId, 'quickCreateFormViewId')}

              />
              <LY.LY_DropDown
                name='quickEditFormViewId'
                style={{ width: '250px' }}
                label="Quick Edit Form:"
                placeholder="Default Auto Form"
                isAutoCompleteStyle={true}
                displayProperty='name'
                data={listSettingsState?.formViews}
                valueProperty={"formViewId"}
                selectedItem={listSettingsState?.formViews?.find(item => item.formViewId === state.listSettingsForm?.quickEditFormViewId)}
                onChange={(item) => onInputChange(item.formViewId, 'quickEditFormViewId')}
              />
              <LY.LY_DropDown
                name='createFormViewId'
                style={{ width: '250px' }}
                label="Create Form:"
                displayProperty='name'
                data={listSettingsState?.formViews}
                isAutoCompleteStyle={true}
                valueProperty={"formViewId"}
                selectedItem={listSettingsState?.formViews?.find(item => item.formViewId === state.listSettingsForm?.createFormViewId)}
                onChange={(item) => onInputChange(item.formViewId, 'createFormViewId')}
                placeholder="Default Auto Form"
              />
              <LY.LY_DropDown
                name='editFormViewId'
                style={{ width: '250px' }}
                label="Edit Form:"
                placeholder="Default Auto Form"
                displayProperty='name'
                isAutoCompleteStyle={true}
                data={listSettingsState?.formViews}
                valueProperty={"formViewId"}
                selectedItem={listSettingsState?.formViews?.find(item => item.formViewId === state.listSettingsForm?.editFormViewId)}
                onChange={(item) => onInputChange(item.formViewId, 'editFormViewId')}
              />
            </div>
            { service.props.editFormsLink && <div className='LY_ListSettingsModal_EditFormsLink'>
                <a href={service.props.editFormsLink} target='__blank'>Edit Forms</a>
              </div>}
          </Tabs.Panel>
        </Tabs>
      </div>}
      <div className='LY_ListSettingsModal_Buttons'>
        <LY.LY_ButtonCancel onClick={context.onCancelOrCloseReset}>Cancel</LY.LY_ButtonCancel>
        <LY.LY_ButtonDelete onClick={() => context.handleMenuItemClick('delete')} disabled={isActionInProgress} > Delete </LY.LY_ButtonDelete>
        <LY.LY_Button onClick={onSaveClick} disabled={isActionInProgress}>Save</LY.LY_Button>
      </div>
    </LY.LY_Modal>
  }

  return render();
}
