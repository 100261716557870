import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_ListPageModuleState } from "../Context/LY_ListPageModuleState";
import { LY_ListPageModuleServiceManager } from './LY_ListPageModuleServiceManager';

export class LY_ListPageModuleManager {


  public forceUpdate: () => void;
  public state: LY_ListPageModuleState;
  public props: LY.LY_ListPageModuleProps;
  public context: LY.LY_ListPageModuleContextProps;


  public listViewService: App.ListViewService;
 
  constructor(props: LY.LY_ListPageModuleProps, 
              state: LY_ListPageModuleState, 
              context: LY.LY_ListPageModuleContextProps,
              services: LY_ListPageModuleServiceManager
              ) {

    console.log('LY_ListPageModuleManager constructor state', state);        

    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;
 
    this.listViewService = services.listViewService;
  
  }
 
  public onCancelOrCloseReset() {

    var state = this.state;
    state.isActionInProgress = false;

    state.errors.clear();
    state.isAddColumnScreenOpen = false;
    state.isColumnSettingsScreenOpen = false;
    state.isColumnDeleteOpen = false;
    state.isColumnDeleteInProgress = false;
    state.isDetailRightPanelOpen = false; 
    state.isDetailPopupModalOpen = false;
    state.isItemAddOpen = false;
    state.isItemDeleteOpen = false;
    state.isItemEditOpen = false;
    state.isRelActionInProgress = false;
    state.isSearchInProgress = false;
    state.isNewRecordOpen = false;
    state.isPageLoadDone = false;
    state.isActionInProgress = false;
    state.listState = new App.ListState();
    state.viewId = -1; 
    state.sm = new Api.ListGetDataSm();

    this.forceUpdate();
  }

  
  public resetCache(excludeViewId?: number) {
  //  console.log('ListPage resetCache excludeViewId: ', excludeViewId);

    var listStates = this.state.listStates;
    console.log('ListPage resetCache listStates: ', listStates);

    if (!excludeViewId)
    this.state.listStates = [];
    //  setListStates([]);
    else {

      var keepList = listStates.filter(x => x.view?.listViewId == excludeViewId) || [];
      console.log('ListPage resetCache keepList: ', keepList);
      this.state.listStates =keepList;
       
      if (keepList?.length > 0)
        this.state.listState = keepList[0];
         
    }

    this.forceUpdate();
  }

  public onNewButtonClick(){

    var listState = this.state?.listState;

    console.log('LY_ListPageModuleManager onNewButtonClick, listId:', listState?.listId);
    console.log('LY_ListPageModuleManager onNewButtonClick, this.state:', this.state);

    if(listState?.listId!="8420e335-407d-4501-93f2-9f4b4c80199c")
        return null;
 
    this.state.isNewRecordOpen = true;
    this.forceUpdate();
  }

    public onTabChange(tab: Api.ListViewSm) {

    console.log('LY_ListPageModuleManager onChange tab', tab);
    var sm = this.state.sm;
    App.RouteManager.routeToList(tab.listViewId, this.props.workspaceId, sm.linkedWorkspaceId);

  }
 
}
