import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_AddColumnModuleState } from "../Context/LY_AddColumnModuleState";
import { LY_AddColumnModuleManager } from './LY_AddColumnModuleManager';

export class LY_AddColumnModuleServiceManager {


  public forceUpdate: () => void;
  public state: LY_AddColumnModuleState;
  public props: LY.LY_AddColumnModuleProps;
  public context: LY.LY_AddColumnModuleContextProps;


  public listColumnService: App.ListColumnService;
  public manager: LY_AddColumnModuleManager; 

  public sm: Api.ListViewGetDataSm;

  constructor(props: LY.LY_AddColumnModuleProps, state: LY_AddColumnModuleState, context: LY.LY_AddColumnModuleContextProps) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;

    this.sm = new Api.ListViewGetDataSm()

    this.listColumnService = new App.ListColumnService(props.workspaceId)
   // this.listColumnService.linkedWorkspaceId = this.props.linkedWorkspaceId;


  }
 
  public onServiceError(error: any) {

    if (this.state == undefined)
      return;

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    App.AppBase.showError(errorMessage)
    this.state.isActionInProgress = false;
    this.forceUpdate();

  }

  public addColumn(sm:Api.ListColumn) {

  
    console.log('LY_AddColumnModuleServiceManager sm send:', sm);
  
    this.listColumnService.addColumn(sm,
      //onSuccess
      (result: any) => {
        console.log('LY_AddColumnModuleServiceManager onSuccess:', result);

        this.state.errors.clear();
        this.state.isActionInProgress = false;
 
        this.state.isOpen = false;
         
        this.props.refreshData();
        this.forceUpdate();

        if(this.props.onColumnAddedSuccess)
          this.props.onColumnAddedSuccess(result);

      },
      //onError
      (error: any) => {

        console.error('LY_AddColumnModuleServiceManager onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        this.state.isActionInProgress = false;
        this.state.errors.set('all', errorMessage);
        this.forceUpdate();
      });

 
    this.state.isActionInProgress = true;
    this.forceUpdate();

 


  }


  public getRelationshipInfo(relListColumnId:string) {

  
    var sm = new Api.ListColumnGetRelationshipInfoSm();
    sm.relListColumnId = relListColumnId;
    sm.listId = this.props?.state?.listId;

    if(!sm.listId || !sm.relListColumnId){
      this.state.existingRelColumnInfo = undefined;
      this.state.newColumn.fkExistingRelationshipJoinColumnId = undefined;
      this.forceUpdate();
      return;
    }

    console.log('LY_AddColumnModuleServiceManager getRelationshipInfo sm:', sm);

  
    this.listColumnService.getRelationshipInfo(sm,
      //onSuccess
      (result: Api.ListColumnRelationshipInfoResult) => {
        console.log('LY_AddColumnModuleServiceManager getRelationshipInfo onSuccess:', result);

        this.state.errors.clear();
        this.state.isLoadingGetExistingRelColumnInfo = false;
        this.state.existingRelColumnInfo = result; 
 
        this.forceUpdate(); 

      },
      //onError
      (error: any) => {

        console.error('LY_AddColumnModuleServiceManager onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        this.state.isLoadingGetExistingRelColumnInfo = false;
        this.state.errors.set('all', errorMessage);
        this.forceUpdate();
      });

 
    this.state.isLoadingGetExistingRelColumnInfo = true;
    this.forceUpdate();

 


  }

}
