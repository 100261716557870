import React, { ChangeEventHandler, HTMLAttributes, useImperativeHandle, forwardRef, useRef } from 'react';

import * as LY from '_LY_Components';
import * as RIcons from '@radix-ui/react-icons';
import * as LIB from '_LIB';
import * as Icons from '@tabler/icons-react';
import * as MT from '@mantine/core';

import './_LY_DropDownBaseViewEdit.css';
import { Button, Badge, Tooltip } from '@mantine/core';

import { CloseButton, Combobox, Input, InputBase, useCombobox, ScrollArea, ComboboxOptionProps, Text } from '@mantine/core';


/* interface LY_DropDownBaseViewEditRef {
  validateAll: () => void;
} */

type combinedProps = LY.LY_DropDownBaseViewEditProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element
export const _LY_DropDownBaseViewEdit = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    name,
    data,
    optionsItemRenderer,
    displayProperty,
    valueProperty,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    selectedItem,
    selectedItemDisplayValue,
    style,
    model,
    onItemClick,
    onValueChange,
    onChange,
    viewRenderer,
    isLoading,
    //  editRenderer,
    readOnly,
    hideSearch,
    isAutoCompleteStyle = false,
    isBadgeCompleteStyle = true,
    hideLabel,
    selectedItemBadgeVariant = 'light',
    selectedItemBadgeColor = 'var(--ly-global-primary-text-color)',
    selectedItemBadgeSize = 'lg',
    selectedItemBadgeRadius = 'xl',
    isClearable = true,
    withArrow = true,
    closeDropDownOnChange = true,
    arrowSize = 12,
    ...props
  },
  ref // This is the forwarded ref
) => {


  const forceUpdate = LIB.useForceUpdate();

  const [ mode, setMode ] = React.useState('view'); // Add this line
  const [ search, setSearch ] = React.useState<string | undefined>();


  /*   useImperativeHandle(ref, () => ({
      validateAll
    })); */


  React.useEffect(() => {
    // console.log(`_LY_DropDownBaseViewEdit useEffect name:${name} selectedItemDisplayValue: `, selectedItemDisplayValue);


    if (isAutoCompleteStyle)
      setSearch(selectedItemDisplayValue);

  }, [ selectedItemDisplayValue ]);



  const combobox = useCombobox({
    onDropdownClose: (e: MT.ComboboxDropdownEventSource) => {
      combobox?.resetSelectedOption();
      combobox?.focusTarget();

      if (isAutoCompleteStyle)
        setSearch(selectedItemDisplayValue);

      if (props.onDropdownClose)
        props.onDropdownClose(e, combobox);
    },

    onDropdownOpen: (e: MT.ComboboxDropdownEventSource) => {

      if (!isSearchHidden() && combobox?.dropdownOpened) {
        combobox?.focusSearchInput();
      }


      if (props.onDropdownOpen)
        props.onDropdownOpen(e, combobox);
    },
  });

  const combinedInputClassName = `mantine-Input-input mantine-TextInput-input LY_InputInput ${inputClassName || ''}`;

  function isSearchHidden(): boolean {

    if (hideSearch !== undefined)
      return hideSearch;

    var result = hideSearch || isAutoCompleteStyle || false;

    if (!result && data && data.length < 5)
      result = true;

    return result;
  }

  function getActions() {


    // console.log('_LY_DropDownBaseViewEdit getActions mode:', mode);

    if (readOnly)
      return null;

    return <div className='_LY_DropDownBaseViewEditActions'>

      {(mode == 'view' && !readOnly) &&

        <Tooltip label='Edit Item' position='right'
          withArrow

          arrowSize={6}
        >
          <div onClick={() => setMode('edit')}
            className='_LY_DropDownBaseViewEditActionsButton'

          >
            <RIcons.Pencil1Icon />

          </div>
        </Tooltip>
      }

      {
        (mode == 'edit' && !readOnly) &&
        <div
          //onClick={(e:any) => onLocalChange(model!,e)}
          className='_LY_DropDownBaseViewEditActionsButton'
        >
          <RIcons.CheckIcon />
        </div>
      }


    </div >
  }


  function onLocalNameClick(e: React.MouseEvent<HTMLElement>) {

    //e.preventDefault();

    if (onItemClick)
      onItemClick(model, e);

    /*  console.log('_LY_DropDownBaseViewEdit onItemClick');
     var detailUrl = getDetailUrl();
 
     console.log('_LY_DropDownBaseViewEdit onItemClick URL: ',detailUrl);
     console.log('_LY_DropDownBaseViewEdit onItemClick Model: ',model);
  */

  }



  function onLocalChange(m: LY.DataItemModel, val: string | null, optionProps: ComboboxOptionProps | undefined = undefined) {

    /*       console.log('_LY_DropDownBaseViewEdit onLocalChange model:', m);
            console.log('_LY_DropDownBaseViewEdit onLocalChange value:', val);
            console.log('_LY_DropDownBaseViewEdit onLocalChange search:', search);  
    
            console.log('_LY_DropDownBaseViewEdit onLocalChange optionProps:', optionProps);   */

    // if(!isAutoCompleteStyle)

    //if no change 
    if (selectedItem && valueProperty && selectedItem[ valueProperty ] === val)
      return;

    console.log('_LY_DropDownBaseViewEdit onLocalChange set val: ', val);


    onSearchChange('');

    if (onValueChange) {
      /*       console.log('_LY_DropDownBaseViewEdit onLocalChange set2 val2: ',val);
            console.log('_LY_DropDownBaseViewEdit onLocalChange set2 selectedItem: ',selectedItem);
       */
      onValueChange(m, val, selectedItem, optionProps, combobox);

    }

    if (onChange)
      onChange(val, optionProps, combobox);


    console.log('_LY_DropDownBaseViewEdit onLocalChange value: ', value);
    console.log('_LY_DropDownBaseViewEdit onLocalChange selectedItem: ', selectedItem);

    try {
      if (closeDropDownOnChange && combobox.dropdownOpened)
        combobox?.closeDropdown();
    } catch (e) {
      console.log('_LY_DropDownBaseViewEdit onLocalChange error: ', e);
    }



    forceUpdate();
  }


  function onSearchChange(val: string, valueChangeDone: boolean = false) {

    if (!combobox.dropdownOpened)
      combobox.openDropdown();

    setSearch(val)

    if (props.onSearchChange)
      props.onSearchChange(val, combobox);


  }


  function getEmptyOptions() {

    if (isLoading)
      return <Combobox.Empty>
        <MT.Loader size={30} />;
      </Combobox.Empty>;
    return <Combobox.Empty>Nothing found</Combobox.Empty>;
  }



  function getHeaderRenderer() {

    if (props.headerRenderer)
      return props.headerRenderer;

    return null;

    /*     return <Combobox.Header
          className='_LY_DropDownBaseViewEditFooter'
        >
          <a
            className='_LY_DropDownBaseViewEditFooterButton'
            onClick={(e) => {
              // console.log('_LY_DropDownBaseViewEdit onClearClick');
              e.preventDefault();
              onLocalChange(model!, null);
            }}
            aria-label="Clear value"
          >
            Clear
          </a>
    
        </Combobox.Header> */

  }


  function getFooterRenderer() {


    if (props.footerRenderer)
      return props.footerRenderer(combobox);

    if (!selectedItemDisplayValue || !isClearable)
      return null;



    return <Combobox.Footer
      className={'_LY_DropDownBaseViewEditFooter ' + props.footerContainerClassName || ''}
    >
      <a
        className='_LY_DropDownBaseViewEditFooterButton'
        onClick={(e) => {
          // console.log('_LY_DropDownBaseViewEdit onClearClick');
          e.preventDefault();
          onLocalChange(model!, null);
        }}
        aria-label="Clear value"
        style={{ cursor: 'pointer' }}
      >
        Clear
      </a>
      {props.footerRightSectionRenderer && props.footerRightSectionRenderer(combobox)}
    </Combobox.Footer>

  }


  function getOptionsRenderer() {

    console.log('_LY_DropDownBaseViewEditOptionsRenderer name: ', name);



    if (props.optionsRenderer)
      return props.optionsRenderer(combobox);

    // console.log('_LY_DropDownBaseViewEditOptionsRenderer3 optionsRenderer: ', props.optionsRenderer);

    let options: React.JSX.Element[] | undefined = [];

    if (optionsItemRenderer)
      options = optionsItemRenderer(combobox)

    return <Combobox.Options  /* mah={200} style={{ overflowY: 'auto' }} */

      className={'_LY_DropDownBaseViewEditOptions ' + props.optionsContainerClassName || ''}
      style={{ flex: 1 }}
    >
      {options && options.length > 0 ? options : getEmptyOptions()}

    </Combobox.Options>

  }


  function getDropDownPopupOptions() {


    if (!combobox.dropdownOpened)
      return null;

    var hideSearch = isSearchHidden();

    console.log('_LY_DropDownBaseViewEdit getDropDownPopupOptions optionsRenderer: ', props.optionsRenderer);


    var popup = <>

      {!hideSearch &&
        <div
          className={`_LY_DropDownBaseViewEditSearchContainer ${props.searchInputContainerClassName || ''}`}

        //  className={props.searchInputContainerClassName + ' _LY_DropDownBaseViewEditSearchContainer'}
        >
          <Combobox.Search
            value={search}
            onChange={(e) => onSearchChange(e.currentTarget.value)}
            placeholder={'Search'}

            classNames={{
              input: `_LY_DropDownBaseViewEditSearchInput ${props.searchInputClassName || ''} `,
              wrapper: `_LY_DropDownBaseViewEditSearchInputWrapper ${props.searchInputWrapperClassName || ''} `
            }}

            rightSection={props.searchInputRightIcon || <Icons.IconSearch color="#989899" size={20} />}

          />

        </div>
      }

      {props.optionsHeaderRenderer && props.optionsHeaderRenderer(combobox)}

      {getOptionsRenderer()}

      {getFooterRenderer()}
    </>;




    return popup;


  }

  function getSelectedItemAutoCompleteRenderer() {


    /*  if(readOnly)
       return <div>
         {value}
       </div>; */

    return <InputBase
      autoFocus={combobox.dropdownOpened}
      pointer={!isAutoCompleteStyle}
      aria-placeholder={placeholder}
      placeholder={placeholder}

      disabled={props.disabled || readOnly}
      onKeyDown={onKeyDown}

      value={search || ''} // value={search} selectedItemDisplayValue
      /*   onChange={(event) => {
          combobox.openDropdown();
          combobox.updateSelectedOptionIndex();
          setSearch(event.currentTarget.value);
        }} */
      onChange={(e) => onSearchChange(e.currentTarget.value)}

      onClick={() => {

        if (readOnly)
          return;

        combobox.toggleDropdown();

      }}


      rightSectionPointerEvents="none"

      rightSection={
        (!readOnly &&

          <Icons.IconCaretDownFilled
            className='_LY_DropDownBaseViewEditInputDownArrow'
            size={16} />
        )

      }
      //rightSectionPointerEvents={value === null ? 'none' : 'all'}
      className={'_LY_DropDownBaseViewEditInput ' + inputClassName}
      //classNames={{ input: '_LY_DropDownBaseViewEditInputButton', wrapper: '_LY_DropDownBaseViewEditInputButtonWrapper' }}
      classNames={{
        input: '_LY_DropDownBaseViewEditContainerAutoComplete'
      }}

    >


    </InputBase>



  }

  function onKeyDown(e: React.KeyboardEvent<any>) {
    //console.log('_LY_DropDownBaseViewEdit onKeyDown key:', e.key);
    if (e.key == ' ' || e.ctrlKey || e.altKey || e.shiftKey || e.metaKey ||
      (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight')
    ) {
      e.stopPropagation();
      //e.preventDefault();
    }

  }

  function onClick() {
    if (readOnly)
      return;

    combobox.toggleDropdown();

  }

  function getSelectedItemRenderer() {

    if (props.selectedItemRenderer)
      return props.selectedItemRenderer(combobox);

    if (isAutoCompleteStyle)
      return getSelectedItemAutoCompleteRenderer();

    var col = model?.column;

    /*  console.log(`_LY_DropDownBaseViewEdit getDropDownInput name:${name} col: `, col);
     console.log(`_LY_DropDownBaseViewEdit getDropDownInput name:${name} selectedItemBadgeVariant: `, selectedItemBadgeVariant);
     console.log(`_LY_DropDownBaseViewEdit getDropDownInput name:${name} selectedItemBadgeColor: `, selectedItemBadgeColor);
     console.log(`_LY_DropDownBaseViewEdit getDropDownInput name:${name} col textColor: `, col?.textColor);
  */

    return <InputBase
      component="button"
      type="button"
      disabled={props.disabled || readOnly}

      pointer
      aria-placeholder={placeholder || ''}
      rightSection={
        /*   selectedItemDisplayValue ? (
            <CloseButton
              size="sm"
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => onLocalChange(model!,'')}
              aria-label="Clear value"
            />   <Combobox.Chevron  />
          ) :   */

        //check different places for this change
        // (!selectedItemDisplayValue && !readOnly &&
        (!readOnly &&

          <Icons.IconCaretDownFilled size={16} onClick={() => onClick()} />
        )

      }

      onClick={() => onClick()}
      rightSectionPointerEvents={value === null ? 'none' : 'all'}
      className='_LY_DropDownBaseViewEditInput'
      classNames={{
        input: '_LY_DropDownBaseViewEditInputButton',
        wrapper: '_LY_DropDownBaseViewEditInputButtonWrapper'
      }}
      onKeyDown={onKeyDown}

    >
      {selectedItemDisplayValue && isBadgeCompleteStyle &&

        <Badge color={col?.textColor || selectedItemBadgeColor || 'var(--ly-global-primary-text-color)'}
          variant={col?.styleVariant || selectedItemBadgeVariant || 'light'}
          //variant='white'
          size={col?.styleSize || selectedItemBadgeSize || 'lg'}
          radius={col?.styleRadius || selectedItemBadgeRadius || 'xl'}
          //autoContrast
          className='_LY_DropDownBaseViewEditSelectedDisplayBadge'
          style={{ justifyContent: 'left' }}
        >{selectedItemDisplayValue}
        </Badge>

      }
      {selectedItemDisplayValue && !isBadgeCompleteStyle &&

        <span
          className='_LY_DropDownBaseViewEditContainerViewMode'
        >{selectedItemDisplayValue}
        </span>

      }
      {!selectedItemDisplayValue &&
        <span
          className='_LY_DropDownBaseViewEditInputButton'
        >&nbsp; </span>
      }
    </InputBase>



  }


  function getEditMode() {

    var col = model?.column;

    // console.log('_LY_DropDownBaseViewEdit optionsRenderer: ', optionsRenderer);
    // console.log('_LY_DropDownBaseViewEdit data: ', data);

    var optionsContainerStyle = props.optionsContainerStyle;


    var result = (
      <div className={`_LY_DropDownBaseViewEditContainer ${props.editContainerClassName || ''}`}
        style={style}
      >


        {/* <div className='_LY_DropDownBaseViewEditContent'> */}
        <Combobox
          store={combobox}
          withinPortal={true}
          // position="bottom"
          // middlewares={{ flip: false, shift: false }}
          // middlewares={{ closeOnClickOutside: false }}
          onOptionSubmit={(val, optionProps) => {

            // console.log('_LY_DropDownBaseViewEdit onOptionSubmit val: ', val);
            onLocalChange(model!, val, optionProps);

            if (closeDropDownOnChange)
              combobox.closeDropdown();
          }}

          offset={3}
          withArrow={withArrow}
          disabled={props.disabled || readOnly}
          arrowSize={arrowSize}
          zIndex={9999}
        >
          <Combobox.Target

          >

            {getSelectedItemRenderer()}

          </Combobox.Target>

          <Combobox.Dropdown
            className={`_LY_DropDownBaseViewEditDropdown LY_PopoverRoundCorners ${props.optionsContainerClassName || ''}`}
            style={optionsContainerStyle}

          >

            {getDropDownPopupOptions()}

          </Combobox.Dropdown>
        </Combobox>

      </div>
    );


    return result;
  }


  function getViewMode() {

    // console.log('_LY_DropDownBaseViewEdit viewRenderer:', viewRenderer);

    // var memViewRenderer = React.useMemo(() => viewRenderer, [ viewRenderer ]);
    // var memActions = React.useMemo(() => getActions(), [ mode ]);

    if (props.readOnlyShowAsText && readOnly) {
      return <div
        title={selectedItemDisplayValue}
        className={'_LY_DropDownBaseViewEditContainer _LY_DropDownBaseViewEditContainerReadOnly ' + props.editContainerClassName || ''}
        style={style}
      >
        {selectedItemDisplayValue}
      </div>
    }

    if (!viewRenderer)
      viewRenderer = getSelectedItemRenderer();

    return <div
      // onClick={onDoubleClick}
      //onDoubleClick={onDoubleClick}
      onClick={mode == 'view' ? () => setMode('edit') : () => setMode('view')}
      //onClick={() => setMode('edit')}
      className={'_LY_DropDownBaseViewEditContainer ' + (props.editContainerClassName || '')}
      style={style}
    >

      {/*    {localValue} */}
      {viewRenderer}

      {/*    {getActions()}   */}
    </div>

  }

  function renderMode() {

    // console.log('_LY_DropDownBaseViewEdit render mode:', mode);

    // return getEditMode();

    if (mode == 'view')
      return getViewMode();

    //  console.log('_LY_DropDownBaseViewEdit render getEditMode:', mode);

    return getEditMode();

  }

  function render() {

    //   console.log('_LY_DropDownBaseViewEdit render');


    if (hideLabel)
      return renderMode();

    return (
      <LY.LY_InputBase  {...props}
      //key={props.key}
      >
        {renderMode()}

      </LY.LY_InputBase>
    );
  }

  return render();

});


