import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_AddColumnModuleState } from "../Context/LY_AddColumnModuleState";
import { LY_AddColumnModuleServiceManager } from './LY_AddColumnModuleServiceManager';

export class LY_AddColumnModuleManager {


  public forceUpdate: () => void;
  public state: LY_AddColumnModuleState;
  public props: LY.LY_AddColumnModuleProps;
  public context: LY.LY_AddColumnModuleContextProps;


//  public listViewService: App.ListViewService;
 
  constructor(props: LY.LY_AddColumnModuleProps, 
              state: LY_AddColumnModuleState, 
              context: LY.LY_AddColumnModuleContextProps,
              services: LY_AddColumnModuleServiceManager
              ) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;
 
    //this.listViewService = services.listViewService;
  
  }
 
  public onCancelOrCloseReset() {

    this.state.isActionInProgress = false;

    this.state.errors.clear();
    this.forceUpdate();
  }

 
}
