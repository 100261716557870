import './LY_KanbanBoard_Item.css';

import * as Api from "app-api";
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from '@mantine/core'

import { LY_KanbanProps } from '../../Interfaces/LY_KanbanModuleProps';
import React from 'react';
import { useKanbanContext } from '../../Context/LY_KanbanModuleContext';

interface LY_KanbanBoard_ItemProps extends LY_KanbanProps {
    item: { [key: string]: any };
    isDragging: boolean;
    provided: any;

}

const LY_KanbanBoard_Item: React.FC<LY_KanbanBoard_ItemProps> = ({ item, isDragging, provided, ...props }) => {


    const context = useKanbanContext();
    const state = context.state;


    function getInputSelectorRenderer(col: Api.ListColumn) {
  
        if(!col || !item)  
          return null; 
 
        var value = item[ col.name ];  
     
        var itemModel = new LY.DataItemModel(); 

        if(LIB.Common.isNullOrEmpty(value))
          return undefined;
   
        itemModel.rowId = item.id;
        itemModel.row = item;
        itemModel.column = col;
        itemModel.value = value;
        itemModel.listId = props.listId;
        itemModel.workspaceId = props.workspaceId!;

  
/*          var formContainerItemClassName =  props.formContainerItemClassName;
          if(formContainerItemClassName && col.listColumnTypeId == App.ListColumnTypeEnum.Status)
            formContainerItemClassName = formContainerItemClassName+=' '+formContainerItemClassName+'Status';
     */
    
        var result = (
    
          <LY.LA_InputSelector
            key={`Kanban_formItem_${col.name}_${item.id}`}
            model={itemModel}
            linkedWorkspaceId={props.linkedWorkspaceId}
            workspaceId={props.workspaceId}
            column={col}

            lookupTypes={props.lookupTypes}
            dropDownData={props.dropDownData}
            users={props.users}

            forceReadOnly={true}
            name={col.name}
            label={col.displayName}
            value={value}
            
  
            hideLabel={true}
          
            maxDisplayLength={30}
    
            isForList={false}
            isForCard={true}
            tooltip={col.displayName}

          //  inputContainerClassName={`LY_RecordUpdateModule_FormContainerItem ${formContainerItemClassName || ''}`}
    
          />
          /*  <div key={index}>
           {col.displayName}
         </div> */
        );
    
    
        return result;
    
      }
    


    function getCardRenderer() {

        if(props.cardRenderer ){
           return <div className="LY_KanbanBoard_Item_content">
              {props.cardRenderer(item)}
          </div>
        }

        var view = props.listState?.view;
        var selectColumns = view?.filter?.selectColumns;
 
        var listColumns = state?.columns?.filter(x=>selectColumns?.includes(x.name) && !x.isAlwaysSystemHidden);

       return <div className="LY_KanbanBoard_Item_content"
 
          >
            {
            listColumns?.map((col, index) => {

                return getInputSelectorRenderer(col);
            })
            }
           
          </div>



    }

    function render() {
        return (
            <div
                className={`LY_KanbanBoard_Item ${isDragging ? 'dragging' : ''}`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}

                onClick={(e)=>props.onItemClick ?props.onItemClick(item,e) : undefined}

            >

                {getCardRenderer()}
{/*                 <div className="LY_KanbanBoard_Item_content"
                >
                    {props.cardRenderer ? props.cardRenderer(item) : <blockquote>{item?.name}</blockquote>}
                </div> */}
            </div>
        );
    }

    
    return render();
}

export default LY_KanbanBoard_Item;
