import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_AddColumnModule.css";


 import { LY_AddColumnModuleContextProvider } from './Context/LY_AddColumnModuleContext';
import { LY_AddColumnModule_NewColumn } from './Components/LY_AddColumnModule_NewColumn/LY_AddColumnModule_NewColumn';
 


export const LY_AddColumnModule: React.FC<LY.LY_AddColumnModuleProps> = (props) => {

  function render() {
 
    // console.log('LY_FiltersModule render view:', view);
    // console.log('LY_FiltersModule render view.filter:', view?.filter);
    //console.log('LY_FiltersModule render view.orderByColumns:', view?.filter?.orderByColumns);
 
    
    return (
      <LY_AddColumnModuleContextProvider {...props}  >
       {/*  <div className="LY_AddColumnModuleContainer"> */}
        <LY_AddColumnModule_NewColumn {...props} />
   {/*      </div> */}
      </LY_AddColumnModuleContextProvider>
    );
  }




  return render();
};
