import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { createContext, useContext, useEffect } from "react";

import { LY_ListPageModuleServiceManager } from '../Managers/LY_ListPageModuleServiceManager';
import { LY_ListPageModuleManager } from '../Managers/LY_ListPageModuleManager';
import { LY_ListPageModuleState } from './LY_ListPageModuleState';

import { useParams, useLocation } from 'react-router';


import { useDisclosure } from '@mantine/hooks';

export interface LY_ListPageModuleContextProps {
  state: LY_ListPageModuleState;
  forceUpdate: () => void;
  resetState: () => void;
  services: LY_ListPageModuleServiceManager; 
  manager: LY_ListPageModuleManager; 

}

const useListPageModuleState = (props: LY.LY_ListPageModuleProps): LY_ListPageModuleContextProps => {


  const initialState = new LY_ListPageModuleState();

  const [state, setState] = React.useState<LY_ListPageModuleState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_ListPageModuleState>(new LY_ListPageModuleState());

  const params = useParams();
  const location = useLocation();
  var globalUIState = App.AppBase.getGlobalState();

/*   var workspaceVm = globalUIState?.workspaceVm;
  const selectedWorkspace = workspaceVm?.selectedWorkspace;
  const workspaceId = selectedWorkspace?.workspaceId;
  var workspaceItem = workspaceVm?.selectedItem;
  const currentUser = App.AppBase.currentUser; */

  const contextObjBase: LY.LY_ListPageModuleContextProps = {
    state,
    forceUpdate,
    resetState,
    services: {} as LY_ListPageModuleServiceManager,
    manager: {} as LY_ListPageModuleManager
  };

  const services = new LY_ListPageModuleServiceManager(props, state, contextObjBase);
  const manager = new LY_ListPageModuleManager(props, state, contextObjBase,services);
  services.manager = manager;
  
  const contextObj: LY.LY_ListPageModuleContextProps = {...contextObjBase, services: services, manager: manager};

  React.useEffect(() => {

   /*  console.log('LY_ListPageModule useEffect props', props);
    console.log('LY_ListPageModule useEffect props.hasDataLoaded', props.hasDataLoaded);
    console.log('LY_ListPageModule useEffect props.viewId', props.viewId);
    console.log('LY_ListPageModule useEffect selectedWorkspaceItem',   props.selectedWorkspaceItem); */

    state.workspaceId = props.workspaceId;
    state.viewId = props.viewId;
    state.workspaceId = props.workspaceId;
    state.linkedWorkspaceId = props.linkedWorkspaceId;
    state.selectedWorkspaceItem = props.selectedWorkspaceItem;

    var sm = state.sm;
    sm.viewId = props.viewId;
  //  sm.linkedWorkspaceId = Number(params.linked);
 //   sm.workspaceId = Number(params.w);
    sm.linkedWorkspaceId = props.linkedWorkspaceId;
    sm.workspaceId = props.workspaceId;
 
    var workspaceItem  = props.selectedWorkspaceItem;
    var workspaceId = state.workspaceId; 
    if (workspaceItem?.defaultViewId != sm.viewId && !state.isPageLoadDone) {

  //  if (workspaceItem?.defaultViewId != sm.viewId && !isNaN(props.viewId) && !pageLoadDone) {

      var checkWorkspaceId = sm.linkedWorkspaceId || workspaceId;
      var itemWorkspace = props.allWorkspaces?.find(x => x.workspaceId == checkWorkspaceId);

      // let's find the workspace item to select
      let newSelectedItem = itemWorkspace?.workspaceItems?.find(x => x.listViews?.find(b => b.listViewId == sm.viewId) != undefined);
    
      //todo workspaceVm.selectedItem = newSelectedItem;
      workspaceItem = newSelectedItem;
      state.selectedWorkspaceItem = newSelectedItem;
     // console.log('LY_ListPageModule useEffect selectedWorkspaceItem newSelectedItem',   newSelectedItem);


      if(props.onWorkspaceItemChange)
        props.onWorkspaceItemChange(newSelectedItem);

      var newVm = {selectedItem: newSelectedItem, disableOnSelectRouteToPage: true};
      App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE,newVm);
       
    }

    state.isPageLoadDone = true;
 
    if (!sm.viewId)
      return;

    var workspace = App.AppBase.currentWorkspace;
    sm.filter = undefined; 
 
    forceUpdate();

    var stateCopy = clone(state);
    setPropsState(stateCopy);


  }, [ props.viewId, props.workspaceId ]);

  function clone(stateIn: LY_ListPageModuleState): LY_ListPageModuleState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_ListPageModuleState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }


  function forceUpdate() {
    state.listStates = [...state.listStates];
    setState({ ...state })
  }
  
  function resetState() {
    var stateCopy = clone(propsState);
    setState({ ...state })
  }


 

  return contextObj;
};

const LY_ListPageModuleContext = createContext<LY.LY_ListPageModuleContextProps | undefined>(undefined);

export const useListPageModuleContext = () => {


  const context = useContext(LY_ListPageModuleContext)


  if (!context) {
    throw new Error(
      "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_ListPageModuleContextProvider: React.FC<LY.LY_ListPageModuleProps & { children: React.ReactNode }> = (props) => {


  const contextValue = useListPageModuleState(props);

  function render() {


    return (
      <LY_ListPageModuleContext.Provider value={contextValue}>
        {props.children}
      </LY_ListPageModuleContext.Provider>
    );
  }

  return render();
};
export { LY_ListPageModuleContextProvider, LY_ListPageModuleContext };
