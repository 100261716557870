import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';



import { DndContext, DragEndEvent, KeyboardSensor, MouseSensor, PointerSensor, TouchSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { Tabs, rem } from '@mantine/core';

import './LY_Tabs.css';
import { link } from 'fs';

interface LY_TabsProps {
    name: string;
    icons: { type: string, icon: React.ReactNode }[]
    tabs: any[];
    selectedItem?: any;
    idProperty: string;
    onOrderChanged: (tabs: any[]) => void;
    onTabChange?: (tab: any) => void;
    newTabContextMenu: React.ReactNode;
    tabUpdateContextMenu: (item: Api.ListViewSm) => React.ReactNode;
    isDragable?: boolean;
    contentPanels: React.ReactNode[];
    workspaceId: number;
    linkedWorkspaceId?: number | undefined;
}


export const LY_Tabs = React.forwardRef<HTMLSelectElement, LY_TabsProps>((props, ref) => {

    // const workspaceVm = App.AppBase.getCurrentWorkspaceVm();
    //  const currentWorkspace = workspaceVm.selectedWorkspace;

    const [ isDragging, setIsDragging ] = React.useState(false);
    //const linkRefs = React.useRef<(HTMLAnchorElement | null)[]>([]);



    function moveItems(fromIndex: number, toIndex: number) {

        var fromTab = props.tabs[ fromIndex ];
        var toTab = props.tabs[ toIndex ];
        console.log('LY_Tabs moveItems fromTab: ', fromTab);
        console.log('LY_Tabs moveItems toTab: ', toTab);

        if (fromTab.isLinkedShared || fromTab.isLinkedView ||
            toTab.isLinkedShared || toTab.isLinkedView) {

            App.AppBase.showError("Some of the views are shared/locked and cannot be moved!");
            return;
        };

        console.log('LY_Tabs moveItems 2:', fromIndex, toIndex);

        if (fromIndex === toIndex) return;
        const newTabs: typeof props.tabs = LIB.ObjectHelper.clone(props.tabs);
        const [ movedTabs ] = newTabs.splice(fromIndex, 1);
        newTabs.splice(toIndex, 0, movedTabs);
        props.onOrderChanged(newTabs);
 
    };

    function handleDragStart(e: DragEndEvent) {

        setIsDragging(true);

        console.log('LY_Tabs handleDragStart isDragging', isDragging);
 
 
    }

    function handleDragEnd(e: DragEndEvent) {

        console.log('LY_Tabs handleDragEnd e', e);

        const { active, over } = e;
        if (active.id !== over?.id) {
            const oldIndex = props.tabs.findIndex(tab => tab.listViewId === active.id);
            const newIndex = props.tabs.findIndex(tab => tab.listViewId === over?.id);
            moveItems(oldIndex, newIndex);
        }

        setIsDragging(false);

    }

    function getTabUrl(tabId: string) {

 

        var url = `/${props.workspaceId}/list/${tabId}`;

        if (props.linkedWorkspaceId)
            url += `/${props.linkedWorkspaceId}`;

        return url;
    }


    function onTabItemClick(tabId: string, e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();

        console.log('LY_Tabs onTabItemClick isDragging', isDragging);
 

        if (e.ctrlKey) {
            e.stopPropagation();

            window.open(getTabUrl(tabId), '_blank');
            return;
        }


    }
 
    const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
        console.log('LY_Tabs handleMouseDown');

       // setMouseDown(true);
  
        e.currentTarget.removeAttribute('href');

    };

    const handleMouseUp = (e: React.MouseEvent<HTMLElement>) => {

        console.log('LY_Tabs handleMouseUp');

  
       // setMouseDown(false);
        e.currentTarget.removeAttribute('href');
 
    };

    function handleContextMenu(tabId: string, e: React.MouseEvent<HTMLElement>) {
       // e.preventDefault(); // Prevent the default context menu
        let href = getTabUrl(tabId);

        if(href)
             e.currentTarget.setAttribute('href', href);
    };


 

    function tabList() {


        //  console.log('LY_Tabs tabList', props.tabs);


        const lockIconStyle = { width: rem(14), height: rem(14) };



        return (
            <div key={'TabsContainerKey' + props.name}>
                <Tabs.List
                    key={'TabsContainerListKey' + props.name}
                // defaultValue={selectedItemId}

                >

                    <SortableContext
                        items={props.tabs.map(x => (x[ props.idProperty ] as UniqueIdentifier))}
                        strategy={horizontalListSortingStrategy}


                    >
                        {props.tabs.map((tab, index) => {

                            var iconName = tab.iconName || 'table';

                            var tabId = tab[ props.idProperty ]?.toString() || '-1';

                            return <SortableItem
                                key={'SortableItemKey' + tab.sortOrder + tabId}
                                id={tab.listViewId!}
                            >
                                <Tabs.Tab

                                    // classNames={{ tabSection: "LY_ListPageViewTabSection" }}
                                    className='LY_ListPageViewTab'

                                    key={'tabKey' + tabId}
                                    /*   onChange={(e) => {
  
                                          console.log('LY_Tabs onChange', e, tab);
  
                                        //  console.log('tab.listViewId', e, tab.listViewId)
  
                                          if (props.onTabChange)
                                               props.onTabChange(tab)
                                      }} */


                                    value={tabId}
                                    classNames={{ tab: 'LY_TabSection', tabSection: "LY_ListPageViewTabSection" }}
                                    leftSection={<div className="LY_TabSection_Icon_wrapper">
                                        {/*        {props.isDragable && <Icons.IconGripVertical className='LY_TabSection_GripIcon' size={16} />} */}
                                        {props.icons.find(i => i?.type === iconName)?.icon}
                                    </div>}
                                    rightSection={props.tabUpdateContextMenu(tab)}
                                >
                                    {/*    {tab.name} */}

                                    <a
                                        id={'tab_a_' + tabId}
                                      //  ref={(el) => (linkRefs.current[ index ] = el)}
                                        className='LY_ListPageViewTabSectionItem'
                                       // href={getTabUrl(tabId)}
                                        onMouseDown={handleMouseDown}
                                         onMouseUp={handleMouseUp}
                                        draggable={true}
                                  
                                        onContextMenu={(e) => handleContextMenu(tabId, e)}

                                        onClick={(e) => onTabItemClick(tabId, e)}
                                        key={'div' + props?.name + tab.listViewId}
                                        style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                                        {tab?.name}
                                        {tab?.isLocked &&
                                            <Icons.IconLock style={lockIconStyle} />
                                        }

                                    </a>

                                </Tabs.Tab>
                            </SortableItem>
                        })}
                    </SortableContext>
                    <div className='LY_Flex_Column' style={{ justifyContent: 'center', marginLeft: 10 }}>
                        {props.newTabContextMenu}
                    </div>
                </Tabs.List>


                {props.contentPanels}
            </div>
        );
    }
    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10,
            },
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function render() {


        var selectedItem = props.selectedItem;
        if (!selectedItem && props.tabs.length > 0)
            selectedItem = props.tabs[ 0 ];

        var selectedItemId = '-1';

        if (selectedItem)
            selectedItemId = selectedItem[ props.idProperty ]?.toString();

        // console.log('LY_Tabs selectedItemId', selectedItemId);
        //  console.log('LY_Tabs props.tabs', props.tabs);


        return (
            <DndContext onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                sensors={sensors}>
                <Tabs
                    //defaultValue={selectedItemId}  
                    value={selectedItemId ? selectedItemId : undefined}
                    onChange={(value: string | null) => {

                        if (props.onTabChange) {
                            var item = props.tabs.find(i => i[ props.idProperty ]?.toString() === value);
                            props.onTabChange(item)
                        }

                    }}
                >
                    {tabList()}
                </Tabs>
            </DndContext>
        );
    }

    return render();
});




interface SortableItemProps {
    id: number;
    children: React.ReactNode;
}

const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            {children}
        </div>
    );
};

