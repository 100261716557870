
import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
import * as MT from '@mantine/core';
import * as Icons from '@tabler/icons-react';

import { Button, Menu, Modal, TextInput } from "@mantine/core";
import { useState } from "react";

import "./LY_ListFilterModule_Save.css";

import { useDisclosure } from "@mantine/hooks";

 
export const LY_FiltersModule_Save: React.FC<LY.LY_ListFilterModuleProps>= (props) => {


  const [opened, { open, close }] = useDisclosure(false);
  const [value, setValue] = useState("")
 
  const context = LY.useListFilterModuleContext(); 
  const { columns, view } = context.state;
  var state = context.state;

  const currentUser = App.AppBase.currentUser;

  
  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);
    var sm = state.newViewSm;

     //@ts-ignore
    sm[key] = value;

    validate(key);
 
    context.forceUpdate();

  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = state.newViewSm;

    state.errors.clear();
 
    if (vAll || key == 'name') {
      if (LIB.Common.isNullOrEmpty(item.name))
        state.errors.set('name', `View Name is required!`);
      else if (item.name!.length > 50)
        state.errors.set('name', `View Name must be less than 50 characters!`);
    } 

    var isValid = (state.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }

  function onSaveAsNewView(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
 
    
    var isValid = validate('all');
    if (!isValid) {
      console.log('onSaveAsNewView onSubmit isValid:', isValid);
      context.forceUpdate();
      return false;
    }

    context.onSaveAsNewViewSuccess(onClose)
    
  }

  function onClose(){

    state.newViewSm = new Api.ListViewSm();
    close();
    state.isActionInProgress = false;
    state.errors.clear();
    context.forceUpdate();
  }

  function getSaveAsNewViewForm() {
 
    //var globalError = vm.errors.get('all');

    var sm = state.newViewSm;
    sm.listViewTypeId = view.listViewTypeId;
    sm.kanbanTypeStatusColumnId = view.kanbanTypeStatusColumnId;
    sm.calendarTypeDateColumnId = view.calendarTypeDateColumnId;

    var form = (
      <form autoComplete="off"
         onSubmit={onSaveAsNewView}
        className='NewWorkspaceScreenForm'
        //ref={focusTrapRef}
        >

{/*         {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>} */}

      
          <LY.LY_Input
            name="name"
            value={sm?.name}
            type='text'
            label="Name"
            placeholder="Name"
            labelPlacement='top'
            withStar={true}
            error={state.errors.get('name')}
            //errorPlacement='inside'

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}  

          />
 
        <MT.Group mt="md" justify='right'>
          <MT.Button variant='default' onClick={onClose} >Cancel</MT.Button>
          <MT.Button  type='submit'

            loading={state.isActionInProgress} loaderProps={{ type: 'dots' }}
 
          >
            Save
          </MT.Button>
        </MT.Group>

      </form>
    );

    return form;

  }


  function getSaveAsNewModal(){

    return       <LY.LY_Modal
    id='LYFiltersModuleSaveAsNewModal'
    classNames={{
      header: "LY_FiltersModule_Save_Modal_header",
      body: "LY_FiltersModule_Save_Modal_body",
    }}
    opened={opened}
    onClose={close}
    centered
    title="Save As New View"

    overlayProps={{
      backgroundOpacity: 0.1,
      blur: 0,
    }}
    transitionProps={{ duration: 0, timingFunction: 'linear' }}
    className={'LY_Global_Modals'}

   // size={'lg'}
    closeOnClickOutside={false}
  >

{getSaveAsNewViewForm()}

  </LY.LY_Modal>;

  }


  function render(){

    return <div className='LY_Flex_Row' style={{gap:10}}>


             <MT.ActionIcon
               variant="default"
                onClick={() => {
                  if(props.onApply)
                      props.onApply(state.view);
                }}
              /*   size={'lg'} */
                className='LY_FiltersModule_Save_RefreshBtn'
                size={36}
              >
                <Icons.IconReload color="#757B87" size={22} title="Refresh" />
              </MT.ActionIcon>


       
        {getSaveAsNewModal()}
   
        <Menu position="bottom-start"  
        offset={2}
        withinPortal={false}
        withArrow
        width={150}
        >
          <Menu.Target>
            <Button
              variant="outline"
              rightSection={
                <Icons.IconChevronDown width={15} height={16} stroke={1.5} />
              }
              className="LY_FiltersModule_Save_button"
            >
              Save
            </Button>
          </Menu.Target>
  
          <Menu.Dropdown 
          >
            <Menu.Item
              value={"saveView"}
              //className="LY_FiltersModule_Save_optionItem"
              onClick={() => context.updateView()}
              disabled={view.isLinkedShared && view.companyId !== currentUser.companyId}
            >
              Save View
            </Menu.Item>
            <Menu.Item
              onClick={open}
              value={"saveasNew"}
            >
              Save as New
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        </div>;


  }

  return render();
  
};
