import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_AddColumnModule_NewColumn.css";

import { useFocusTrap } from '@mantine/hooks';

import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import { ListColumnRelationshipSettings } from '_LY_Components/List/Columns/_shared/ListColumnRelationshipSettings';
import { UpdateColumnAdvancedTab_Calculations } from '_LY_Components/List/Columns/UpdateColumnScreen/UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_Calculations';
import { LY_AddColumnModule_ExistingRelColumn } from '../LY_AddColumnModule_ExistingRelColumn/LY_AddColumnModule_ExistingRelColumn';

export const LY_AddColumnModule_NewColumn: React.FC<LY.LY_AddColumnModuleProps> = (props) => {

  const context = LY.useAddColumnModuleContext();
  const state = context.state;
  const services = context.services;
  const manager = context.manager;
  const forceUpdate = context.forceUpdate;
  const newColumn = state.newColumn;

  const { isMobile } = useIsMobile(768);


  // var item = state.newViewSm;

  var errors = state.errors;

  const focusTrapRef = useFocusTrap();

  const relationshipSettingsRef = React.useRef<any>();

  const { view, list, columnTypes } = props.state;
  const columns = view?.columns || [];

  //var vm = props.state.vm;
  const propsState = props.state;


 

  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    console.log('LY_AddColumnModule_NewColumn onInputChange key:', key);
    // console.log('LY_AddColumnModule_NewColumn onInputChange newWorkspace:', newWorkspace);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    console.log('LY_AddColumnModule_NewColumn onValueChange key:', key);
    console.log('LY_AddColumnModule_NewColumn onValueChange value:', value);

    if (key == 'displayName' || key == 'name') {
      newColumn.name = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }
    if (key == 'name')
      value = newColumn.name;

    //@ts-ignore
    newColumn[ key ] = value;

    validate(key);

    console.log('LY_AddColumnModule_NewColumn onValueChange newColumn:', newColumn);



    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange newWorkspace', newWorkspace);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = newColumn;

    if (item.isRelationship) {
      var isRelValid = relationshipSettingsRef?.current?.validateAll();
      if (!isRelValid) {
        console.log('LY_AddColumnModule_NewColumn isRelationship isValid:', isRelValid);
        return false;
      }

    }


    errors.clear();

    if (vAll || key == 'name') {
      if (LIB.Common.isNullOrEmpty(item.name))
        errors.set('name', `Internal Name is required`);
      else if (item.name.length > 50)
        errors.set('name', `Internal Name must be less than 50 characters`);
    }
    if (vAll || key == 'displayName') {
      if (LIB.Common.isNullOrEmpty(item.displayName))
        errors.set('displayName', `Name is required!`);
      else if (item.displayName.length > 50)
        errors.set('displayName', `Name must be less than 50 characters`);
    }

    if ((vAll || key == 'listColumnTypeId') && LIB.Common.isNullOrEmpty(item.listColumnTypeId)) {
      errors.set('listColumnTypeId', `Column Type is required`);
    }
    else if ((vAll || key == 'listColumnTypeId') && (isNaN(item.listColumnTypeId) || item.listColumnTypeId < 1)) {
      errors.set('listColumnTypeId', `Column Type is required`);
    }

    if ((vAll || key == 'defaultValue') && LIB.Common.isNullOrEmpty(item.defaultValue) && item.isNull === false) {
      errors.set('defaultValue', `Default Value is required`);
    }


    /*     if ((vAll || key == 'dropdownValueColumnTypeId') && LIB.Common.isNullOrEmpty(item.dropdownValueColumnTypeId) &&
        (item.listColumnTypeId == App.ListColumnTypeEnum.Dropdown || item.listColumnTypeId == App.ListColumnTypeEnum.Status)
        ) {
          errors.set('dropdownValueColumnTypeId', `Value Type is required`);
        } */


    var isCalculatedType = LY.ListColumnManager.isCalculatedColumn(item, true)

    if (vAll && isCalculatedType &&
      (LIB.Common.isNullOrEmpty(item.clientCalculation) &&
        LIB.Common.isNullOrEmpty(item.backendCalculation))
    ) {
      var calcMsg = `Either Client Calculation or Backend Calculation is Required`;
      errors.set('clientCalculation', calcMsg);
      errors.set('backendCalculation', calcMsg);
    }

    if( item.listColumnTypeId == App.ListColumnTypeEnum.ExistingRelationshipColumn){

      if ((vAll || key == 'fkExistingRelationshipColumnId') && !item.fkExistingRelationshipColumnId) {
        errors.set('fkExistingRelationshipColumnId', `Existing Relationship is required`);
      }
      if ((vAll || key == 'fkExistingRelationshipJoinColumnId') && !item.fkExistingRelationshipJoinColumnId) {
        errors.set('fkExistingRelationshipJoinColumnId', `Add/Join Column is required`);
      }
  
    }

 

    var isValid = (errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne errors:', errors);
    //console.log('validateOne col.name error:', errors[col.name]);

    return isValid;

  }





  function onAddClick(e: React.MouseEvent) {
    e.preventDefault();

    console.log('LY_AddColumnModule_NewColumn onSubmit newColumn:', newColumn);
    //console.log('LY_AddColumnModule_NewColumn onSubmit configId:', data?.configId);
    //console.log('LY_AddColumnModule_NewColumn onSubmit  newWorkspace configId:', newWorkspace?.configId);

    var isValid = validate('all');
    if (!isValid) {
      console.log('LY_AddColumnModule_NewColumn onSubmit isValid:', isValid);
      forceUpdate();
      return false;
    }
    var sm = newColumn;

    //place it at the end for now
    sm.columnOrder = (columns.length + 1);// * 10;
    sm.workspaceId = view?.workspaceId;
    sm.listViewId = propsState.view?.listViewId;

    console.log('LY_AddColumnModule_NewColumn sm send:', sm);
    //   return;

    services.addColumn(sm);

  }

  function getForm(column: Api.ListColumn) {


    var vm = props.state.vm;
    var item = newColumn;
    var columnTypes = props.state.columnTypes;
    let columns = props.state?.view?.columns?.filter(x => !x.isSystemHidden) || [];


    
  // when Type changes, let's change what's selected 
  var listColumnTypeId = newColumn.listColumnTypeId;

  //console.log('LY_AddColumnModule_NewColumn watch listColumnTypeId:', listColumnTypeId);
  //console.log('LY_AddColumnModule_NewColumn newColumn:', newColumn);
  //console.log('LY_AddColumnModule_NewColumn columnTypes:', columnTypes);
  //console.log('LY_AddColumnModule_NewColumn selectedColumnType:', selectedColumnType);

  var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == newColumn?.listColumnTypeId);

  if (listColumnTypeId) {
    defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);

  }


    var globalError = errors.get('all');
    var isCalculatedType = LY.ListColumnManager.isCalculatedColumn(item, true)

    //console.log('LY_AddColumnModule_NewColumn getForm isRelationship:', item.isRelationship);
    var shownColumnTypes: Api.ListColumnType[] = [];

    shownColumnTypes = columnTypes?.filter(x => !x.isHiddenOnList) || [];

    // var dropdownValueTypes = state.lookupTypes?.filter(x => x.groupName == 'ListColumnDropDownValueType') || [];
    var dropdownValueTypes = columnTypes?.filter(x => x.isDropdownValueColumnType && !x.name.includes('Array')) || [];

    var valueTypePlaceholder = 'Defaults to Guid';

    if (item.listColumnTypeId == App.ListColumnTypeEnum.MultiSelectDropdown){
      dropdownValueTypes = columnTypes?.filter(x => x.isDropdownValueColumnType && x.name.includes('Array')) || [];
      valueTypePlaceholder = 'Defaults to Guid Array';
    }

    /*     console.log('LY_AddColumnModule_NewColumn getForm lookupTypes:', state.lookupTypes);
        console.log('LY_AddColumnModule_NewColumn getForm lookupTypes dropdownValueTypes:', dropdownValueTypes); */

    //dropdownValueTypes?.find(x => x.listColumnTypeId === item.dropdownValueColumnTypeId)


    var form = (
      <form

        className='LY_AddColumnModule_NewColumnForm'
        ref={focusTrapRef}>

        {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}


        <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
          <LY.LY_Input
            name="displayName"
            value={item.displayName}
            type='text'
            label="Display Name"
            placeholder="Display Name"
            labelPlacement='top'
            withStar={true}
            error={errors.get('displayName')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            containerStyle={{ flex: 1 }}

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

          {item.listColumnTypeId != App.ListColumnTypeEnum.ExistingRelationshipColumn &&
            <LY.LY_Input
              name="defaultValue"
              value={item.defaultValue}
              type='text'
              label="Default Value"
              placeholder={item.isUnique ? 'Disabled when Unique Values Only ' : 'Default Value'}
              labelPlacement='top'
              withStar={item.isNull == false}
              error={errors.get('defaultValue')}


              onChange={(m, e) => {
                onInputChange(e!);
              }}
              disabled={item.isUnique}
              hidden={LY.ListColumnManager.isCalculatedColumn(item)}
            />
          }

        </div>

        {item.listColumnTypeId != App.ListColumnTypeEnum.ExistingRelationshipColumn &&
          <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
            <LY.LY_Switch
              name="isRequired"
              value={item.isRequired}
              label="Required"
              labelPlacement='left'
              // withStar={true}
              error={errors.get('isRequired')}


              onChange={(m, e) => {
                onInputChange(e!);
              }}
              hidden={isCalculatedType}
            />
            <LY.LY_Switch
              name="isRelationship"
              value={item.isRelationship}
              label="Relationship"
              labelPlacement='left'
              // withStar={true}
              error={errors.get('isRelationship')}


              onChange={(m, e) => {
                onInputChange(e!);
              }}
              hidden={isCalculatedType}
            />

            <LY.LY_Switch
              name="isNull"
              checked={item.isNull || item.isNull === undefined}
              label="Is Null"
              labelPlacement='left'
              // withStar={true}
              error={errors.get('isNull')}

              //helpText="Technical setting, leave the default value."
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}

              disabled={item.isRequired && item.isNull}
              hidden={LY.ListColumnManager.isCalculatedColumn(item)}
            />
          </div>
        }


        <div className='LY_AddColumn_ColumnTypeRow'>
          <LY.LY_DropDown
            name="listColumnTypeId"
            label="Column Type"
            withStar={true}
            value={item?.listColumnTypeId}
            displayProperty="name"
            valueProperty="listColumnTypeId"
            data={shownColumnTypes}
            inputContainerStyle={{ flex: 1 }}
            style={{ flex: 1 }}
            selectedItem={shownColumnTypes?.find((col: any) => col.listColumnTypeId === item.listColumnTypeId)}
            isAutoCompleteStyle={true}
            error={errors.get('listColumnTypeId')}

            onChange={(value, option: any) => {

              console.log('LY_AddColumnModule_NewColumn LY_DropDown onLocalValueChange value:', value);
              console.log('LY_AddColumnModule_NewColumn LY_DropDown onLocalValueChange option:', option);

              onValueChange('listColumnTypeId', option?.value)
              //forceUpdate();
            }}

          // className="LY_FiltersModule_Sort_Select_Columns"
          //inputContainerClassName="LY_FiltersModule_Sort_Select_Columns"
          />

          {(item.listColumnTypeId == App.ListColumnTypeEnum.Dropdown ||
            item.listColumnTypeId == App.ListColumnTypeEnum.MultiSelectDropdown ||
            item.listColumnTypeId == App.ListColumnTypeEnum.Status)
            &&
            <LY.LY_DropDown
              name="dropdownValueColumnTypeId"
              label="Value Type"
              //withStar={true}
              placeholder={valueTypePlaceholder}
              value={item.dropdownValueColumnTypeId}
              displayProperty="name"
              valueProperty="listColumnTypeId"
              data={dropdownValueTypes}

              selectedItem={dropdownValueTypes?.find(x => x.listColumnTypeId === item.dropdownValueColumnTypeId)}
              isAutoCompleteStyle={true}
              error={errors.get('dropdownValueColumnTypeId')}

              onChange={(value, option: any) => {

                console.log('LY_AddColumnModule_NewColumn LY_DropDown dropdownValueColumnTypeId value:', value);
                console.log('LY_AddColumnModule_NewColumn LY_DropDown dropdownValueColumnTypeId option:', option);

                onValueChange('dropdownValueColumnTypeId', option?.value)
              }}

            />
          }



        </div>

        {!isCalculatedType && defaultTypeValue &&
          <MT.Alert variant="light" color="cyan" title={`Selected Type: ${defaultTypeValue?.name || ''}`}
            style={{ marginTop: 0, minHeight: 50, padding: 8 }} >
            {defaultTypeValue?.description}
          </MT.Alert>
        }

        {isCalculatedType &&
          <UpdateColumnAdvancedTab_Calculations
            column={item}
            state={props.state} />
        }
        {!isCalculatedType && item.isRelationship &&

          <div className='LY_AddColumn_RelationshipSettings'>
            <ListColumnRelationshipSettings
              ref={relationshipSettingsRef}
              state={props.state}
              column={item}
              isAddColumnView={true}
              onSelectionsDone={(svm: LY.ListColumnRelationshipSettingsVm) => {
                console.log('ListColumnRelationshipSettings onSelectionsDone svm: ', svm);


              }}
            />
          </div>
        }


  {item.listColumnTypeId == App.ListColumnTypeEnum.ExistingRelationshipColumn &&
    <LY_AddColumnModule_ExistingRelColumn {...props} onValueChange={onValueChange} />
  }


        <LY.LY_Input
          name="name"
          value={item.name}
          type='text'
          label="Internal Name"
          placeholder="Internal Name"
          labelPlacement='top'
          withStar={true}
          error={errors.get('name')}

          helpText="This will be used in APIs or Integrations"
          // helpTextPlacement='bottom'

          onChange={(m, e) => {
            onInputChange(e!);
          }}
        />


        <LY.LY_Select
          name="afterColumnOrder"
          value={item.afterColumnOrder}
          label="Add After Column"
          placeholder="Add at the end"
          // selectOptionValue={App.ListColumnTypeEnum.Other}

          data={columns}
          //value={defaultTypeValue?.listColumnTypeId}
          labelProperty="displayName"
          valueProperty="columnOrder"
          // withStar={true}
          error={errors.get('afterColumnOrder')}

          // helpText="This name will be used in APIs or Integrations"
          // helpTextPlacement='bottom'

          onChange={(m, e) => {
            onInputChange(e!);
          }}
        />



      </form>
    );

    return form;

  }


  function getButtons() {
    return <MT.Group mt="xl" justify='right'>
      <MT.Button variant='default' onClick={() => onClose()} >Cancel</MT.Button>
      <MT.Button
        loading={state.isActionInProgress} loaderProps={{ type: 'dots' }}
        onClick={onAddClick}

      >
        Add
      </MT.Button>
    </MT.Group>
  }


 /*  function getExistingRelationshipColumnForm() {
    return <LY_AddColumnModule_ExistingRelColumn {...props} />

  } */


  function onClose() {
    /*     var vm = props.state.vm;
    
        vm.isLY_AddColumnModule_NewColumnOpen = open;
        errors.clear();
    
        //setOpen(open);
        forceUpdate(); */

    state.isOpen = false;
    forceUpdate();

    if (props.onClose)
      props.onClose();
  }


  function render() {




    // console.log('LY_AddColumnModule_NewColumn render vm.isLY_AddColumnModule_NewColumnOpen', vm.isLY_AddColumnModule_NewColumnOpen);

    /*     if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || column == undefined || !state.isLY_AddColumnModule_NewColumnOpen)
          return null;
     */

    if (!newColumn || !state.isOpen)
      return null;

    var column = newColumn;

    return (
      <>
        <LY.LY_Modal
          id='LY_AddColumnModule_NewColumnModal'
          opened={state.isOpen}
          onClose={() => onClose()}
          title="Add Column"
          //scrollAreaComponent={ScrollArea.Autosize}
          centered
          overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0,
          }}
          transitionProps={{ duration: 0, timingFunction: 'linear' }}
          className={'LY_Global_Modals'}
          size={'xl'}
          closeOnClickOutside={false}
          zIndex={7001}

          footer={getButtons()}
        >

          {getForm(column)}

        </LY.LY_Modal>

      </>
    );
  }

  return render();

};
