import './LY_KanbanBoard_Column.css';

import * as Api from "app-api";
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from '@mantine/core'

import { LY_KanbanSection, useKanbanContext } from '../../Context/LY_KanbanModuleContext';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { LY_KanbanBoard_ItemList } from '../LY_KanbanBoard_ItemList/LY_KanbanBoard_ItemList';
import { Menu } from '@mantine/core';
import React from 'react';

interface ILY_KanbanBoard_Column {
    title: string;
    section: LY_KanbanSection;
    index: number;
    isScrollable?: boolean;
    isCombineEnabled?: boolean;
    useClone?: boolean;
}

export const LY_KanbanBoard_Column: React.FC<ILY_KanbanBoard_Column & LY.LY_KanbanProps> = ({
    title,
    section,
    index,
    isScrollable,
    isCombineEnabled,
    useClone,
    ...props

}) => {



    const context = useKanbanContext();
    const state = context.state;





    function deleteConfirmationModalRenderer() {
        return (
            <LY.ConfirmModal
                visible={state.isDeleteModalOpened}
                onCancelClick={() => {
                    state.selectedItem = new Api.SystemLookupType();
                    state.isDeleteModalOpened = false;
                    context.forceUpdate();
                }}
                onConfirmClick={context.deleteColumn}
                title={<div>Confirm Delete: <b>{state.selectedItem?.name}</b></div>}
                content={
                    <div>Are you sure you want to delete the <b>{state.selectedItem?.name}</b> status?
                        <MT.Alert
                            variant="light"
                            color="red"
                            icon={<Icons.IconAlertTriangle />}
                            style={{ marginTop: 10, padding: 8 }}
                        >
                            There is NO Rollback for this action!
                        </MT.Alert>
                    </div>
                }
                confirmButtonLabel="Delete"
            />
        );
    }





    function getColumnContextMenu() {
        return <Menu shadow="md" 
            withArrow
            arrowSize={12}
            offset={-5}
        width={200}>
            <Menu.Target>


          {/*       <Icons.IconDots
                    stroke={1.5}
                /> */}

                <MT.CloseButton className='LY_DotsIcon'  
                    icon={<Icons.IconDots stroke={1.5} size={20} />}
                 />

            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={() => {
                    state.isDeleteModalOpened = true;
                    state.selectedItem = section;
                    context.forceUpdate();
                }}>
                    Delete
                </Menu.Item>
                <Menu.Item
                    onClick={() => {
                        state.isColumnModalOpened = true;
                        const col = { ...section, tasks: undefined } as Api.SystemLookupType;
                        state.selectedItem = col;
                        context.forceUpdate();
                    }}
                >
                    Change
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    }






    function newTaskRenderer() {
        return <div onClick={() => props?.onAddNewItem && props?.onAddNewItem(section as Api.SystemLookupType)} className="LY_KanbanBoard_Column_New_Task">
            <Icons.IconPlus width={18} height={18} color='#00000080' />
            <div>
                Add Task
            </div>
        </div>
    }


    function columnHeaderRenderer(provided: DraggableProvided) {
        return (
            <div className='LY_KanbanBoard_Column_Item-header_wrapper' 
            style={{ borderBottom: `1px solid ${section?.id ? section?.color + '30' : '#b4c3cd'}` }}>
                <div
                    className='LY_KanbanBoard_Column_Border'
                    style={{
                        borderColor: section?.color || '#b4c3cd'
                    }}>

               </div>

                <div className="LY_KanbanBoard_Column_Item-header" 
                style={{ backgroundColor: section?.color + "10" }} 
                {...provided.dragHandleProps}>
                    <div style={{ color: '#00000090' }}>{title} ({section?.records?.length})</div>
                    {section?.id && getColumnContextMenu()}
                </div>
            </div>
        )
    }



    function columnRenderer() {

        return (
            <Draggable draggableId={title} index={index}>
                {(provided, snapshot) => (
                    <div
                        className={`LY_KanbanBoard_Column_Item ${snapshot.isDragging ? 'dragging' : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        {columnHeaderRenderer(provided)}
                        <LY_KanbanBoard_ItemList
                            {...props}
                            listKeyId={title}
                            records={section?.records ?? []}
                            internalScroll={isScrollable}
                            isCombineEnabled={isCombineEnabled}
                            useClone={useClone}
                        />
                        {props.onAddNewItem && newTaskRenderer()}
                    
                    </div>
                )}
            </Draggable>
        );
    }
    function render() {
        return <>
            {columnRenderer()}
            {deleteConfirmationModalRenderer()}
        </>
    }
    return render()
};
