import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class ListColumnService extends App.BaseAppService {



    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
    }

    public getColumnInfoWithDetails(listColumnId:string, onSuccess: Function, onError: Function | undefined = undefined) {

        if(!listColumnId)
            return;

        console.log("ListColumnService.getColumnInfoWithDetails listColumnId: ", listColumnId); 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListColumn/getColumnInfoWithDetails?listColumnId=${listColumnId}`;

        this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess.bind(this),
            onError: onError?.bind(this),
            method: 'GET'
        }); 

    }

    public updateColumn(data: Api.ListColumn, onSuccess: Function, onError: Function) {

        console.log("ListColumnService.updateSettings data: ", data);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListColumn/updateColumn`;

        this.executeApi({
            apiUrl: apiUrl,
            data: data,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {

                if (onError)
                    onError(error);
                else {
                    console.error("ListColumnService.updateColumn onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });

    }

    public updateColumnQuickUpdates(data: Api.ListColumn, onSuccess: Function, onError: Function) {

        console.log("ListColumnService.updateColumnQuickUpdates data: ", data);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListColumn/updateColumnQuickUpdates`;

        this.executeApi({
            apiUrl: apiUrl,
            data: data,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {

                if (onError)
                    onError(error);
                else {
                    console.error("ListColumnService.updateColumnQuickUpdates onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });

    }

    public addColumn(data: Api.ListColumn, onSuccess: Function, onError: Function) {

        console.log("ListColumnService.addColumn data: ", data);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListColumn/addColumn`;

        this.executeApi({
            apiUrl: apiUrl,
            data: data,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {

                if (onError)
                    onError(error);
                else {
                    console.error("ListColumnService.addColumn onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });

    }

    public deleteColumn(data: Api.ListColumn, onSuccess: Function, onError: Function) {

        console.log("ListColumnService.deleteColumn data: ", data);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListColumn/deleteColumn`;

        this.executeApi({
            apiUrl: apiUrl,
            data: data,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {

                if (onError)
                    onError(error);
                else {
                    console.error("ListColumnService.deleteColumn onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });

    }


    
    public getRelationshipInfo(sm:Api.ListColumnGetRelationshipInfoSm, onSuccess: Function, onError: Function | undefined = undefined) {

        if(!sm)
            return;

        const apiUrl = `${App.AppBase.appApiBaseUrl}/ListColumn/getRelationshipInfo`;

        this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess.bind(this),
            onError: onError?.bind(this),
            method: 'POST'
        }); 

    }

   
}
