import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

//import "./LY_ListDataNewButtonClickRenderer.css";


interface LY_ListDataNewButtonClickRendererProps {
  listState?: App.ListState;
  isOpen: boolean;
  onClose?: () => void;
}

export const LY_ListDataNewButtonClickRenderer: React.FC<LY_ListDataNewButtonClickRendererProps> = (props) => {


  function render(){

    var listState = props.listState;
    var listId = listState?.listId;

    console.log("LY_ListDataNewButtonClickRenderer listId:",listId);
    console.log("LY_ListDataNewButtonClickRenderer listState:",listState);

    if(!listId || !listState?.workspaceId)
     return null;

     //LY.LYTemplates.

     if(listState.listId!="8420e335-407d-4501-93f2-9f4b4c80199c")
        return null;

    return <LY.LY_EmailAccountSetupModule 
    
          workspaceId={listState.workspaceId}
          isOpen={props.isOpen}
          onClose={props.onClose}
       />




  }

  return render();
 
};
