import * as React from 'react';
import * as LIB from '_LIB';
import * as App from 'AppReferences';
import * as Api from 'app-api';
import { Alert } from '@mantine/core';
import * as Icons from '@tabler/icons-react';
import { Notifications } from '@mantine/notifications';
import * as LY from '_LY_Components';

import './MainContainer.css';


interface MainContainerProps {
    pageTitle?: any;
    loading?: boolean;
    inProgress?: boolean;
    showContentWhenInProgress?: boolean;
    hideContentWhenError?: boolean;
    hasDataLoaded?: boolean;
    showModalForLoading?: boolean;
    children?: React.ReactNode
    showError?: boolean;
    errorMessage?: string;
    alignError?: string;
    errorTitle?: string;
    className?: string;
}



export const MainContainer: React.FC<MainContainerProps> = (props) => {


    const currentUser = App.AppBase.currentUser;


    function getAlert(error: string) {

        var showError = props.showError;
        if (LIB.Common.isNullOrEmpty(showError))
            showError = true;

        if (LIB.Common.isNullOrEmpty(error) || !showError)
            return null;

        var alignError: any = 'center';
       // var alignMarginLeft: any = 0; 

        if (alignError === 'left')
            alignError = 'flex-start';

        //console.log("MainContainer.getAlert: " + error);

        const icon = <Icons.IconExclamationCircle />;

        return <div
            style={{alignItems:alignError}} //,marginLeft: alignMarginLeft
            className='LY_MainContainerAlertContainer'
        >
{/* 
            <Alert style={alertCss}
                severity="error">{error}</Alert> */}

            <Alert variant="light" color="red" 
                title={props.errorTitle} 
                icon={icon}
                className='LY_MainContainerAlert'
               // autoContrast={true}
                >
                {error}
            </Alert>

        </div >

        return
    }

    function getInProgress() {

        var inProgress = props.inProgress;
        var error = props.errorMessage;
        if (LIB.Common.isNullOrEmpty(inProgress) || !inProgress || !LIB.Common.isNullOrEmpty(error)) {
            return null;
        }

        return null;

    }


    function getErrorMessage(): any {
        // console.log("MainContainer.getErrorMessage");

        var error = props.errorMessage;

        return error;
    }

    function render() {

        // var isMobile = getst
        // console.log("MainContainer.render isMobile:", state.isMobile);

        var globalState = App.AppBase.getState().globalData;
        // var error = props.state?.apiErrorMessage;

        var error = getErrorMessage();

        var children: any = props.children;
        var workspaceVm = App.AppBase.getState().globalData.workspaceVm;
        var selectedWorkspace = workspaceVm?.selectedWorkspace;

        var loading = props.loading;
        var inProgress = props.inProgress;
        var showContentWhenInProgress = props.showContentWhenInProgress;
        var hasDataLoaded = props.hasDataLoaded;
        var hideContentWhenError = props.hideContentWhenError;

        if (hasDataLoaded === undefined)
            hasDataLoaded = true;

        if (loading === undefined)
            loading = false;

        if (!hasDataLoaded || loading) {
            //console.log("MainContainer.render Data Not Loaded yet");
            children = undefined;
        }
        if ((!LIB.Common.isNullOrEmpty(error) && hideContentWhenError)) {
            //  console.log("MainContainer.render error, and hideContentWhenError : ", error);
            children = undefined;
        }

        var loadingMessage =  "Setting up your account, please wait...";



        // console.log("MainContainer.render loading", loading);
        // console.log("MainContainer.render children", children);


        if (showContentWhenInProgress)
            children = props.children;

        var title: any = "";

        if (!LIB.Common.isNullOrEmpty(props.pageTitle))
            title = props.pageTitle;

        if (!LIB.Common.isNullOrEmpty(props.pageTitle) && typeof props.pageTitle === 'string'   )
            title = <h1 style={{ marginLeft: 0 }}>{props.pageTitle}</h1>;
        
 
        return <div className={`LY_MainContainerContent ${props.className || ''}`} >
            {title}
            <LIB.Loading 
            show={loading} 
            isModal={props.showModalForLoading} 
           //todo also check on date created:
           // message={currentUser?.company?.workspaceCount==0 ? loadingMessage: undefined}
            />
            {getAlert(error)}
            {children}
            {getInProgress()}


        </div>


    }

    return render();
}
