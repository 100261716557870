import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_ListSettingsState } from "../Context/LY_ListSettingsModuleState";

export class LY_ListSettingsServiceManager {


  public forceUpdate: () => void;
  public state: LY_ListSettingsState;
  public props: LY.LY_ListSettingsProps;
  public context: LY.LY_ListSettingsContextProps;


  public service: App.ListSettingsService;
  public sm: Api.ListViewGetDataSm;

  constructor(props: LY.LY_ListSettingsProps,
    state: LY_ListSettingsState,
    context: LY.LY_ListSettingsContextProps) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;

    this.sm = new Api.ListViewGetDataSm()

    this.service = new App.ListSettingsService(props.workspaceId)
    // this.service.linkedWorkspaceId = this.props.linkedWorkspaceId;


  }

  public onServiceError(error: any) {

    if (this?.state == undefined)
      return;

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    App.AppBase.showError(errorMessage)
    this.state.isActionInProgress = false;
    this.forceUpdate();

  }

  public getListSettings() {

    this.service.getListSettingsState(this.props?.listId!,

      //onSuccess
      (result: Api.ListSettingsState) => {
        console.log('LY_ListSettingsServiceManager createView onSuccess:', result);

        this.state.listSettingsState = result;
        if (result?.list?.listSettings) {
          this.state.listSettingsForm = result?.list?.listSettings;
        } else {
          this.state.listSettingsForm = new Api.ListSetting();
          this.state.listSettingsForm.listSettingId = LIB.StringHelper.generateUUID()
          this.state.listSettingsForm.name = this.props.listName;
          this.state.listSettingsForm.internalName = this.props.internalName;
        }

        this.state.isActionInProgress = false;

        this.forceUpdate();
      },
      //onError
      this.onServiceError.bind(this)
    );

    this.state.isActionInProgress = true;
    this.forceUpdate();

  }

  public updateListSettings() {


    if(!this.props?.listId || !this.state.listSettingsForm) 
      return;

    this.service.updateListSettings(this.props?.listId!, this.state.listSettingsForm!,

      //onSuccess
      (result: Api.List ) => {
        console.log('LY_ListSettingsServiceManager createView onSuccess:', result);

        var settings = result?.listSettings;
        this.state.listSettingsForm = settings;
        this.state.isActionInProgress = false;
        this.state.isSettingsModalOpen = false;

        App.AppBase.showSaveSuccess();
        this.props.onSettingsUpdateSuccess && this.props.onSettingsUpdateSuccess(result);


        this.forceUpdate();
      },
      //onError
      this.onServiceError.bind(this)
    );

    this.state.isActionInProgress = true;
    this.forceUpdate();

  }

  public deleteList() {

    this.service.deleteList(
      this.props.workspaceItemId!,
      this.props.workspaceId,
      (result: boolean) => {
        console.log('LY_ListSettingsServiceManager createView onSuccess:', result);


        this.state.isActionInProgress = false;
        this.state.isSettingsModalOpen = false;
        this.state.isDeleteModalOpen = false

        App.AppBase.showMessage("Successfully removed");
        this.props.onDeleteSuccess && this.props.onDeleteSuccess(this.props.listId!);


        this.forceUpdate();
      },
      //onError
      this.onServiceError.bind(this)
    );

    this.state.isActionInProgress = true;
    this.forceUpdate();


  }


}