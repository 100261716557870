import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

export class LY_AddColumnModuleState extends LIB.BaseVmModel {

  public isOpen:boolean = false;

  public existingRelColumnInfo:Api.ListColumnRelationshipInfoResult | undefined;
  public newColumn:Api.ListColumn;

  public isLoadingGetExistingRelColumnInfo:boolean = false;

  constructor() {
    super();
  }
}
