import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';
export class LY_EmailAccountSetupModuleState extends LIB.BaseVmModel {

  public view?: Api.ListView = new Api.ListView();
  public newViewSm: Api.ListViewSm = new Api.ListViewSm();

  public model: LY.DataItemModel = new LY.DataItemModel();

  public EmailProviderTypeEnum = {
    GoogleGSuite: 1,
    GoogleGmailFree: 2,
    Microsoft_Office: 3,
    Microsoft_Outlook: 4,
    iCloud: 5,
    Yahoo: 6,
    CustomOther: 99
  }

  public EmailProviderAuthTypeEnum = {
    OAuth: 1,
    Password: 2,
    Api_Key: 3
  }

  public accountOauthUrl: string = "";

  public encryptionTypes = [{ name: "SSL", id: 1 }, { name: "TLS", id: 2 }]
  public selectedImapEncryptionType = { name: "", id: 0 }
  public selectedSmtpEncryptionType = { name: "", id: 0 }

  public isPasswordAuthErrorMessageVisible: boolean = false;

  //public passwordAuthErrorMessage: string = "";
 
  public newAccountSm: Api.EmailAccount = new Api.EmailAccount();

  public selectedAccountType: Api.EmailProviderType | undefined;
  public selectedEmailProviderAuthtype?: Api.EmailProviderAuthTypeLookup;


  public workspaceId?: number | undefined;
  public linkedWorkspaceId?: number | undefined;

  public editAccountStatuses: Api.SystemLookupType[] = [];
  public editAccountSelectedStatus: Api.SystemLookupType = new Api.SystemLookupType();

  public emailAccountTypes: Api.EmailProviderType[] = [];

  public isAddAccountModalOpened: boolean = false;
  public isEditAccountModalOpened: boolean = false;

  public isEmailTypesLoadingActive: boolean = false;
  public isConnectPending: boolean = false;
  public isUserEmailInfoLoadingActive: boolean = false;


  public currentStep: number = 0;

  constructor() {
    super();
  }
}
