import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ListManager {


  constructor() {

  }
  
  public static getColumnsToShow(columns?:Api.ListColumn[]): Api.ListColumn[] {

    if (!columns || !Array.isArray(columns))
      return [];
 
     var result  =  columns?.filter(x => (x.isSystemHidden == false || x.name=='id') && 
                                    !x.isAlwaysSystemHidden);
 
      return result;
  } 

  public static cleanupSelectColumns(view?:Api.ListView): string[] | undefined {

    if(!view?.filter?.selectColumns)
      return undefined; 

    var showingCols = ListManager.getColumnsToShow(view?.columns);
    var selectColumns = view?.filter?.selectColumns?.filter(x=>showingCols?.find(y=>y.name == x));
    return selectColumns;
  }
 




}