import './LY_ListSettingsDeleteModal.css'

import * as Api from 'app-api';
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LY from "_LY_Components";
import * as LIB from '_LIB';
import * as MT from '@mantine/core';
import * as React from "react";

import { IconAlertTriangle } from '@tabler/icons-react';

export const LY_ListSettingsDeleteModal = () => {

    const context = LY.useListSettingsModuleContext();
    const { state, services } = context;

    function render() {
        return <LY.ConfirmModal
            visible={state.isDeleteModalOpen}
            disabled={state.isActionInProgress}
            onCancelClick={() => {
                state.isDeleteModalOpen = false;
                context.forceUpdate();
            }}
            onConfirmClick={() => {
                services.deleteList();
            }}
            title={<div> Confirm Delete List: <b>{services.props?.listName}</b></div>}
            content={
                state.isActionInProgress ?
                    <div className='LY_ListSettingsDeleteModal_LoaderContainer'>
                        <MT.Loader />
                    </div> :
                    <div>
                        Are you sure you want to delete the <b>{services.props?.listName}</b> List?
                        <MT.Alert
                            variant="light"
                            color="red"
                            icon={<IconAlertTriangle />}
                            style={{ marginTop: 10, padding: 8 }}
                        >
                            There is NO Rollback for this action!
                        </MT.Alert>
                    </div>
            }
            confirmButtonLabel="Delete"

        />

    }

    return render();
}
