import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { createContext, useContext, useState } from "react";

import { LY_StaticDropDownModuleManager } from '../Manager/LY_StaticDropDownModuleManager';
import { LY_StaticDropDownState } from './LY_StaticDropDownState';
import React from "react";

export interface LY_StaticDropDownContextProps {
    state: LY_StaticDropDownState;
    propsState: LY_StaticDropDownState;
    forceUpdate: () => void;
    saveChanges: () => void;
    saveColumnChanges: () => void;
    changeEditMode: (mode: boolean) => void;
    changeSelectedValue: (value?: Api.SystemLookupType) => void;
    setPopoverOpened: (opened: boolean) => void;
    deleteById: (id: string) => void;
    addItem: (item: Api.SystemLookupType) => void;
    itemChanged: (item: Api.SystemLookupType) => void;
    setInitialColumns: (column: Api.ListColumn) => void;
    moveItems: (fromIndex: number, toIndex: number) => void;
    isFullWidthStyle: (item?: Api.SystemLookupType) => boolean;
}

const useDropDownModuleState = (props: LY.LY_StaticDropDownProps): LY_StaticDropDownContextProps => {


    const initialState = new LY_StaticDropDownState();

    const [state, setState] = useState<LY_StaticDropDownState>(initialState);
    const [propsState, setPropsState] = useState<LY_StaticDropDownState>(new LY_StaticDropDownState());

    const manager = new LY_StaticDropDownModuleManager(props, state, forceUpdate);


    // console.log(state)

    React.useEffect(() => {


      if (state.isSelectStaticPopoverOpen)
            return;  

        state.model = props.model;
        state.items = props.data || [];

      // if(state.model?.column?.name=='multi_1')
      //  console.log('LY_StaticDropDownContext useEffect allowMultiSelect:', props.allowMultiSelect);
    //   console.log('LY_StaticDropDownContext useEffect props.data:', props.data );

        state.selectedItems = [];

        if (props.selectedValues && props.selectedValues.length > 0)
            state.selectedItems = props.selectedValues;

        else if (props.selectedValue) {
            state.selectedItems = [props.selectedValue];
        }

        state.previewItem = props?.data?.[0];
       
       // state.isShowingAdvancedSettings = (props.valueProperty === 'value' || props.valueProperty === 'value_id');
        
        state.allowMultipleSelection = props.allowMultiSelect;
        state.disableEditOptions = props.disableEditOptions;
        state.readOnly = props.readOnly;
        state.isSelectStaticPopoverOpen = false;

        state.valueProperty =  props.valueProperty as keyof Api.SystemLookupType;
        
/*         console.log('LY_StaticDropDownContext useEffect selectedItems:',state.selectedItems);
        console.log('LY_StaticDropDownContext useEffect selectedValues:',props.selectedValues);
        console.log('LY_StaticDropDownContext useEffect selectedValue:',props.selectedValue); */

        
        forceUpdate();

        var stateCopy = clone(state);
        setPropsState(stateCopy);


    }, [props.data, props.model, props.model?.rowId]);



    function clone(stateIn: LY_StaticDropDownState): LY_StaticDropDownState {

        var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_StaticDropDownState;
        stateCopy.errors = new Map<string, any>();
        return stateCopy;
    }

    function forceUpdate() {
       // console.log('LY_StaticDropDownContext forceUpdate');
        state.forceUpdateCounter++;
        setState({ ...state })
    }

    function saveColumnChanges() {
        manager.onColumnChange();
    }

    function saveChanges() {
        state.items.forEach((item, index) => {
            item.sort_order = index + 1;
            itemChanged(item);
        })
        if (!state.chagnedItems || state.chagnedItems.length == 0) {
            state.isEditMode = false;
            forceUpdate();
            return;
        }


        manager.saveChanges(state.chagnedItems);


    }

    function itemChanged(item: Api.SystemLookupType) {


        //check if it's already in the list
        var found = state.chagnedItems.find(i => i.id === item.id);
        if (found) {
            forceUpdate();
            return;
        }

        // check if something changed
        var original = propsState.items.find(i => i.id === item.id);
        if (!original) {
            forceUpdate();
            return;
        }

       // console.log('LY_StaticDropDownContext itemChanged:', item, original);

        if (item.color !== original.color ||
            item.text_color !== original.text_color ||
            item.name !== original.name ||
            item.value !== original.value ||
            item.help_text !== original.help_text ||
            item.value_id !== original.value_id
            ) {

            //    console.log('LY_StaticDropDownContext itemChanged YES');

            item.isChanged = true;
            state.chagnedItems.push(item);
            state.items = [...state.items];

            forceUpdate();
        }

    }



    function deleteById(id: string) {

        var found = state.items.find(i => i.id === id);
        if (!found) {
            console.log('LY_StaticDropDownContext deleteById not found:', id);
            return;
        }

        //let's remove it from the list
        var index = state.items.indexOf(found);
        state.items.splice(index, 1);
        state.items = [...state.items];

        if (found.isNew) {
            //let's check and remove from  state.chagnedItems
            var foundChanged = state.chagnedItems.find(i => i.id === id);
            if (foundChanged) {
                var index = state.chagnedItems.indexOf(foundChanged);
                state.chagnedItems.splice(index, 1);
            }
            return;
        }

        found._ly_date_deleted = new Date();

        //keep here to save when Save
        state.chagnedItems.push(found);

        forceUpdate();


    }


    function setInitialColumns(column: Api.ListColumn) {

        if (!column || !column.listColumnId)
            return;

        if(state.model)
        state.model.column = column;

        forceUpdate();
    }



    function setPopoverOpened(opened: boolean) {
        console.log('LY_StaticDropDownContext setPopoverOpened:', opened);
        state.isSelectStaticPopoverOpen = opened;
        forceUpdate();

    }


    function changeSelectedValue(value?: Api.SystemLookupType) {
        if (state.readOnly) 
                return false;

         // console.log('LY_StaticDropDownContext changeSelectedValue:', value);
     

        if (!value) {
            state.selectedItems = [];
            state.isSelectStaticPopoverOpen = false;
            forceUpdate();
            if (props.onChange)
                props.onChange(state.model,value, state.selectedItems);

            return;
        }

        //var selectedValId = value.id;

       
        var selectedValId = value[state.valueProperty];
 

        if (!state.selectedItems)
            state.selectedItems = [];

        if (props.allowMultiSelect) {


            var foundSelected = state.selectedItems.find(x => x[state.valueProperty] === selectedValId);

         //   console.log('LY_StaticDropDownContext changeSelectedValue allowMultiSelect, foundSelected',foundSelected);
         //   console.log('LY_StaticDropDownContext changeSelectedValue allowMultiSelect, selectedValId',selectedValId);

            if (foundSelected) {
                state.selectedItems = state?.selectedItems?.filter(x => x[state.valueProperty] !== selectedValId);

            }
            else {
                state.selectedItems.push(value);
            }
            var valueIds = state.selectedItems?.map(x => x[state.valueProperty]);

            if(state.model)
            state.model.value = valueIds;

            // state.isSelectStaticPopoverOpen = true;

        } 
        else if (value) {

           console.log('LY_StaticDropDownContext changeSelectedValue single: value', value);

            if(state.model)
                state.model.value = selectedValId;

            state.selectedItems = [value];

            console.log('LY_StaticDropDownContext changeSelectedValue state.selectedItems',  state.selectedItems);

        }

       // console.log('LY_StaticDropDownContext changeSelectedValue selectedItems',state.selectedItems);

        state.isSelectStaticPopoverOpen = false;

        forceUpdate();

        if (props.onChange)
            props.onChange(state.model,value, state.selectedItems);

    }


    function changeEditMode(mode: boolean) {
        state.isEditMode = mode;
        forceUpdate();

    }


    function addItem(item: Api.SystemLookupType) {

        item.list_id = state.model?.column?.listId;
        item.column_id = state.model?.column?.listColumnId;
        item.workspace_id = props.workspaceId;
        item.isNew = true;
        item.sort_order = 1;

        if (state.items) {
            var lastItem = state.items[state.items.length - 1];
            item.sort_order = lastItem?.sort_order ? lastItem.sort_order + 1 : 1;
        }

        if (props.allowMultiSelect) {
            state.isSelectStaticPopoverOpen = true;
        }

        //keep here to save when Save is called
        state.chagnedItems.push(item);




        state.items.push(item);
        forceUpdate();



    }
    function moveItems(fromIndex: number, toIndex: number) {
        
        if (fromIndex === toIndex) return;
        const items = state.items;
        const [movedStatic] = items.splice(fromIndex, 1);
        items.splice(toIndex, 0, movedStatic);
        forceUpdate();
    };

    
    function isFullWidthStyle(item: Api.SystemLookupType | undefined = undefined) {

      //  if (state.allowMultipleSelection)
        state.isFullWidthStyle = false;

        if (state?.model?.column?.styleIsFullWidth || (item && !item.color))
        state.isFullWidthStyle= true;

            

        return state.isFullWidthStyle;
    }


    return {
        state,
        propsState,
        forceUpdate,
        saveChanges,
        saveColumnChanges,
        changeEditMode,
        changeSelectedValue,
        setPopoverOpened,
        deleteById,
        addItem,
        itemChanged,
        setInitialColumns,
        moveItems,
        isFullWidthStyle
    };
};

const LY_StaticDropDownContext = createContext<LY_StaticDropDownContextProps | undefined>(undefined);

export const useStaticDropDownContext = () => {


    const context = useContext(LY_StaticDropDownContext);

    if (!context) {
        throw new Error(
            "useLY_StaticDropDownContext must be used within a ListTableViewProvider"
        );
    }
    return context;
};


const LY_StaticDropDownContextProvider: React.FC<LY.LY_StaticDropDownProps & { children: React.ReactNode }> = (props) => {


    const contextValue = useDropDownModuleState(props);

    function render() {

        return (
            <LY_StaticDropDownContext.Provider value={contextValue}>
                {props.children}
            </LY_StaticDropDownContext.Provider>
        );
    }

    return render();
};
export { LY_StaticDropDownContextProvider, LY_StaticDropDownContext };
