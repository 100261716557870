import './LY_ListFilterModule_Filter_Columns.css';

import * as Icons from "@tabler/icons-react";
import * as LY from '_LY_Components';

import {
  Button,
  Popover,
  Switch,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";

import { LY_FiltersModule_Filter_Footer } from '../Components/LY_ListFilterModule_Filter_Footer/LY_ListFilterModule_Filter_Footer';
import { LY_FiltersModule_Filter_Header } from '../Components/LY_ListFilterModule_Filter_Header/LY_ListFilterModule_Filter_Header';
import useIsMobile from "_LIB/Components/Hooks/UseIsMobile";

 export const LY_FiltersModule_Columns: React.FC<LY.LY_ListFilterModuleProps> = (props) => {


  const context = LY.useListFilterModuleContext();
  const state = context.state;
  const { columns, view } = context.state;

  const [search, setSearch] = useState("");
  const [popoverOpened, setPopoverOpened] = useState(false);

  const getColumnCount = () => {

    var selectedColumns = state.columns?.filter(x => view.filter?.selectColumns?.includes(x.name));

   // var result = view.filter?.selectColumns?.length || 0;
    var result = selectedColumns?.length || 0;
  /*   if (result > 1)
      result--; */

    return result;
  };

  const { isMobile } = useIsMobile(768);
 
  function onPopoverChange(opened: boolean) {
    console.log('handlePopoverClose');

    if(!opened)
      setSearch("");

    setPopoverOpened(opened);
  }



  return (
    <Popover
      opened={popoverOpened}
      onChange={onPopoverChange}
      width={380}
      position="bottom-start"
      withinPortal={true}
      withArrow
    >
      <Popover.Target>
        {isMobile ? (
          <Icons.IconColumns
            width={36}
            height={36} color="#757B87"
            onClick={() => setPopoverOpened(true)}
            className="LY_ListFiltersModule_Filter_button_Mobile"
          />
        ) : (
          <Button
            className={`LY_ListFiltersModule_Columns_button ${getColumnCount() > 0 && 'LY_ListFiltersModule_Filter_button_active'}`}
            variant="transparent"
            rightSection={<Icons.IconChevronDown width={16} height={16} />}
            onClick={() => setPopoverOpened(true)}
          >
            {props.columnsMenuName || "Columns"}
             
            {!!getColumnCount() && <span className="LY_ListFiltersModule_Filter_count">{getColumnCount()}</span>}
          </Button>
        )}
      </Popover.Target>
      <Popover.Dropdown className="LY_ListFiltersModule_Columns_comboboxDropdown"
        classNames={{
          arrow: 'LY_PopoverRoundCorners-arrow',
          dropdown: 'LY_PopoverRoundCorners'
        }}>
        <LY_FiltersModule_Filter_Header
          fieldName="Show/Hide Columns"
          selectedItemCount={view?.filter?.selectColumns?.length}
        />
        <TextInput
          placeholder="Find columns to show/hide"
          leftSection={<Icons.IconSearch className="LY_ListFiltersModule_Filter_Searchbar_SearchIcon" />}
          onChange={(e) => setSearch(e.currentTarget.value || "")}
          classNames={{
            root: "LY_ListFiltersModule_Filter_Columns_Header_Searchbar_Root",
            wrapper: "LY_ListFiltersModule_Filter_Columns_Header_Searchbar_Wrapper",
            input: "LY_ListFiltersModule_Filter_Columns_Header_Searchbar_Input",
          }}
        />
        <div className="LY_ScrollbarDropDown">
          {columns?.length ? (
            columns.filter(column => column.displayName?.toLowerCase()?.includes(search?.toLowerCase()))
              .map((item, id) => (
                <div
                  key={id}
                  className="LY_ListFiltersModule_Columns_optionItem"
                >
                  <Switch
                    classNames={{
                      root: "LY_ListFiltersModule_Columns_optionItem_root",
                      body: "LY_ListFiltersModule_Columns_optionItem_body",
                      label: "LY_ListFiltersModule_Columns_optionItem_label",
                      labelWrapper: "LY_ListFiltersModule_Columns_optionItem_labelWrapper",
                      track: "LY_ListFiltersModule_Columns_optionItem_track",
                    }}
                    label={item.displayName}
                    checked={
                      view.filter?.selectColumns?.includes(item.name) ||
                      view.filter?.selectColumns?.length === 0
                    }
                    onClick={() => context.onSelectColumns(item.name)}
                    disabled={item.name === 'name'}
                  />
                </div>
              ))
          ) : (
            <div className="LY_ListFiltersModule_Columns_emptyOption">
              Nothing found
            </div>
          )}
        </div>
        <LY_FiltersModule_Filter_Footer
          leftAction={() => context.onColumnsHideAll()}
          leftActionName="Hide all"
          rightAction={() => context.onColumnsShowAll()}
          rightActionName="Show all"
        />
      </Popover.Dropdown>
    </Popover>
  );
};
