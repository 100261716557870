import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { createContext, useContext, useEffect } from "react";

import { LY_AddColumnModuleServiceManager } from '../Managers/LY_AddColumnModuleServiceManager';
import { LY_AddColumnModuleManager } from '../Managers/LY_AddColumnModuleManager';
import { LY_AddColumnModuleState } from './LY_AddColumnModuleState';


import { useDisclosure } from '@mantine/hooks';

export interface LY_AddColumnModuleContextProps {
  state: LY_AddColumnModuleState;
  forceUpdate: () => void;
  resetState: () => void;
  services: LY_AddColumnModuleServiceManager; 
  manager: LY_AddColumnModuleManager; 

}

const useAddColumnModuleState = (props: LY.LY_AddColumnModuleProps): LY_AddColumnModuleContextProps => {


  const initialState = new LY_AddColumnModuleState();

  const [state, setState] = React.useState<LY_AddColumnModuleState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_AddColumnModuleState>(new LY_AddColumnModuleState());


  const contextObjBase: LY.LY_AddColumnModuleContextProps = {
    state,
    forceUpdate,
    resetState,
    services: {} as LY_AddColumnModuleServiceManager,
    manager: {} as LY_AddColumnModuleManager
  };

  const services = new LY_AddColumnModuleServiceManager(props, state, contextObjBase);
  const manager = new LY_AddColumnModuleManager(props, state, contextObjBase,services);
  services.manager = manager;
  
  const contextObj: LY.LY_AddColumnModuleContextProps = {...contextObjBase, services: services, manager: manager};

   React.useEffect(() => {

    console.log('LY_AddColumnModule useEffect props', props);

    state.isOpen = props.state.vm.isAddColumnScreenOpen;
    state.newColumn = props.state.newColumn;
    
  
    forceUpdate();

    var stateCopy = clone(state);
    setPropsState(stateCopy);


  }, [ props.state.vm.isAddColumnScreenOpen, props.state.newColumn ]) 

  function clone(stateIn: LY_AddColumnModuleState): LY_AddColumnModuleState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_AddColumnModuleState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }


  function forceUpdate() {
    setState({ ...state })
  }
  
  function resetState() {
    var stateCopy = clone(propsState);
    setState({ ...state })
  }


 

  return contextObj;
};

const LY_AddColumnModuleContext = createContext<LY.LY_AddColumnModuleContextProps | undefined>(undefined);

export const useAddColumnModuleContext = () => {


  const context = useContext(LY_AddColumnModuleContext)


  if (!context) {
    throw new Error(
      "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_AddColumnModuleContextProvider: React.FC<LY.LY_AddColumnModuleProps & { children: React.ReactNode }> = (props) => {


  const contextValue = useAddColumnModuleState(props);

  function render() {


    return (
      <LY_AddColumnModuleContext.Provider value={contextValue}>
        {props.children}
      </LY_AddColumnModuleContext.Provider>
    );
  }

  return render();
};
export { LY_AddColumnModuleContextProvider, LY_AddColumnModuleContext };
