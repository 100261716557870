import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_EmailAccountSetup_CustomForm.css";
interface LY_EmailAccountSetup_CustomFormProps extends LY.LY_EmailAccountSetupModuleProps {
}

export const LY_EmailAccountSetup_CustomForm: React.FC<LY_EmailAccountSetup_CustomFormProps> = (props) => {

    const context = LY.useEmailAccountSetupModuleContext()
    const state = context.state

    function onInputChange(value: string, key: keyof Api.EmailAccount) {

        state.errors.delete(key)

        const item = state.newAccountSm

        //@ts-ignore
        item[key] = value

        context.forceUpdate();
    }

    function render() {

        return <div className="LY_EmailAccountSetup_CustomForm_Container">
            <div className="LY_EmailAccountSetup_CustomForm_Line">
                <LY.LY_Input
                    error={state.errors.get("first_name")}
                    value={state.newAccountSm.first_name}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "first_name");
                    }}
                    label="First Name"
                    labelPlacement='top'
                    isRequired={true}
                    inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer'
                    name="LY_EmailAccountSetup_NameInput" />
                <LY.LY_Input
                    error={state.errors.get("last_name")}
                    value={state.newAccountSm.last_name}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "last_name");
                    }}
                    label="Last Name"
                    labelPlacement='top'
                    isRequired={true}
                    inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer'
                    name="LY_EmailAccountSetup_LastNameInput" />
            </div>
            <div className="LY_EmailAccountSetup_CustomForm_FullLine">
                <LY.LY_Input
                    error={state.errors.get("email")}
                    value={state.newAccountSm.email}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "email");
                    }}
                    label="Email"
                    labelPlacement='top'
                    isRequired={true}
                    inputClassName='LY_EmailAccountSetup_CustomForm_Input'
                    name="LY_EmailAccountSetup_EmailInput" />
            </div>
            <div className="LY_EmailAccountSetup_CustomForm_Line">
                <LY.LY_Input
                    error={state.errors.get("username")}
                    value={state.newAccountSm.username}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "username");
                    }}
                    label="Username"
                    labelPlacement='top'
                    isRequired={true}
                    inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer'
                    name="LY_EmailAccountSetup_UserameInput" />
                <LY.LY_Input
                    error={state.errors.get("password")}
                    type="password"
                    value={state.newAccountSm.password}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "password");
                    }}
                    label="Password"
                    labelPlacement='top'
                    isRequired={true}
                    inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer'
                    name="LY_EmailAccountSetup_PasswordInput" />
            </div>
            <div className="LY_EmailAccountSetup_CustomForm_Line">
                <LY.LY_Input
                    type="number"
                    error={state.errors.get("imap_host")}
                    value={state.newAccountSm.imap_host}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "imap_host");
                    }}
                    label="IMAP Host"
                    labelPlacement='top'
                    isRequired={true}
                    inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer'
                    name="LY_EmailAccountSetup_HostInput" />
                <LY.LY_Input
                    inputContainerClassName='LY_EmailAccountSetup_PortInput_Container'
                    type="number"
                    error={state.errors.get("imap_port")}
                    value={state.newAccountSm.imap_port}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "imap_port");
                    }}
                    label="IMAP Port"
                    labelPlacement='top'
                    isRequired={true}
                    name="LY_EmailAccountSetup_PortInput" />
                <LY.LY_DropDown
                    data={state.encryptionTypes}
                    valueProperty="id"
                    displayProperty="name"
                    name="LY_EmailAccountSetup_Imap_Encryption"
                    selectedItem={state.selectedImapEncryptionType}
                    onChange={(item, option) => {
                        context.setSelectedImapEncryptionType(item.name)
                    }}
                    label="IMAP Encryption"
                    isRequired
                    //isAutoCompleteStyle={true}
                    isBadgeCompleteStyle={false}
                />
            </div>
            <div className="LY_EmailAccountSetup_CustomForm_Line">
                <LY.LY_Input
                    type="number"
                    error={state.errors.get("smtp_host")}
                    value={state.newAccountSm.smtp_server}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "smtp_server");
                    }}
                    label="SMTP Host"
                    labelPlacement='top'
                    isRequired={true}
                    inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer'
                    name="LY_EmailAccountSetup_HostInput" />
                <LY.LY_Input
                    inputContainerClassName='LY_EmailAccountSetup_PortInput_Container'
                    type="number"
                    error={state.errors.get("smtp_port")}
                    value={state.newAccountSm.smtp_port}
                    onChange={(m, e) => {
                    onInputChange(e?.target.value, "smtp_port");
                    }}
                    label="SMTP Port"
                    labelPlacement='top'
                    isRequired={true}
                    name="LY_EmailAccountSetup_PortInput" />
                <LY.LY_DropDown
                    data={state.encryptionTypes}
                    valueProperty="id"
                    displayProperty="name"
                    name="LY_EmailAccountSetup_SMTP_Encryption"
                    selectedItem={state.selectedSmtpEncryptionType}
                    onChange={(item, option) => {
                        context.setSelectedSmtpEncryptionType(item.name)
                    }}
                    label="SMTP Encryption"
                    isRequired
                    isBadgeCompleteStyle={false}
                />
            </div>
        </div>
    }




    return render();
};
