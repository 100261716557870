import React, { ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, HTMLAttributes, ReactElement, ReactNode } from 'react';

import * as LY from '_LY_Components';

import './LY_Modal.css';
import * as MT from '@mantine/core';
import * as LIB from '_LIB';

interface LY_ModalProps {
  // ... additional props
  children?: any; // This line allows the component to accept children
  footer?: ReactNode;
  header?: ReactNode;
};


export const LY_Modal: React.FC<LY_ModalProps & MT.ModalProps> = ((
  {

    ...props

  }) => {


  function getFooter() {
    if (!props.footer)
      return null;

    return <div className="LY_Modal_Footer">
      {props.footer}
    </div>
  }

  function getHeader() {
    if (!props.header)
      return null;

    return <div className="LY_Modal_Header">
      {props.header}
    </div>
  }
  function getContent() {

    if (!props.footer) {
      return props.children;
    }

    return <div className='LY_Modal_Container'>
      {getHeader()}
      <div className='LY_Modal_ScrollArea LY_Scrollbar'>
        {props.children}
      </div>
      {getFooter()}
 
    </div>

  }


  return <MT.Modal
    className={'LY_Modal_Container ' + (props.className || '')}
    classNames={props.classNames ? props.classNames : { body: 'LY_Modal_Body', overlay: 'LY_Modal_Overlay' }}
    centered
    overlayProps={{
      backgroundOpacity: 0.1,
      blur: 0,
    }}
    transitionProps={{ duration: 0, timingFunction: 'linear' }}
    zIndex={props.zIndex || 7001}

    {...props}
  >
    {getContent()}
  </MT.Modal>


});
