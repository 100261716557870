import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./UpdateColumn_ExistingRelColumn.css";




interface UpdateColumn_ExistingRelColumnProps {
  state: App.ListState;
  vm: LY.ListColumnUpdateVm;
}

export const UpdateColumn_ExistingRelColumn: React.FC<UpdateColumn_ExistingRelColumnProps> = (props) => {

  const { view, list, selectedColumn, columnTypes } = props.state;

  var state = props.state;
  var item = selectedColumn;
  var vm = props.vm;

function getForm() {


  var relCol = item?.fkExistingRelationshipColumn;

  return <div className='UpdateColumn_ExistingRelColumn_Container'>

    <LY.LY_Input
      name="Related Workspace/List"
      label="Existing Relationship"
      readOnly={true}
      value={item?.fkExistingRelationshipColumn?.displayName}
      isLoading={vm.isActionInProgress}
      inputContainerClassName='UpdateColumn_ExistingRelColumn_Container_Item'
    />

    <LY.LY_Input
      name="Related Workspace/List"
      label="Related Workspace/List"
      readOnly={true}
      value={relCol?.fkWorkspace?.name + ' > ' + relCol?.fkList?.name}
      isLoading={vm.isActionInProgress}
      inputContainerClassName='UpdateColumn_ExistingRelColumn_Container_Item'
    />

    <LY.LY_Input
      name="Related Workspace/List"
      label="Add/Join Relationship Column"
      readOnly={true}
      value={item?.fkExistingRelationshipJoinColumn?.displayName}
      isLoading={vm.isActionInProgress}
      inputContainerClassName='UpdateColumn_ExistingRelColumn_Container_Item'
    />


  </div>

}

function render() {


  return getForm();


}

return render();

};
