import * as Icons from "@tabler/icons-react";

import { createSuggestionItems } from "novel/extensions";
import { Command, renderItems } from "novel/extensions";

export const suggestionItems = createSuggestionItems([
  {
    title: "Select Columns",
    description: "Let us know how we can improve.",
    searchTerms: ["p", "paragraph"],
    icon: <Icons.IconLayoutColumns size={18} />,
  },
  {
    title: "Text",
    description: "Just start typing with plain text.",
    searchTerms: ["p", "paragraph"],
    icon: <Icons.IconLayoutColumns size={18} />,
    command: ({ editor, range }) => {
      editor?.chain()?.focus()?.deleteRange(range).run();
    },
  },
  {
    title: "To-do List",
    description: "Track tasks with a to-do list.",
    searchTerms: ["todo", "task", "list", "check", "checkbox"],
    icon: <Icons.IconCheck size={18} />,
    command: ({ editor, range }) => {
      editor?.chain()?.focus()?.deleteRange(range).toggleTaskList().run();
    },
  },
  {
    title: "Heading 1",
    description: "Big section heading.",
    searchTerms: ["title", "big", "large"],
    icon: <Icons.IconH1 size={18} />,
    command: ({ editor, range }) => {
      editor?.chain()?.focus()?.deleteRange(range).setNode("heading", { level: 1 }).run();
    },
  },
  {
    title: "Heading 2",
    description: "Medium section heading.",
    searchTerms: ["subtitle", "medium"],
    icon: <Icons.IconH2 size={18} />,
    command: ({ editor, range }) => {
      editor?.chain()?.focus()?.deleteRange(range).setNode("heading", { level: 2 }).run();
    },
  },
  {
    title: "Heading 3",
    description: "Small section heading.",
    searchTerms: ["subtitle", "small"],
    icon: <Icons.IconH3 size={18} />,
    command: ({ editor, range }) => {
      editor?.chain()?.focus()?.deleteRange(range).setNode("heading", { level: 3 }).run();
    },
  },
  {
    title: "Bullet List",
    description: "Create a simple bullet list.",
    searchTerms: ["unordered", "point"],
    icon: <Icons.IconList size={18} />,
    command: ({ editor, range }) => {
      editor?.chain()?.focus()?.deleteRange(range).toggleBulletList().run();
    },
  },
  {
    title: "Numbered List",
    description: "Create a list with numbering.",
    searchTerms: ["ordered"],
    icon: <Icons.IconListNumbers size={18} />,
    command: ({ editor, range }) => {
      editor?.chain()?.focus()?.deleteRange(range).toggleOrderedList().run();
    },
  },
  {
    title: "Quote",
    description: "Capture a quote.",
    searchTerms: ["blockquote"],
    icon: <Icons.IconQuote size={18} />,
    command: ({ editor, range }) =>
      editor?.chain()?.focus()?.deleteRange(range).toggleNode("paragraph", "paragraph").toggleBlockquote().run(),
  },
  {
    title: "Code",
    description: "Capture a code snippet.",
    searchTerms: ["codeblock"],
    icon: <Icons.IconCode size={18} />,
    command: ({ editor, range }) => editor?.chain()?.focus()?.deleteRange(range).toggleCodeBlock().run(),
  },
]);

export const slashCommand = Command.configure({
  suggestion: {
    items: () => suggestionItems,
    render: renderItems,
  },
});
