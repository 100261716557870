import * as Api from "app-api";
import * as LIB from "_LIB";

export class LY_ListViewTabsState extends LIB.BaseVmModel {
    public tabs: Api.ListViewSm[] = [];
 
 
    public inputValue: string = '';
    public selectedTab?: Api.ListViewSm;
    public selectedSettingsItem?: Api.ListViewSm;
    public updateItem?: Api.ListViewSm;
    public newItem?: Api.ListViewSm;

    public errors: Map<string, string> = new Map();
    public isNew: boolean = false;
   // public selectedTabId: number;

    public listId:string;
    public workspaceId:number;

    public tabContextMenuOpenId:any;

    public isNewTabModalOpen: boolean = false;
    public isAllTabsSettingsOpen: boolean = false;
    public isTabSettingsOpen: boolean = false;
    public isDeleteModalOpen: boolean = false;

  //  public pinnedTabs: Api.ListViewSm[] = [];


    constructor() {
        super();
    }
}
