import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_ListPageModule.css";


import { LY_ListPageModuleContextProvider } from './Context/LY_ListPageModuleContext';
import { LY_ListPageModule_Container } from './Components/LY_ListPageModule_Container/LY_ListPageModule_Container';
 


export const LY_ListPageModule: React.FC<LY.LY_ListPageModuleProps> = (props) => {

  function render() {
    //var view = props.view;
    // console.log('LY_FiltersModule render view:', view);
    // console.log('LY_FiltersModule render view.filter:', view?.filter);
    //console.log('LY_FiltersModule render view.orderByColumns:', view?.filter?.orderByColumns);
 
    return (
      <LY_ListPageModuleContextProvider {...props}  >

        <LY_ListPageModule_Container {...props} />
       
      </LY_ListPageModuleContextProvider>
    );
  }




  return render();
};
