import './LY_ListSettings.css'


import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import { LY_ListSettingsModal } from '../LY_ListSettingsModal/LY_ListSettingsModal';
import { LY_ListSettingsDeleteModal } from '../LY_ListSettingsDeleteModal/LY_ListSettingsDeleteModal';

const menuItems = [
  { id: "settings", name: "Settings" },
  { id: "delete", name: "Delete" },
];
export const LY_ListSettings = () => {

  const context = LY.useListSettingsModuleContext();
  const { services, state } = context;

  function render() {
    return <div className='LY_ListSettings'>

      <LY.LY_Button 
      className='LY_ListSettings_NewButton'
      onClick={services.props.onNewButtonClick}>New</LY.LY_Button>

      <LY.LY_MenuButton 
      menuItems={menuItems} 
      onItemClick={(val) => context.handleMenuItemClick(val.id)} />

      {state.isSettingsModalOpen && <LY_ListSettingsModal />}
      {state.isDeleteModalOpen && <LY_ListSettingsDeleteModal />}
    </div>
  }

  return render();
}
