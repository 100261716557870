import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { LY_KanbanSection } from "../Context/LY_KanbanModuleContext";
import { LY_KanbanModuleState } from "../Context/LY_KanbanModuleState";

export class LY_KanbanBoardModuleServices extends LIB.BaseManager {
    public state: LY_KanbanModuleState;
    public forceUpdate: Function;
    public props: LY.LY_KanbanProps;

    constructor(props: LY.LY_KanbanProps, state: LY_KanbanModuleState, forceUpdate: Function) {
        super("id");
        this.state = state;
        this.forceUpdate = forceUpdate;
        this.props = props;
    }



    public onColumnChange() {

        var service = new App.ListColumnService(this.props.workspaceId);
        this.state.isActionInProgress = true;

        this.forceUpdate();

        var col = this.state?.model?.column;
        if (!col || !col.listColumnId) {
            console.error('error: LY_DropDownModuleManager onColumnChange invalid column:', col);
            return;
        }


        service.updateColumn(col,
            () => {
                App.AppBase.showSaveSuccess();
                this.state.isActionInProgress = false;
                this.forceUpdate();
            },
            this.onServiceError.bind(this)
        );

    }
    public async updateValues(sm: Api.ListUpdateDataSm) {

        var service = new App.ListDataService(this.props.workspaceId);
 

        service.updateValues(sm,

            //On Success
            (result:Api.ListState ) => {

              //  this.state.data = result;
              //  state.sections  = getColumnsAndRecords(props.sections, props.listState?.records);

              //remove updated records
              if(result && result.records){
                var recordsWithNoUpdates = this.props.listState?.records?.filter(x=>!result?.records?.find(y=>y.id === x.id));
                
                if(recordsWithNoUpdates)
                    result.records = [ ...result.records, ...recordsWithNoUpdates];
              }

               // console.log('LY_DropDownModuleManager updateValues', result);
                if(this.props.onItemsSaveChangesSuccess){
                    this.props.onItemsSaveChangesSuccess(sm,result)
                }
                
            },
            // On Error
            this.onServiceError.bind(this)
        );


 
    }


    public updateSections(sm: Api.SystemLookupType[]) {
        var service = new App.StaticDropDownService(this.props.workspaceId);

        var filtered = sm.filter(x => x.id && x.name!='Unassigned');

        service.saveChanges(filtered,

            //On Success
            (result: Api.SystemLookupType[]) => {

                if (result && result.length) {
                    const newCol = result[ result?.length - 1 ] as LY_KanbanSection;
                    newCol.records = [];
                    if (newCol.name !== this.state.sections[ this.state.sections.length - 1 ].name) {
                        this.state.sections = [ ...this.state.sections, newCol ];
                    }
                    this.state.isActionInProgress = false;
                    this.state.isColumnModalOpened = false;
                    this.state.selectedItem = new Api.SystemLookupType();
                    this.forceUpdate();
                    App.AppBase.showSaveSuccess();


                }
                else {

                    this.onServiceError('Unable to save the chnages, please try again!');
                }
            },
            // On Error
            this.onServiceError.bind(this)
        );

        this.state.isActionInProgress = true;
        this.forceUpdate();

    }

    public delete() {

        var service = new App.StaticDropDownService(this.props.workspaceId);
        const id = this?.state?.selectedItem?.id!;
        this.state.isActionInProgress = true;
        this.forceUpdate();

        return service.delete(id,
            () => {
                const updatedStatuses = this.state.sections.filter(item => item.id !== id)
                this.state.sections = updatedStatuses;
                this.state.isActionInProgress = false;
                this.state.isDeleteModalOpened = false;
                this.forceUpdate();

                App.AppBase.showSaveSuccess();


            },
            this.onServiceError.bind(this)
        );
    }

    public onServiceError(error: any) {

      //  console.error('LY_DropDownModuleManager onServiceError', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

      ///  console.error('LY_DropDownModuleManager onServiceError errorMessage', errorMessage);

        this.state.isActionInProgress = false;
        this.state.errors.set('all', errorMessage);
        App.AppBase.showError(errorMessage);
        this.forceUpdate();

    }
}
