import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from '_LIB';
import * as LY from "_LY_Components";
import * as MT from '@mantine/core';
import * as React from "react";


export const LY_ListViewTabsCreateNewViewModal: React.FC<LY.LY_ListViewTabsModuleProps> = (props) => {

    const context = LY.useListViewTabsContext();
    const { state } = context;

    var item = state.newItem;
    var errors = state.errors;

    function onInputChange(e: React.ChangeEvent<any>) {

        var key = LIB.FormHelper.getChangeTargetName(e.target);
        var value = LIB.FormHelper.getChangeTargetValue(e.target);

        onValueChange(key, value);

    }

    function onValueChange(key: string, value: any) {
        //console.log('LY_ListViewTabsCreateNewViewModal onValueChange key:', key);
        //console.log('LY_ListViewTabsCreateNewViewModal onValueChange value:', value);

        /*         if (key == 'name') {
                    value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
                } */

        //@ts-ignore
        item[ key ] = value;


/*         console.log('LY_ListViewTabsCreateNewViewModal onValueChange key:', key);
        console.log('LY_ListViewTabsCreateNewViewModal onValueChange value:', value);
        console.log('LY_ListViewTabsCreateNewViewModal onValueChange item:', item);
 */
        validate(key);


        context.forceUpdate();


    }


    function validateAll(): boolean {
        return validate('all');
    }

    function validate(key: string): boolean {

        var vAll = (key === 'all'); //validate all

        errors.clear();

       // console.log('LY_ListViewTabsCreateNewViewModal validate item:', item);

        if (vAll || key == 'name') {
            if (LIB.Common.isNullOrEmpty(item?.name) || !item?.name)
                errors.set('name', `Name is required`);
            else if (item?.name?.length > 50)
                errors.set('name', `Name must be less than 50 characters`);
        }

        if ((vAll || key == 'kanbanTypeStatusColumnId')
            && !item?.kanbanTypeStatusColumnId && item?.listViewId == App.ListViewTypeEnum.Kanban) {
            errors.set('kanbanTypeStatusColumnId', `Status Column is required`);
        }

        var isValid = (errors.size < 1);
        return isValid;

    }

    function onSaveClick(e: React.MouseEvent) {

        //e.preventDefault();
        state.isActionInProgress = false;

        var isValid = validateAll();
        if (!isValid) {
            console.log('LY_ListViewTabsCreateNewViewModal onSubmit isValid:', isValid, errors);
            context.forceUpdate();
            return false;
        }

        context.createNewView();


    }


    function render() {

        //   console.log('LY_ListViewTabsCreateNewViewModal render item', item);

        if (item && !item?.listViewTypeId)
            item.listViewTypeId = App.ListViewTypeEnum.Table;

        var selectedItem = props.listViewTypes?.find(x => x.lookupTypeId === item?.listViewTypeId);


        if(item && !item?.kanbanTypeStatusColumnId && item?.listViewTypeId == App.ListViewTypeEnum.Kanban)
               item.kanbanTypeStatusColumnId = props.statusColumns[0]?.listColumnId;
  
        /*         console.log('LY_ListViewTabsCreateNewViewModal render item.kanbanTypeStatusColumnId', item?.kanbanTypeStatusColumnId);
                console.log('LY_ListViewTabsCreateNewViewModal render statusColumns', statusColumns);
         */

        return <LY.LY_Modal
            opened={state.isNewTabModalOpen}
            onClose={() => {
                state.isNewTabModalOpen = false;
                context.forceUpdate();
            }}

            title={`Create a New View`}

        >

            <LY.LY_Input
                name="name"
                label="Name"
                value={item?.name || ''}
                isRequired
                error={errors.get('name')}
                // helpText="This name will be used in APIs or Integrations"
                // helpTextPlacement='bottom'
                onChange={(m, e) => {
                    onInputChange(e!);
                }}
            />

            <div className='LY_Flex_Row' style={{ alignItems: 'start' }}>
                <LY.LY_DropDown

                    name="listViewTypeId"
                    label="View Type"
                    placeholder='Defaults to Table'
                   
                    
                    displayProperty="name"
                    valueProperty="lookupTypeId"
                    isAutoCompleteStyle={true}
                    withStar={true}
                    data={props.listViewTypes}
                    selectedItem={selectedItem}
                   // value={item?.listViewTypeId}
                    inputContainerStyle={{ width: '100%' }}
                    error={errors.get('listViewTypeId')}
                    isClearable={false}
                    onChange={(value, option: any) => {

                        console.log('AddColumnScreen LY_DropDown dropdownValueColumnTypeId value:', value);
                        console.log('AddColumnScreen LY_DropDown dropdownValueColumnTypeId option:', option);

                        onValueChange('listViewTypeId', option?.value)
                    }}

                />

                {item?.listViewTypeId == App.ListViewTypeEnum.Kanban &&
                    <LY.LY_DropDown
                        name="kanbanTypeStatusColumnId"
                        label="Section Column"
                        withStar={true}
                        selectedItem={props.statusColumns.find(x => x.listColumnId === item?.kanbanTypeStatusColumnId)}
                        displayProperty="displayName"
                        valueProperty="listColumnId"
                        data={props.statusColumns}
                        isAutoCompleteStyle={true}
                        isClearable={false}
                        error={errors.get('kanbanTypeStatusColumnId')}
                        inputContainerStyle={{ width: '100%' }}
                        onChange={(value, option: any) => {

                            onValueChange('kanbanTypeStatusColumnId', option?.value)
                        }}

                    />
                }
            </div>

            <LY.LY_CheckBox
                name="isPinned"
                //styles={{ root: { marginTop: 10 } }}
                checked={item?.isPinned}
                label="Pinned"
                labelPlacement="right"
                error={state.errors.get('isPinned')}
                onChange={(m, e) => {
                    onInputChange(e!);
                }}

            />








            <div className="LY_ListFiltersModule_Save_Modal_Buttons">

                <LY.LY_ButtonCancel
                    onClick={() => {
                        context.reset();
                    }}
                >
                    Cancel
                </LY.LY_ButtonCancel>



                <LY.LY_Button
                    loading={state.isActionInProgress}
                    loaderProps={{ type: 'dots' }}
                    onClick={onSaveClick}
                >
                    Create
                </LY.LY_Button>

            </div>
        </LY.LY_Modal>
    }
    return render();
}