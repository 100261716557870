import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, Stack, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as MT from "@mantine/core";
import * as Icons from "@tabler/icons-react";


//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";

import { useParams, useLocation } from 'react-router';

import { ListTableViewTable } from './components/ListTableViewTable';
import { UpdateColumnScreen } from '../Columns/UpdateColumnScreen/UpdateColumnScreen';

import { ListFilter } from '../ListFilter/ListFilter';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ResizeHandle from 'Components/Layouts/MainSplitPage/ResizeHandle';
import { DetailRightPanel } from 'Pages/Detail/DetailRightPanel/DetailRightPanel';
import { SelectedItemActions } from '../SelectedItemActions/SelectedItemActions';
import { DetailPopupModal } from 'Pages/Detail/DetailPopupModal/DetailPopupModal';
import { LY_ListFilterModule } from '_LY_Components/_Modules/LY_ListFilterModule/LY_ListFilterModule';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';



//class ListTableView extends React.Component<App.CMSState, {}> {
export const ListTableView: React.FC<LY.ListTableViewProps> = (props) => {


  var validationManager: App.ValidationManager = new App.ValidationManager();

  var params = useParams();
  var location = useLocation();

  let initialListState = new App.ListState();
  initialListState.forceReadOnly = props.forceReadOnly || false;
  initialListState.hideCheckboxSelection = props.hideCheckboxSelection || false;

  const [ listState, setListState ] = React.useState(initialListState);
  const vm = listState.vm;

  const forceUpdate = LIB.useForceUpdate();
  listState.listFilterSm.linkedWorkspaceId = props.linkedWorkspaceId;
  listState.linkedWorkspaceId = props.linkedWorkspaceId;
  listState.workspaceId = props.workspaceId!;
  listState.lookupTypes = props.lookupTypes;


  const workspaceVm = App.AppBase.getCurrentWorkspaceVm();
  const workspace = workspaceVm.selectedWorkspace;

  const currentWorkspace = workspaceVm.selectedWorkspace;
  const selectedWorkspaceItem = workspaceVm?.selectedItem;

  if (!listState.workspaceLookupTypes || listState.workspaceLookupTypes.length == 0)
    listState.workspaceLookupTypes = currentWorkspace?.lookupTypes;

  var listTableManager: LY.ListTableManager = new LY.ListTableManager(props, listState, forceUpdateState,
    onListDataLoaded, onListDataChanged);

  const subject = new Subject();


  React.useEffect(() => {


    /*  console.log('ListTableView useEffect filter:', props.filter);
     console.log('ListTableView useEffect filter: forceFilter:', props.forceFilter);
  */

    /*     console.log('ListTableView useEffect viewId:', props.viewId);
        console.log('ListTableView useEffect vm:', listState.vm);
        console.log('ListTableView workspaceId', props.workspaceId);
        console.log('ListTableView linkedWorkspaceId', props.linkedWorkspaceId); */
    //console.log('ListTableView forceReadOnly', props.forceReadOnly); 
    console.log('ListTableView useEffect props.cachedListState', props.cachedListState);

    if (props.cachedListState)
      setListState(props.cachedListState);
    else
      onRefreshData();

  }, [ props.viewId ]);


  function forceUpdateState(st: App.ListState | undefined = undefined) {
    var vm = listState.vm;

    console.log('ListTableView forceUpdateState isActionInProgress', vm.isActionInProgress);
    console.log('ListTableView forceUpdateState state', st);

    if (st?.workspace?.lookupTypes && currentWorkspace) {
      console.log('ListTableView forceUpdateState lookupTypes', st?.workspace?.lookupTypes);
      currentWorkspace.lookupTypes = st.workspace.lookupTypes;

    }

    setListState(listState);

    forceUpdate();
  }

  function onListDataLoaded(listState: App.ListState) {
    console.log('onListDataLoaded listState', listState);
    // console.log('onListDataLoaded filter', listState?.view?.filter);
    // console.log('onListDataLoaded filter.selectColumns', listState?.view?.filter?.selectColumns);

    setListState(listState);
    /* 
        listStates.push(listState);
    
        console.log('onListDataLoaded listStates', listStates); */

    if (props.onListDataLoaded)
      props.onListDataLoaded(listState);
  }


  // Subscribe to the Subject with debounceTime and distinctUntilChanged
  subject.pipe(
    debounceTime(2000),
    //distinctUntilChanged((prev:App.ListState, curr:App.ListState) => prev..column.name === curr.newState.column.name)
  ).subscribe({
    next: (data: any) => {
      onListDataChangedRxJs(data.newState);
    },
  });

  function onListDataChanged(newState: App.ListState) {
    console.log('ListTableView onListDataChanged listState', newState);

    onListDataChangedRxJs(newState);
    // Emit the new data
    //subject.next({ newState });

  }

  function onListDataChangedRxJs(newState: App.ListState) {
    console.log('ListTableView onListDataChanged listState', newState);

    listState.records = [ ...newState.records ];
    listState.newItem = newState.newItem;
    setListState(listState);
    forceUpdate();

    if (props.onListDataChanged)
      props.onListDataChanged(newState);

  }


  function onRefreshData() {
    console.log('ListTableView onRefreshData');

    if (props.forceFilter)
      listTableManager.getListData(undefined, props.filter);
    else
      listTableManager.getListData();



  }


  function onCreateClick() {
    console.log('ListTableView onCreateClick');
    var state = listState;
    var vm = state.vm;

    // vm.setSelectedPage();

    // App.RouteManager.routeToPath(`/Pages/0/`);

    //vm.isPagesAddOpen = true;
    forceUpdate();


  }


  function getRightPanelRenderer() {


    var result = <DetailRightPanel
      state={listState}
      model={vm.selectedItem}
    />;


    /*     if(LY.LYTemplates.isBiddingBoardList(view?.list)){
    
        }
     */


    return result;


  }

  function setSelectedItem(item: LY.DataItemModel | undefined=undefined) {
 
    if (item === undefined)
        item = new LY.DataItemModel();
 
    vm.selectedItem = item;
    vm.selectedItemOriginal = LIB.ObjectHelper.clone(vm.selectedItem);

  }


  function getRelationshipItemClick(m: LY.DataItemModel | undefined, event: React.MouseEvent<any> | undefined) {

    console.log('ListTableView getRelationshipItemClick m: ', m);

    var state = listState;
    var vm = state.vm;
    var col = m?.column;

    console.log('ListTableView getRelationshipItemClick col: ', col);
    console.log('ListTableView getRelationshipItemClick column name: ', col?.name);


    setSelectedItem(m);
    vm.isDetailPopupModalOpen = true;

    if (col?.name == 'name') {
      vm.selectedDetailListId = col?.listId;
      vm.selectedDetailViewId = state.list?.detailViewId || state.view?.listViewId;
    }
    else {
      vm.selectedDetailListId = col?.fkListId;
      vm.selectedDetailViewId = col?.fkListViewId;
    }

    // App.AppStore.dispatch(App.ActionTypes.LIST_DATA_ITEM_VIEW_DETAIL_POPUP_CHANGE, vm);
    forceUpdate();


  }



  function getKanbanView() {

    var state = listState;

    var view = listState.view;
    var dropDownDataSystemData = currentWorkspace?.lookupTypes;

    var statuses = dropDownDataSystemData?.filter(x => x.list_id === listState.listId && x.column_id === view.kanbanTypeStatusColumnId) || [];

    if (!statuses || statuses.length == 0)
      statuses = listState?.workspaceLookupTypes?.filter(x => x.list_id === listState.listId && x.column_id === view.kanbanTypeStatusColumnId) || [];

    var listStatuses = listState?.workspaceLookupTypes?.filter(x => x.list_id === listState.listId) || [];

/*     console.log('ListTableView getKanbanView statuses:', statuses);
    console.log('ListTableView getKanbanView workspaceLookupTypes:', listState?.workspaceLookupTypes);

    console.log('ListTableView getKanbanView d2 view.kanbanTypeStatusColumnId:', view.kanbanTypeStatusColumnId);
    console.log('ListTableView getKanbanView d2  listState.listId:', listState.listId);
    console.log('ListTableView getKanbanView d2 listStatuses:', listStatuses); */


    var lookupTypes = [ ...workspace?.lookupTypes || [], ...state?.workspaceLookupTypes || [] ];


    return <LY.LY_Kanban
      workspaceId={props.workspaceId}
      listId={listState.listId}
      lookupTypes={lookupTypes}
      users={workspaceVm?.users}
      dropDownData={state.dropDownData}

      //  onAddNewItem={(col) => { console.log(col) }}
      onItemClick={(item, event) => {

        console.log('LY.LY_Kanban onItemClick', item)
        var itemM = new LY.DataItemModel();
        itemM.row = item;

        if (onNameClick)
          onNameClick(itemM, event);
      }}

      onItemsSaveChangesSuccess={(newStatus, newState) => {

        console.log('ListTableViewTable onItemsSaveChangesSuccess', newStatus);

        var newListState = { ...listState, records: newState.records } as App.ListState;

        setListState(newListState);
        if (props.onResetCache)
          props.onResetCache(view.listViewId);

      }}



      // onItemClick={onNameClick}
      sections={statuses}
      listState={listState}
      statusColumnId={view.kanbanTypeStatusColumnId}
    />
  }




  function onNameClick(m: LY.DataItemModel | undefined, e: React.MouseEvent<HTMLElement> | undefined) {
    console.log('onNameClick model:', m);
    if (e)
      e.preventDefault();

    if (props.disableNameClick)
      return;

    //var vm = state.vm;

    setSelectedItem(m);
    vm.isDetailRightPanelOpen = true;
    //forceUpdate();


    App.AppStore.dispatch(App.ActionTypes.LIST_SETTINGS_DETAIL_PANEL_CHANGE, vm);


  }


  function getViewContentByViewType() {



    var state = listState;

    var view = state.view;

    if (view?.listViewTypeId == App.ListViewTypeEnum.Kanban) {
      return getKanbanView();
    }

    return <div>
      <ListTableViewTable
        state={state}
        onForceUpdate={() => {
          console.log('ListTableViewTable onForceUpdate');
          forceUpdate()

          if (props.onResetCache)
            props.onResetCache();
        }}

        onResetCache={props.onResetCache}

        listTableManager={listTableManager}
        hideFooterWhenNoPaging={props.hideFooterWhenNoPaging}
        disableNameClick={props.disableNameClick}
        refreshData={onRefreshData}
        presetRelColumnName={props.presetRelColumnName}
        presetRelColumnValue={props.presetRelColumnValue}
        onSelectedItemsChange={(selectedItems: any[]) => {
          state.selectedItems = selectedItems;
          forceUpdate();

          if (props.onSelectedItemsChange)
            props.onSelectedItemsChange(listState, selectedItems);

        }}

        tableHeight={props.tableHeight}
        minTableHeight={props.minTableHeight}
        leftPinnedColumns={props.leftPinnedColumns}
        forceUpdateState={forceUpdateState}
        getRelationshipItemClick={getRelationshipItemClick}
        autoHeight={props.autoHeight}
        onNameClick={onNameClick}
      />

      <LY.LY_AddColumnModule state={state}
        refreshData={onRefreshData}
        onColumnAddedSuccess={(result: any) => { vm.isAddColumnScreenOpen = false; forceUpdate(); }}
        onClose={() => { vm.isAddColumnScreenOpen = false; forceUpdate(); }}

        workspaceId={props.workspaceId}
      />

      <UpdateColumnScreen state={state}
        refreshData={onRefreshData}
        onClose={() => { vm.isColumnSettingsScreenOpen = false; forceUpdate(); }}
      />

    </div>;

  }


  function onNewButtonClick(){
    console.log('ListTableView onNewItemClick');
 
    if(props.onNewButtonClick)
      props.onNewButtonClick();

  }

  function render() {

    //console.log('ListTableView render');

    var state = listState;

    var currentUser = App.AppBase.currentUser;
    //var filter = state.listFilter;

    //console.log('ListTableView hasPageDataLoaded', state.hasPageDataLoaded);
    // console.log('ListTableView isServiceCallPending',myListingsService.isServiceCallPending);
    //console.log('ListTableView vm.isActionInProgress 1', vm.isActionInProgress);

    var list = state.list;
    var view = state.view;


    //view.filter = state?.listFilter;

    // console.log('ListTableView render view.filter', view.filter);

    var showSave = (currentUser?.isAdmin || App.AppBase.isDev()) && !state.forceReadOnly;

    if (!showSave && currentUser?.email?.toLowerCase()?.endsWith('loocey.com'))
      showSave = true;

    var createBtn: any = null;


    var hide = state.forceReadOnly;

    //  console.log('ListTableView render vm.selectedDetailListId', vm.selectedDetailListId);


    var filterColumnsMenuName = 'Columns';
    if (view.listViewTypeId == App.ListViewTypeEnum.Kanban) {
      filterColumnsMenuName = 'Fields';
    }

    return <App.MainContainer

      errorMessage={vm.apiErrorMessage}
      loading={vm.isActionInProgress}
      hasDataLoaded={state.hasDataLoaded}
      showContentWhenInProgress={true}
      showModalForLoading={true}

    >
      <LIB.Loading show={vm.isActionInProgress} isModal={true} />
      <PanelGroup direction='horizontal'
        autoSaveId={'ListTableView'}
        className={`ListPageContentPanelGroup ${props.groupPanelClass || ''}`}
      >
        <Panel className='ListPageContent' defaultSize={50}>

          <div>

            <div className='LY_List_TopBar'>
              {props.showFilters && (

                <LY_ListFilterModule
                  workspaceVm={workspaceVm}
                  view={view}
                  columns={state.view.columns}
                  columnsMenuName={filterColumnsMenuName}
                  onApply={(newView: Api.ListView) => {
                    console.log('LY_FiltersModule onApply newView:', newView);
                    console.log('LY_FiltersModule onApply newView.filter:', newView.filter);

                    //reset paging
                    if (newView.filter)
                      newView.filter.pageNum = 1;
                    // listTableManager.getListData()
                    listTableManager.getListData(undefined, newView.filter)
                  }}
                  onColumnSelectionChange={(newView: Api.ListView) => {
                    console.log('LY_FiltersModule onColumnSelectionChange newView:', newView);

                    var sm = new Api.ListGetDataSm();
                    sm.viewId = newView?.listViewId;
                    sm.listId = newView?.listId;
                    sm.linkedWorkspaceId = props.linkedWorkspaceId;
                    sm.filter = newView?.filter;

                    //todo listTableManager.getListData(sm, newView?.filter, false)


                    forceUpdate();
                  }}

                  onSaveAsNewViewSuccess={props.onSaveAsNewViewSuccess}

                  // hideSave={!showSave} //**hideSave
                  // hideColumnSelection={hide}
                  hideFilters={false}
                  // hideSort={true}

                  dropDownData={state.dropDownData}
                  linkedWorkspaceId={props.linkedWorkspaceId}
                  workspaceLookupTypes={state.workspaceLookupTypes}
                  readOnly={state.forceReadOnly}
                />
              )}

              {props.showFilters && !props.forceReadOnly && !state.linkedWorkspaceId &&
                <LY.LY_ListSettingsModule
                  listId={list.listId}
                  listName={list?.name}
                  internalName={list.tableName}
                  workspaceId={list?.workspaceId}
                  //editFormsLink="https://www.google.com/" 
                  workspaceItemId={selectedWorkspaceItem?.workspaceItemId}


                  onNewButtonClick={onNewButtonClick}


                  onDeleteSuccess={(listId: any) => console.log("Successfully removed", { listId })}
                  onSettingsUpdateSuccess={(list: Api.List) => {

                    console.log("onSettingsUpdateSuccess: Successfully updated: ", list?.name);
                    if (props.onListSettingsChanged)
                      props.onListSettingsChanged(list);
                  }}
                />
              }
            </div>

            {getViewContentByViewType()}

            <DetailPopupModal
              //state={state}
              model={vm.selectedItem}
              workspaceId={view.workspaceId}
              linkedWorkspaceId={props.linkedWorkspaceId}
              onClose={() => { vm.isDetailPopupModalOpen = false; forceUpdate(); }}
              isOpen={vm.isDetailPopupModalOpen}
              listId={vm.selectedDetailListId}
              viewId={vm.selectedDetailViewId}
              isLinkedView={view.isLinkedView}
              forceReadOnly={state.forceReadOnly}
              className='LY_ListTableView_DetailPopupModal'
              classNames={{ body: 'LY_ListTableView_DetailPopupModal_Body' }}

            />

          </div>

        </Panel>

        {vm.isDetailRightPanelOpen && (
          <>
            <ResizeHandle />
            <Panel id='mainRightPanel'
              className='ListRightPanelContent'
              collapsedSize={0}
              defaultValue={10}
              minSize={10}
              order={3}
              collapsible>

              {getRightPanelRenderer()}

            </Panel>
          </>
        )}



      </PanelGroup>


      <SelectedItemActions
        listId={state.listId}
        list={state.list}
        selectedItems={state.selectedItems}
        refreshData={onRefreshData}
        forceReadOnly={state.forceReadOnly}
        onClose={() => { state.selectedItems = []; forceUpdate(); }}
        onCopySuccess={props.onResetCache}
        onDeleteSuccess={props.onResetCache}

        linkedWorkspaceId={props.linkedWorkspaceId}
        customMenuItems={props.customSelectedRecordsCustomMenuItems}
      />


    </App.MainContainer>
  }





  function renderWithProvider() {
    return (
      <LY.ListTableViewContextProvider>
        {render()}
      </LY.ListTableViewContextProvider>
    );

  }

  return renderWithProvider();
};

