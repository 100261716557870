import "./LY_ListSettingsModule.css";

import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import { LY_ListSettingsContextProvider } from './Context/LY_ListSettingsModuleContext';
import { LY_ListSettings } from './Components/LY_ListSettings/LY_ListSettings';



export const LY_ListSettingsModule: React.FC<LY.LY_ListSettingsProps> = (props) => {

  function render() {
    var view = props.view;
    // console.log('LY_FiltersModule render view:', view);
    // console.log('LY_FiltersModule render view.filter:', view?.filter);
    //console.log('LY_FiltersModule render view.orderByColumns:', view?.filter?.orderByColumns);

    return (
      <LY_ListSettingsContextProvider {...props}  >
        <div className=".LY_ListSettingsModuleContainer">
          <LY_ListSettings  />
        </div>
      </LY_ListSettingsContextProvider>
    );
  }


  return render();
};
