import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import "./LY_ListPageModule_TableView.css";


import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import { LY_ListDataNewButtonClickRenderer } from '../LY_ListPageModule_NewRenderers/LY_ListDataNewButtonClickRenderer';

interface LY_ListPageModule_TableViewProps extends LY.LY_ListPageModuleProps {
  view: Api.ListViewSm

}

export const LY_ListPageModule_TableView: React.FC<LY_ListPageModule_TableViewProps> = (props) => {

  const context = LY.useListPageModuleContext();
  const state = context.state;
  const services = context.services;
  const manager = context.manager;

  const { isMobile } = useIsMobile(768);
  var item = state.newViewSm;
  var errors = state.errors;

  const sm = state.sm;
  const list = state.list;
   
  var workspaceItem = state.selectedWorkspaceItem;
  var listState = state.listState;
  var listStates = state.listStates;
  var workspaceId = state.workspaceId;

  const iconStyle = { width: MT.rem(16), height: MT.rem(16) };
  const lockIconStyle = { width: MT.rem(14), height: MT.rem(14) };
  
//getSelectedListViewTabContent
  function render() {

  /*    console.log('LY_ListPageModule_TableView getSelectedListViewTabContent view', view);
     console.log('LY_ListPageModule_TableView getSelectedListViewTabContent sm.viewId', sm.viewId);
 */
    //if not selected tab, return null
    var view = props.view;
    if (!view || sm.viewId != view.listViewId)
      return null;

    console.log('LY_ListPageModule_TableView getSelectedListViewTabContent show List listViewId: ', view.listViewId);

    var leftPinnedColumns = [ 'id', 'name' ];

    if (view.listId == "cd99e4a3-08cc-4d01-b579-8e81e25da999")//DLA RFQs
      leftPinnedColumns.push('nsn');

    var listViews = workspaceItem?.listViews || [];

   // vm.isNewRecordOpen = false;

    var cachedListState = listStates.find(x => x.view?.listViewId == view.listViewId);

    if(listState?.listId!=cachedListState?.listId)  
      state.listState = cachedListState;
       //setListState(cachedListState);

    //console.log('LY_ListPageModule_TableView getSelectedListViewTabContent listStates: ', listStates);
    //console.log('LY_ListPageModule_TableView getSelectedListViewTabContent cachedListState: ', cachedListState);
 
    var result = <LY.ListTableView
      viewId={view?.listViewId}
      workspaceId={workspaceId}
      linkedWorkspaceId={sm.linkedWorkspaceId}
      cachedListState={cachedListState}
      showFilters={true}
      onListSettingsChanged={(list: Api.List) => {

            if(list)
              state.list = list;
              //setList(list);
         }
      }
      onListDataLoaded={(newState) => {
        console.log('ListTableView onListDataLoaded', newState);


        var findIndex = listStates.findIndex(x => x.view?.listViewId === newState.view?.listViewId && x.list?.workspaceId === newState.list?.workspaceId);
        if (findIndex > -1) {
          listStates[ findIndex ] = newState;
        }
        else
          listStates.push(newState);
 
          var cachedListState = listStates.find(x => x.view?.listViewId == view.listViewId);
 
         // setListState(cachedListState);
          state.listState = cachedListState;

        console.log('ListTableView onListDataLoaded listStates', listStates);

       // if (listStates.length < 2)
          state.listStates = listStates;
 
 
          if(list?.listId!=newState.list?.listId)
            state.list = newState.list;
           
            console.log('ListTableView onListDataLoaded  state.list',  state.list);
            console.log('ListTableView onListDataLoaded state.listState', state.listState);

        context.forceUpdate();
      }}

      leftPinnedColumns={leftPinnedColumns}

      lookupTypes={state.lookupTypes}


      onResetCache={(excludeViewId?: number) => {

        console.log('LY_FiltersModule onResetCache excludeViewId:', excludeViewId);
        manager.resetCache(excludeViewId);
 
      }}
 
      onSaveAsNewViewSuccess={(newView: Api.ListViewSm) => {

        console.log('LY_FiltersModule onSaveAsNewViewSuccess newView:', newView);
        listViews.push(newView);
        manager.resetCache();

        manager.onTabChange(newView);



      }}

      onNewButtonClick={()=>manager.onNewButtonClick()}
    />
    return result;
  }

   
 
 
  return render();


};
