import * as Api from "app-api";
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from '@mantine/core'

import { Draggable } from 'react-beautiful-dnd';
import LY_KanbanBoard_Item from '../LY_KanbanBoard_Item/LY_KanbanBoard_Item';
import { LY_KanbanProps } from "../../Interfaces/LY_KanbanModuleProps";
import React from 'react';

export const LY_KanbanBoard_InnerList: React.FC<{
    records: { [key: string]: any }[];
    dropProvided: any;
    title?: string;
} & LY_KanbanProps> = ({ records, dropProvided, title, ...props }) => {






    function render() {

        return (
            <div className="LY_KanbanBoard_ItemList-list-container">
                {title && <h4>{title}</h4>}
                <div className="LY_KanbanBoard_ItemList-list-container-dropzone" ref={dropProvided.innerRef}>


                    {records.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(dragProvided, dragSnapshot) => (
                                <LY_KanbanBoard_Item
                                    {...props}
                                    key={item.id}
                                    item={item}
                                    isDragging={dragSnapshot.isDragging}
                                    provided={dragProvided}
                                />
                            )}
                        </Draggable>
                    ))}


                    {dropProvided.placeholder}
                </div>
            </div>
        );
    }

    
    return render()
};
