import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class EmailMarketingService extends App.BaseAppService {

    constructor(workspaceId: number | undefined) {
        super();
        this.workspaceId = workspaceId;
    }

 
    public async getEmailAccountConnectState( onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.getEmailAccountConnectState")// emailAccountId: ", emailAccountId);
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailMarketing/getEmailAccountConnectState`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailAccountService.getEmailAccountConnectState onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }

     
    public async connect(emailAccount: Api.EmailAccount, onSuccess: Function, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.connect emailAccount: ", emailAccount);
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailMarketing/connect`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            data: emailAccount,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailAccountService.connect onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });
    }
    
    
    public async getEmailAccountById(emailAccountId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.getEmailAccountById emailAccountId: ", emailAccountId);
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailMarketing/emailAccountId?emailAccountId=${emailAccountId}`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailAccountService.getEmailAccountById onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }
    
    public async getEmailAccounts(onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.getEmailAccounts");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailAccount/getEmailAccounts`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailAccountService.getEmailAccounts onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }
    
    public async getEmailTemplates(onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.getEmailTemplates");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailAccount/getEmailTemplates`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailAccountService.getEmailTemplates onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'GET'
        });
    }
    
    public async createEmailAccount(emailAccount: Api.EmailAccount, onSuccess: Function, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.createEmailAccount emailAccount: ", emailAccount);
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailAccount/createEmailAccount`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            data: emailAccount,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailAccountService.createEmailAccount onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });
    }
    
    public async updateEmailAccount(emailAccount: Api.EmailAccount, onSuccess: Function, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.updateEmailAccount emailAccount: ", emailAccount);
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailAccount/updateEmailAccount`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            data: emailAccount,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailAccountService.updateEmailAccount onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'PATCH'
        });
    }
    
 

    public async deleteEmailAccount(emailAccountId: string, onSuccess: Function, onError: Function | undefined = undefined) {
        console.log("EmailAccountService.deleteEmailAccount emailAccountId: ", emailAccountId);
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailAccount/deleteEmailAccount?emailAccountId=${emailAccountId}`;
    
        await this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("EmailMarketingService.deleteEmailAccount onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'DELETE'
        });
    }
}