import './LY_Stepper.css';

import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

interface LY_StepProps extends MT.StepperStepProps {
  children: React.ReactNode;
  currentStep: number;
}

interface LY_StepperProps extends MT.StepperProps {
  children: React.ReactNode;
  allowNextStepsSelect?: boolean;
  stepperRootClassName?: string;
  stepperContentClassName?: string;
  stepsContainerClassName?: string;
}


type LY_StepperComponent = React.FC<LY_StepperProps> & {
  LY_Step: React.FC<LY_StepProps>;

};

const LY_Step: React.FC<LY_StepProps> = ({ children, currentStep, ...props }) => {
  const stepLabelClass = (props?.step || 0) < currentStep ? "LY_Stepper_Step_Label_Active" : "LY_Stepper_Step_Label"

  function render() {
    return <MT.Stepper.Step classNames={{
      stepIcon: "LY_Stepper_Step_Icon",
      stepLabel: stepLabelClass,

    }} {...props}>{children}</MT.Stepper.Step>;
  }

  return render()
};

export const LY_Stepper: LY_StepperComponent = ({ children, allowNextStepsSelect = true, stepperRootClassName, stepperContentClassName, stepsContainerClassName, ...props }) => {

  function render() {
    return (
      <MT.Stepper
        {...props}
        classNames={{
          stepCompletedIcon: "LY_Stepper_Step_Icon_Completed",
          separator: "LY_Stepper_Step_Separator",
          root: stepperRootClassName,
          content: stepperContentClassName,
          steps: stepsContainerClassName
        }}
        allowNextStepsSelect={allowNextStepsSelect}
      >
        {children}
      </MT.Stepper>
    );
  }


  return render();

};


LY_Stepper.LY_Step = LY_Step;


