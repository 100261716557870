import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_EmailAccountSetup_EditModal.css";

import { Tooltip } from '@mantine/core';
import { Icon } from '@mui/material';

interface LY_EmailAccountSetup_EditModalProps extends LY.LY_EmailAccountSetupModuleProps {

}


export const LY_EmailAccountSetup_EditModal: React.FC<LY_EmailAccountSetup_EditModalProps> = (props) => {


    const context = LY.useEmailAccountSetupModuleContext()
    const state = context.state

    function render() {
        return <></>
    }


    // function render() {

    //     return <>
    //         <LY.LY_Button onClick={onEditButtonClick}>Edit account</LY.LY_Button>

    //         <MT.Modal
    //             classNames={{
    //                 body: "LY_EmailAccountSetup_Modal_Body",
    //                 content: "LY_EmailAccountSetup_Modal_Content"
    //             }}
    //             centered
    //             opened={state.isEditAccountModalOpened}
    //             withCloseButton={false}
    //             onClose={() => context.setEditAccountModalIsOpen(false)}>


    //                 <div className="LY_EmailAccountSetup_EditModal_Header">
    //                 <div className="LY_EmailAccountSetup_EditModal_Header_Name_Container">
    //                     <span> Edit Account</span>
    //                     {!state.isUserEmailInfoLoadingActive ? <span className="LY_EmailAccountSetup_EditModal_Header_AccountName">{state.editAccountInfo.name}</span> : null}
    //                 </div>
    //                     <Icons.IconX cursor={"pointer"} color="#8C8C8C" size={18} onClick={() => context.setEditAccountModalIsOpen(false)} />
    //                 </div>
    //             {state.isUserEmailInfoLoadingActive ? <LY_EmailAccountSetup_Loader /> : <div className="LY_EmailAccountSetup_EditModal_Body">
    //                     <div className="LY_EmailAccountSetup_EditModal_Initials_Container">
    //                     <LY.LY_Input error={state.errors.get("first_name")} value={state.editAccountInfo.first_name} onChange={(m, e) => {
    //                         onInputChange(e?.target.value, "first_name");
    //                         }} label="First Name" labelPlacement='top' placeholder='' isRequired={true} inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer' inputClassName='LY_EmailAccountSetup_CustomForm_Input' name="LY_EmailAccountSetup_NameInput" />
    //                     <LY.LY_Input error={state.errors.get("last_name")} value={state.editAccountInfo.last_name} onChange={(m, e) => {
    //                         onInputChange(e?.target.value, "last_name");
    //                     }} label="Last Name" labelPlacement='top' placeholder='' isRequired={true} inputContainerClassName='LY_EmailAccountSetup_CustomForm_InputContainer' inputClassName='LY_EmailAccountSetup_CustomForm_Input' name="LY_EmailAccountSetup_LastNameInput" />

    //                         <LY.LY_StatusDropDown workspaceId={Number(process.env.REACT_APP_LOOCEY_WORKSPACE_ID)}
    //                             name="LY_EmailAccountSetup_EditModal_StatusDropDown"
    //                         inputContainerClassName='LY_EmailAccountSetup_EditModal_StatusDropDown_Container'
    //                             valueProperty="value"
    //                             model={state.model}
    //                             data={state.editAccountStatuses}
    //                             value={state.editAccountSelectedStatus.value}
    //                             onChange={(item?: Api.SystemLookupType) => {
    //                                 state.editAccountSelectedStatus = item!
    //                                 context.forceUpdate()
    //                             }} />

    //                     </div>
    //                 <div className="LY_EmailAccountSetup_EditModal_SlashEditor_Container">
    //                     <LY.LY_SlashEditor inputContainerClassName='LY_EmailAccountSetup_SlashEditor_Container' label="Signature" onChange={(value) => console.log(value.html)} />
    //                     </div>
    //                 {/* <div className="LY_EmailAccountSetup_EditModal_Status_Container">
    //                         <LY.LY_StatusDropDown workspaceId={Number(process.env.REACT_APP_LOOCEY_WORKSPACE_ID)}
    //                             name="LY_EmailAccountSetup_EditModal_StatusDropDown"
    //                             valueProperty="value"
    //                             model={state.model}
    //                             data={state.editAccountStatuses}
    //                             value={state.editAccountSelectedStatus.value}
    //                             onChange={(item?: Api.SystemLookupType) => {
    //                                 state.editAccountSelectedStatus = item!
    //                                 context.forceUpdate()
    //                             }} />
    //                     </div> */}
    //                     <div className="LY_EmailAccountSetup_EditModal_CampaignSettings_Container">
    //                         <div className="LY_EmailAccountSetup_EditModal_CampaignSettings_Heading">Campaign Settings</div>
    //                         <div className="LY_EmailAccountSetup_EditModal_CampaignSettings_">
    //                             <div className="LY_EmailAccountSetup_EditModal_CampaignSettings">
    //                             <div className="LY_EmailAccountSetup_EditModal_CampaignSettings_Header_Container">
    //                                 <span>Daily campaign limit</span>

    //                                 <Tooltip label="Daily Sending limit">
    //                                     <Icons.IconHelpCircleFilled />
    //                                 </Tooltip>
    //                             </div>
    //                                 <div className="LY_EmailAccountSetup_EditModal_CampaignSettings_Info">
    //                                 <div>{state.editAccountInfo.daily_limit}</div>
    //                                     <span className="LY_EmailAccountSetup_EditModal_CampaignSettings_Info_Text">emails</span>
    //                                 </div>
    //                             </div>
    //                             <div className="LY_EmailAccountSetup_EditModal_CampaignSettings">
    //                             <div className="LY_EmailAccountSetup_EditModal_CampaignSettings_Header_Container">
    //                                 <span>Minimum wait time</span>

    //                                 <Tooltip label="When used with multiple campaigns">
    //                                     <Icons.IconHelpCircleFilled />
    //                                 </Tooltip>
    //                             </div>
    //                                 <div className="LY_EmailAccountSetup_EditModal_CampaignSettings_Info">
    //                                 <div>{state.editAccountInfo.min_wait_between_sends}</div>
    //                                     <span className="LY_EmailAccountSetup_EditModal_CampaignSettings_Info_Text">minute(s)</span>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="LY_EmailAccountSetup_EditModal_Reply_Container">
    //                     <LY.LY_Input error={state.errors.get("reply_to")} value={state.editAccountInfo.reply_to} onChange={(m, e) => {
    //                         onInputChange(e?.target.value, "reply_to");
    //                     }} label="Reply to" labelPlacement='top' placeholder='' name="LY_EmailAccountSetup_ReplyToInput" />
    //                     </div>

    //             </div>}

    //                 <div className="LY_EmailAccountSetup_EditModal_Footer">
    //                     <LY.LY_Button onClick={() => context.setEditAccountModalIsOpen(false)}>Cancel</LY.LY_Button>
    //                 <LY.LY_Button disabled={state.isUserEmailInfoLoadingActive} onClick={reconnectClickHendler}>Reconnect</LY.LY_Button>
    //                 <LY.LY_Button disabled={state.isUserEmailInfoLoadingActive} onClick={() => context.validateEditAccountInfo()}>Save</LY.LY_Button>
    //             </div>


    //         </MT.Modal>
    //     </>
    // }




    return render();
};
