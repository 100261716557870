import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import "./LY_EmailAccountSetupModule.css";

import { LY_EmailAccountSetupModuleContextProvider } from './Context/LY_EmailAccountSetupModuleContext';
import { LY_EmailAccountSetupModal } from './Components/LY_EmailAccountSetupModal/LY_EmailAccountSetupModal';
import { LY_EmailAccountSetup_EditModal } from './Components/LY_EmailAccountSetup_EditModal/LY_EmailAccountSetup_EditModal';



export const LY_EmailAccountSetupModule: React.FC<LY.LY_EmailAccountSetupModuleProps> = (props) => {

  function render() {

    return (
      <LY_EmailAccountSetupModuleContextProvider {...props}  >

        <div className="LY_EmailAccountSetupModal_Container">
          <LY_EmailAccountSetupModal {...props} />
          <LY_EmailAccountSetup_EditModal {...props} />
        </div>

      </LY_EmailAccountSetupModuleContextProvider>
    );
  }




  return render();
};
