import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import './LY_SlashEditor_Action_List.css'


import { Popover, ActionIcon } from "@mantine/core";
import { EditorBubbleItem, EditorInstance } from "novel";
import { SelectorItem } from '../../Interfaces/LY_SlashEditor_SelectorItem';
import { TablerIcon } from '@tabler/icons-react';

interface LY_SlashEditor_Action_ListProps extends LY.LY_SlashEditorBaseProps {
    editor: EditorInstance;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    items: SelectorItem[];
    listName: string;
    icon?: () => React.ReactNode;
}


export const LY_SlashEditor_Action_List: React.FC<LY_SlashEditor_Action_ListProps> = (props) => {


    if (!props.editor)
        return null;


    const activeItem = props.items?.filter((item) => item.isActive(props.editor))?.pop() ?? { name: [props.listName] };
    var open = props.open;

    React.useEffect(() => {
        console.log('LY_NodeSelector useEffect', props.open);

    }, [props.open]);


    function render() {
        return <Popover
            opened={open}
            onClose={() => props.onOpenChange(false)}
            shadow="md"
            closeOnClickOutside={true}
        >
            <Popover.Target>
                {
                    props.isTextButton ?
                        <LY.LY_ButtonPinkHover
                            isActive={open}
                            onClick={() => props.onOpenChange(!open)}
                        >
                            <div className="LY_SlashEditor_ButtonText">
                                {props.icon && props.icon()}
                                <span>{props.listName}</span>
                            </div>
                        </LY.LY_ButtonPinkHover>
                        :
                        <LY.LY_ButtonPinkHover
                            isActive={open}
                            onClick={() => props.onOpenChange(!open)}>
                            <span style={{ fontSize: 12, padding: 0 }}>{activeItem.name}</span>
                            <Icons.IconChevronDown size={16} />
                        </LY.LY_ButtonPinkHover>
                }

            </Popover.Target>
            <Popover.Dropdown style={{ padding: '10px', maxHeight: 300, overflowY: 'auto',zIndex: 9999  }}>

                <div className="LY_SlashEditor_Action_List_Dropdown">
                    {props.items.map((item) => (
                        <EditorBubbleItem
                            key={item.name}
                            onSelect={() => {
                                item.command(props.editor);
                                props.onOpenChange(false);
                            }}
                        >
                            <div className="LY_SlashEditor_Action_List_DropDownItem">
                                <div className="LY_SlashEditor_Action_List_DropDownItemContent">
                                    <ActionIcon variant="transparent" size="sm">
                                        <item.icon size={16} />
                                    </ActionIcon>
                                    <span>{item.name}</span>
                                </div>
                                {activeItem.name === item.name && <Icons.IconCheck size={16} />}
                            </div>
                        </EditorBubbleItem>
                    ))}
                </div>
            </Popover.Dropdown>
        </Popover>
    }

    return render();
};
