import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { Button, Group, Modal } from '@mantine/core';

import { useKanbanContext } from "../../Context/LY_KanbanModuleContext";

export const LY_AddOrChangeItem = () => {
    const context = useKanbanContext();
    const state = context.state;
    const { sections } = state;
    function validate(key: string): boolean {

        var vAll = (key === 'all');
        var item = state.selectedItem;
        state.errors.clear();

        if (vAll || key == 'name') {
            if (LIB.Common.isNullOrEmpty(item.name))
                state.errors.set('name', `Name is required!`);
            else if (item.name.length > 50)
                state.errors.set('name', `Name must be less than 50 characters!`);
        }

        var isValid = (state.errors.size < 1);


        return isValid;

    }
    function onSaveNewColumn() {

        var isValid = validate('name');
        if (!isValid) {
            context.forceUpdate();
            return false;
        }

        context.onSaveNewColumn()

    }
    return <Modal
        opened={state.isColumnModalOpened}
        onClose={() => {
            state.isColumnModalOpened = false
            context.forceUpdate();
        }}
        centered
        overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0,
        }}
        transitionProps={{ duration: 0, timingFunction: "linear" }}
        size={"sm"}
        closeOnClickOutside={false}
        title={"New Status/Section"}
    >

        <div>
            <LY.LY_DoubleColorInput
                onInputChange={(value) => {
                    state.selectedItem.name = value;
                    context.forceUpdate()

                }}
                item={{
                    name: state?.selectedItem?.name || "",
                    text_color: state?.selectedItem?.text_color || "",
                    color: state?.selectedItem?.color || ""
                }}
                onColorChange={(type: string, value) => {
                    //@ts-ignore
                    state.selectedItem[type] = value;
                    context.forceUpdate();

                }}
                value={state?.selectedItem?.name || ''}
                placeholder="Name"
                error={state.errors.get('name')}
            />
            <Group mt="md" justify='right'>
                <Button
                    variant='default'
                    onClick={() => {
                        state.isColumnModalOpened = false;
                        state.selectedItem = new Api.SystemLookupType();
                        context.forceUpdate();
                    }}
                >Cancel</Button>
                <Button
                    loading={state.isActionInProgress}
                    onClick={onSaveNewColumn}
                    loaderProps={{ type: 'dots' }}
                >
                    Save
                </Button>
            </Group>
        </div>
    </Modal>
}
