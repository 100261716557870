import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_ListPageModuleState } from "../Context/LY_ListPageModuleState";
import { LY_ListPageModuleManager } from './LY_ListPageModuleManager';

export class LY_ListPageModuleServiceManager {


  public forceUpdate: () => void;
  public state: LY_ListPageModuleState;
  public props: LY.LY_ListPageModuleProps;
  public context: LY.LY_ListPageModuleContextProps;


  public listViewService: App.ListViewService;
  public manager: LY_ListPageModuleManager; 

  public sm: Api.ListViewGetDataSm;

  constructor(props: LY.LY_ListPageModuleProps, state: LY_ListPageModuleState, context: LY.LY_ListPageModuleContextProps) {

    console.log('LY_ListPageModuleServiceManager constructor state', state);        

    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;

    this.sm = new Api.ListViewGetDataSm()

    this.listViewService = new App.ListViewService(props.workspaceId)
    this.listViewService.linkedWorkspaceId = this.props.linkedWorkspaceId;


  }
 
  public onServiceError(error: any) {

    if (this.state == undefined)
      return;

    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    App.AppBase.showError(errorMessage)
    this.state.isActionInProgress = false;
    this.forceUpdate();

  }

  public createView(sm: Api.ListViewSm, setView: React.Dispatch<React.SetStateAction<Api.ListView>>, close: Function) {

    console.log('LY_ListPageModuleServiceManager createView start sm:', sm);
 
    this.listViewService.createView(sm,

      //onSuccess
      (result: Api.ListView) => {
        console.log('LY_ListPageModuleServiceManager createView onSuccess:', result);

        //todo code
        this.state.isActionInProgress = false;
        App.AppBase.showSaveSuccess();
       
        this.forceUpdate();
      },
      //onError
      this.onServiceError.bind(this)
    );

    this.state.isActionInProgress = true;
    this.forceUpdate();
 
  }



}
