import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

export class LY_ListSettingsState extends LIB.BaseVmModel {

  public view?: Api.ListView = new Api.ListView();
  public newViewSm: Api.ListViewSm = new Api.ListViewSm();
 
  public workspaceId?:number | undefined;
  public listSettingsState: Api.ListSettingsState;
  public listSettingsForm?:  Api.ListSetting = new Api.ListSetting();
  public isDeleteModalOpen: boolean;
  public isSettingsModalOpen: boolean;

  constructor() {
    super();
  }
}
