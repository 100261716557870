import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { createContext, useContext } from "react";

import { LY_ListSettingsServiceManager } from '../Managers/LY_ListSettingsModuleServiceManager';
import { LY_ListSettingsState } from './LY_ListSettingsModuleState';


export interface LY_ListSettingsContextProps {
  state: LY_ListSettingsState;
  forceUpdate: () => void;
  onCancelOrCloseReset: () => void;
  resetState: () => void;
  services: LY_ListSettingsServiceManager;
  handleMenuItemClick: (val: string) => void;
}

const useListSettingsModuleState = (props: LY.LY_ListSettingsProps): LY_ListSettingsContextProps => {


  const initialState = new LY_ListSettingsState();

  const [state, setState] = React.useState<LY_ListSettingsState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_ListSettingsState>(new LY_ListSettingsState());


  const contextObjBase: LY.LY_ListSettingsContextProps = {
    state,
    forceUpdate,
    onCancelOrCloseReset,
    resetState,
    services: {} as LY_ListSettingsServiceManager,
    handleMenuItemClick,
  };

  const services = new LY_ListSettingsServiceManager(props, state, contextObjBase);

  const contextObj: LY.LY_ListSettingsContextProps = { ...contextObjBase, services: services };


  React.useEffect(() => {

   // console.log('LY_ListSettingsModuleContextProvider useEffect props:', props);

    state.workspaceId = props.workspaceId;
    state.view = props.view;
    state.errors = new Map<string, any>();


    
    var stateCopy = clone(state);
    setPropsState(stateCopy);

    forceUpdate()


  }, [props.listId, props.workspaceItemId, props.view, props.workspaceId]);

  function clone(stateIn: LY_ListSettingsState): LY_ListSettingsState {

    var stateCopy = LIB.ObjectHelper.clone(stateIn) as LY_ListSettingsState;
    stateCopy.errors = new Map<string, any>();
    return stateCopy;
  }

  function forceUpdate() {
    setState({ ...state })
  }

  function resetState() {
    var stateCopy = clone(propsState);
    setState({ ...state })
  }


  function onCancelOrCloseReset() {
    state.isSettingsModalOpen = false;
    state.errors.clear();
    forceUpdate();
  }

  function handleMenuItemClick(val: string) {
    if(val === 'settings') {
      state.isSettingsModalOpen = true;
    } else if (val === 'delete') {
      state.isDeleteModalOpen = true;
    }

    forceUpdate();

  }

  return contextObj;
};

const LY_ListSettingsContext = createContext<LY.LY_ListSettingsContextProps | undefined>(undefined);

export const useListSettingsModuleContext = () => {


  const context = useContext(LY_ListSettingsContext)


  if (!context) {
    throw new Error(
      "useLY_StatusDropDownContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};


const LY_ListSettingsContextProvider: React.FC<LY.LY_ListSettingsProps & { children: React.ReactNode }> = (props) => {


  const contextValue = useListSettingsModuleState(props);

  function render() {


    return (
      <LY_ListSettingsContext.Provider value={contextValue}>
        {props.children}
      </LY_ListSettingsContext.Provider>
    );
  }

  return render();
};
export { LY_ListSettingsContextProvider, LY_ListSettingsContext };
