import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';
import { connect } from 'react-redux';
 

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
 
import { useParams, useLocation } from 'react-router';
  
import './ListPage.css';
   
interface ListPageProps {


}

const ListPage: React.FC<ListPageProps> = (props) => {
//export const ListPage: React.FC<ListPageProps> = (props) => {

 
  var params = useParams();
  var location = useLocation();

  //  const globalUIState = props.globalUIState;
  var globalUIState = App.AppBase.getGlobalState();

  //var workspaceVm = props.workspaceVm;
  var workspaceVm = globalUIState?.workspaceVm;
  const selectedWorkspace = workspaceVm?.selectedWorkspace;
  const workspaceId = selectedWorkspace?.workspaceId;

  var workspaceItem = workspaceVm?.selectedItem;
 
 
  React.useEffect(() => {
    console.log('ListPage useEffect: ', params.id );
    console.log('ListPage useEffect workspaceItem: ', workspaceItem );

  }, [ params.id ]);
 
  function render(){

    var id: any = params.id;
 
    if (id == '' || id == undefined) {
      id = workspaceItem?.defaultViewId;
    }
    var viewId = Number(id);

    return <LY.LY_ListPageModule 

      linkedWorkspaceId={Number(params.linked)}
      workspaceId={workspaceId}
      allWorkspaces={workspaceVm.allWorkspaces}
      selectedWorkspaceItem={workspaceVm?.selectedItem}
      viewId={viewId}
      hasDataLoaded={globalUIState.isInitialDataLoaded}
      currentUser={App.AppBase.currentUser}
      onWorkspaceItemChange={(selectedWorkspaceItem?: Api.WorkspaceItem) => {     
        console.log('ListPage onWorkspaceItemChange: ', selectedWorkspaceItem);

            workspaceVm.selectedItem = selectedWorkspaceItem;
      }}
      onViewChange={(selectedWorkspaceItem: Api.WorkspaceItem, viewId: number) => {
        console.log('ListPage onViewChange: ', selectedWorkspaceItem, viewId);
      }}
    />


  }
 
  return render();


};
  export default connect(
  (state: App.AppState) => ({ ...state.listState })
)(ListPage as any);  
