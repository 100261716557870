import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { LY_EmailAccountSetupModuleState } from "../Context/LY_EmailAccountSetupModuleState";
import { LY_EmailAccountSetupModuleServiceManager } from './LY_EmailAccountSetupModuleServiceManager';

export class LY_EmailAccountSetupModuleManager {


  public forceUpdate: () => void;
  public state: LY_EmailAccountSetupModuleState;
  public props: LY.LY_EmailAccountSetupModuleProps;
  public context: LY.LY_EmailAccountSetupModuleContextProps;


  public listViewService: App.ListViewService;
 
  constructor(props: LY.LY_EmailAccountSetupModuleProps, 
              state: LY_EmailAccountSetupModuleState, 
              context: LY.LY_EmailAccountSetupModuleContextProps,
              services: LY_EmailAccountSetupModuleServiceManager
              ) {


    this.state = state;
    this.props = props;
    this.forceUpdate = context.forceUpdate;
    this.context = context;
 
    this.listViewService = services.listViewService;
  
  }
 
  public onCancelOrCloseReset(keepModalsOpen: boolean = false) {


    //Modals
    this.state.isAddAccountModalOpened = keepModalsOpen;
    this.state.isEditAccountModalOpened = keepModalsOpen;
 
    this.state.selectedAccountType = undefined;
    this.state.currentStep = 0;
    this.state.selectedEmailProviderAuthtype = undefined;
    this.state.newAccountSm  = new Api.EmailAccount();
   
    this.resetErrors();

    if(this.props.onClose)
    this.props.onClose();
  }

  public resetErrors() {

    this.state.isPasswordAuthErrorMessageVisible = false;
    this.state.isActionInProgress = false; 
    this.state.isEmailTypesLoadingActive = false;
    this.state.isConnectPending = false;
    this.state.isUserEmailInfoLoadingActive = false;
    this.state.apiErrorMessage = "";
    this.state.errors.clear();

    this.forceUpdate()

  } 

}
