import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import "./LY_ListPageModule_Container.css";


import useIsMobile from '_LIB/Components/Hooks/UseIsMobile';
import { LY_ListDataNewButtonClickRenderer } from '../LY_ListPageModule_NewRenderers/LY_ListDataNewButtonClickRenderer';
import { LY_ListPageModule_TableView } from '../LY_ListPageModule_TableView/LY_ListPageModule_TableView';

export const LY_ListPageModule_Container: React.FC<LY.LY_ListPageModuleProps> = (props) => {

  const context = LY.useListPageModuleContext();
  const state = context.state;
  const services = context.services;
  const manager = context.manager;

  const { isMobile } = useIsMobile(768);
  var item = state.newViewSm;
  var errors = state.errors;

  const sm = state.sm;
  const list = state.list;
   
  var workspaceItem = state.selectedWorkspaceItem;
  var listState = state.listState;
  var listStates = state.listStates;
  var workspaceId = state.workspaceId;

  const iconStyle = { width: MT.rem(16), height: MT.rem(16) };
  const lockIconStyle = { width: MT.rem(14), height: MT.rem(14) };
 

  function getMainPanelContent() {


    //var title = view?.name || list?.name;
    var title = list?.name || workspaceItem?.name;
    
    console.log('LY_ListPageModule_Container getMainPanelContent workspaceItem', workspaceItem);

    if (workspaceItem?.isFolder) {

      return <>
        <h1 className='LY_ListPageModule_ContainerTitle'>{title}</h1>

        <div>
          Folder content coming soon...
        </div>
      </>;
    }

    //console.log('getMainPanelContent props', props);

    return <div>
      <h1 className='LY_ListPageModule_ContainerTitle'>{title}</h1>


      {getTabs()}


    </div>

  }

  

 

  function getViewTabs(): any[] {

    var listViews = workspaceItem?.listViews;

    console.log('LY_ListPageModule_Container getViewTabs listViews', listViews);

    if (!listViews || !Array.isArray(listViews))
      return [];

    var result: any[] = [];

    var count = 1;
    for (var view of listViews!) {

      var tabId = view.listViewId?.toString() || '-1';

      //  console.log('LY_ListPageModule_Container getViewTabs tabId', tabId);

      var tab = <MT.Tabs.Tab
        key={'tab' + view.listViewId}
        value={tabId}

        classNames={{ tabSection: "LY_LY_ListPageModule_ContainerViewTabSection" }}
        className='LY_LY_ListPageModule_ContainerViewTab'
        /*   rightSection={
              <MT.CloseButton
              className='LY_TabsContextMenuButton'
              //onClick={() => context.onDeleteSort(index)}
              icon={<Icons.IconDots style={iconStyle} />}
            />
          }  */
        rightSection={
          <div className='LY_TabsContextMenuButtonContainer'>
            <Icons.IconDots className='LY_TabsContextMenuButton' style={iconStyle} />
          </div>
        }

        leftSection={<Icons.IconDatabase style={iconStyle} />}>

        <div key={'div' + workspaceItem?.listId} style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          {view?.name}
          {view?.isLocked &&
            <Icons.IconLock style={lockIconStyle} />
          }
        </div>

      </MT.Tabs.Tab>
      result.push(tab);
      count++;
    }

    return result;
  }

  function getViewTabContents(): any[] {

    var listViews = workspaceItem?.listViews;

     console.log('LY_ListPageModule_Container getViewTabs listViews', listViews);
    if (!listViews || !Array.isArray(listViews))
      return [];

    var result: any[] = [];

    var count = 1;
    for (var view of listViews) {

      var tabId = view.listViewId?.toString() || '-1';

      var panel = <MT.Tabs.Panel
        key={'tabPanel' + view.listViewId}
        value={tabId} className='LY_ListPageModule_ContainerTabPanel'>

        {/* {getSelectedListViewTabContent(view)} */}
        <LY_ListPageModule_TableView view={view} {...props} />
   

      </MT.Tabs.Panel>

      result.push(panel);
      count++;
    }

    return result;
  }

  

  function getTabs() {

    console.log('LY_ListPageModule_Container getTabs selectedTab', state.viewId);
    var listId = workspaceItem?.listId;

    if (!listId)
      return null;

    var listViews = workspaceItem?.listViews || [];
    var listViewTypes = state.lookupTypes?.filter(x => x.groupName == 'ListViewType');

    let listState = listStates?.find(x => x.listId == listId || x.list?.listId==listId);
    var listStateColumns = listState?.list?.columns;
/* 
    console.log('LY_ListPageModule_Container getTabs workspaceItem', workspaceItem);
    console.log('LY_ListPageModule_Container getTabs listId', listId);
    console.log('LY_ListPageModule_Container getTabs listStates', listStates);
    console.log('LY_ListPageModule_Container getTabs listState', listState);
    console.log('LY_ListPageModule_Container getTabs listStateColumns', listStateColumns); */

    
    var statusColumns = listStateColumns?.filter(x =>
      (x.listColumnTypeId === App.ListColumnTypeEnum.Status
      || x.listColumnTypeId === App.ListColumnTypeEnum.Dropdown ) && 
      !x.isRelationship
    );

    //sort by listColumnTypeId
    statusColumns = statusColumns?.sort((a, b) => a.listColumnTypeId < b.listColumnTypeId ? 1 : -1);



    

    console.log('LY_ListPageModule_Container getTabs listId', listId);
    console.log('LY_ListPageModule_Container getTabs statusColumns', statusColumns);


    return <LY.LY_ListViewTabsModule
      name='ListViewTabs'

      onTabChange={(tab: Api.ListViewSm)=>manager.onTabChange(tab)}
      selectedViewId={state.viewId}
      listId={listId}
      workspaceId={workspaceId!}
      linkedWorkspaceId={sm.linkedWorkspaceId}
      contentPanels={getViewTabContents()}
      data={listViews}
      listViewTypes={listViewTypes}
      statusColumns={statusColumns || []}

      onTabOrderChanged={(newTabs: Api.ListViewSm[]) => {

        if (workspaceItem?.listViews)
          workspaceItem.listViews = newTabs;
      }}
      onNewViewSuccess={(view: Api.ListViewSm) => {
        console.log('LY_ListPageModule_Container onNewViewSuccess view', view);
        //   listViews.push(view);
        manager.onTabChange(view);
        manager.resetCache();
      }}
      onDeleteViewSuccess={(view: Api.ListViewSm) => {

        console.log('LY_ListPageModule_Container onDeleteViewSuccess view', view);
        manager.resetCache();

        if (workspaceItem?.listViews) {
          workspaceItem.listViews = listViews.filter(t => t.listViewId !== view.listViewId) || [];

          if (workspaceItem.listViews.length > 0) {
            var gotoFirstView = workspaceItem.listViews[ 0 ];
            console.log('LY_ListPageModule_Container onDeleteViewSuccess FirstView', gotoFirstView);

            manager.onTabChange(gotoFirstView);

          }

        }
      }}

    />


  }

  function render() {

    console.log('LY_ListPageModule_Container render hasDataLoaded', props.hasDataLoaded);

  //  if (!globalUIState.isInitialDataLoaded)
    if (!props.hasDataLoaded)
      return null;

    return <App.MainSplitPage
      //pageTitle={<span>{view?.name}</span>}
      //state={vm}
      hasDataLoaded={props.hasDataLoaded} //{globalUIState.isInitialDataLoaded}
      //loading={(state.isServiceCallPending)}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      showLeftMenu={true}
      mainPageContainerClassName='MainSplitPageContainerForList'
      mainPanelsClassName='MainSplitPageMainPanelsForList'
    //showRightPanel={vm.isDetailScreenOpen}
    >
 
      {getMainPanelContent()}


      <LY_ListDataNewButtonClickRenderer 
      listState={listState}
      isOpen={state.isNewRecordOpen}
      onClose={() => {
        state.isNewRecordOpen = false;
        context.forceUpdate();
      }}
     />

    </App.MainSplitPage>


  }

  return render();


};
