import * as React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

interface LoadingProps {
    show?: boolean;
    isModal?: boolean;
    style?: React.CSSProperties;
    disablePortal?: boolean;
    message?: string;
}


export class Loading extends React.Component<LoadingProps, {}> {



    public getModalLoading() {


        var divMsg:any = "";
        var message = this.props.message;
       
        if(message)
            divMsg = <div style={{fontSize:16, 
                backgroundColor:'white', borderRadius:10, 
                padding:5, border:'1px solid #ccc'}}>{message}</div>;


        return <Dialog
            open={true}
            style={{
                position: 'absolute', textAlign: "center", boxShadow: 'none',
                verticalAlign: "top", zIndex: 5502,
                marginTop: '25vh', backgroundColor: 'transparent', height: 200
            }}
            disablePortal={this.props.disablePortal}
            BackdropProps={{ style: { opacity: 0.2, backgroundColor: 'transparent' } }}
            PaperProps={{ style: { boxShadow: 'none', backgroundColor: 'transparent' } }}
        >
            <DialogContent style={{ boxShadow: 'none', overflow: 'hidden' }}   >

                <CircularProgress style={{ boxShadow: 'none', color: 'var(--ly-global-primary-color)' }} size="3rem" />
                {divMsg}

            </DialogContent>

        </Dialog>
    }
    public render() {



        if (this.props.show === undefined || this.props.show === true) {

            if (this.props.isModal)
                return this.getModalLoading();
            else
                return <div>
                    <CircularProgress style={this.props.style} />
                </div>;
        }

        return null;
    }
}
