import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { LY_KanbanSection } from "./LY_KanbanModuleContext";

export class LY_KanbanModuleState extends LIB.BaseVmModel {

    public model: LY.DataItemModel = new LY.DataItemModel();
    public sections: LY_KanbanSection[] = [];
    public vm: LIB.BaseVmModel = new LIB.BaseVmModel();
    public isColumnModalOpened: boolean = false;
    public isDeleteModalOpened: boolean = false;
    public errors: Map<string, string> = new Map();
    public selectedItem: Api.SystemLookupType;
    public columns?: Api.ListColumn[];

    public statusColumnName:string;

    constructor() {
        super();
    }
}
